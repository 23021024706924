define("adtikir3v1/models/country", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    name: attr('string'),
    capital: attr('string'),
    phone: attr('string'),
    currency: attr('string'),
    code: Ember.computed.alias("this.id"),
    languages: hasMany('language'),
    // continent: belongsTo('continent'),
    // places: hasMany("country-option")
    states: hasMany('state'),
    places: hasMany('country'),
    region: belongsTo('region')
  });
});