define("adtikir3v1/pods/campaign/details/financials/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentRouteName: Ember.computed("router.currentRouteName", function () {
      let router = this.get("router");
      let currentRouteName = router.get("currentRouteName");
      return currentRouteName;
    })
  });
});