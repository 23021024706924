define("adtikir3v1/pods/components/adk-file-uploader/component", ["exports", "adtikir3v1/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    uploader: Ember.inject.service('adk-uppy-uploader'),
    account: Ember.inject.service('adkSessionAccount'),
    alreadyRun: false,
    init() {
      this._super();
      let id = 'uploader_' + (0, _moment.default)(new Date()).format('MMDDHHmmss');
      this.setProperties({
        showPromptDialog: false,
        configuration: {
          //        id: 'uploader_1',
          id: id,
          autoProceed: false,
          allowMultipleUploads: false,
          restrictions: {
            maxFileSize: null,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: []
          },
          meta: {
            token: this.account.session.token
          },
          header: {},
          onBeforeFileAdded: (currentFile, files) => {
            currentFile, files;
          },
          onBeforeUpload: files => {
            files;
          }
        },
        dashboardConfig: {
          trigger: '.OpenButton',
          inline: true,
          height: 400,
          width: 750,
          target: '.UploaderContainer',
          replaceTargetContent: true,
          showProgressDetails: true,
          note: '',
          metaFields: [],
          browserBackButtonClose: true,
          showLinkToFileUploadResult: false,
          doneButtonHandler: null
        },
        tusConfig: {
          // endpoint should be a variable that is either loaded from ENV or System Para in DB
          endpoint: _environment.default.uploadEndpoint,
          resume: true,
          removeFingerprintOnSuccess: true,
          autoRetry: false,
          retryDelays: [0, 500, 1000, 1500],
          metaFields: null
        }
      });
    },
    //init

    loadUploaderTask: function () {
      this.uploader.setUploader(this.configuration, this.dashboardConfig, this.tusConfig);
    },
    insertFileTypes: function (filetypes) {
      // [filetypes].forEach((filetype) => {
      filetypes.forEach(filetype => {
        this.configuration.restrictions.allowedFileTypes.push(filetype);
        if (this.dashboardConfig.note.length == 0) {
          this.dashboardConfig.note += 'Only Allows ' + filetype;
        } else {
          this.dashboardConfig.note += ', ' + filetype;
        }
      });
    },
    //need a function to return a function to wrap the component and pass it to uppy
    submitFunc() {
      let component = this;
      return function () {
        component.onSubmit({
          filesId: component.uploader.tusid
        });
        component.uploader.clearId();
      };
    },
    //run
    embeddedRun() {
      //not sure y some component run 2 times on this.. 

      if (this.alreadyRun) {
        return;
      }
      this.set('alreadyRun', true);
      this.loadUploaderTask();
      this.uploader.setComplete(this.submitFunc());
    },
    didReceiveAttrs() {
      this._super(...arguments);

      //allow user to upload multiple files
      if (this.allowMultipleUploads) {
        this.set('configuration.allowMultipleUploads', this.allowMultipleUploads);
      }

      //allow user to upload image files
      if (this.allowImage) {
        (true && !(_environment.default.imageExt) && Ember.assert("config imageExt not found", _environment.default.imageExt));
        this.insertFileTypes(_environment.default.imageExt);
      }

      //allow user to upload pdf files
      if (this.allowPdf) {
        (true && !(_environment.default.pdfExt) && Ember.assert("config pdfExt not found", _environment.default.pdfExt));
        this.insertFileTypes(_environment.default.pdfExt);
      }

      //allow user to upload excel documents
      if (this.allowDoc) {
        (true && !(_environment.default.docExt) && Ember.assert("config docExt not found", _environment.default.docExt));
        this.insertFileTypes(_environment.default.docExt);
      }

      //allow user to upload audio
      if (this.allowAudio) {
        (true && !(_environment.default.audioExt) && Ember.assert("config audioExt not found", _environment.default.audioExt));
        this.insertFileTypes(_environment.default.audioExt);
      }

      //allow user to upload video
      if (this.allowVideo) {
        (true && !(_environment.default.videoExt) && Ember.assert("config videoExt not found", _environment.default.videoExt));
        this.insertFileTypes(_environment.default.videoExt);
      }

      //set number of maximum files uploads
      if (this.maxNumberOfFiles) {
        this.set('configuration.restrictions.maxNumberOfFiles', this.maxNumberOfFiles);
      }

      // This set up the meta data that koa hook use to move files
      // fileFor is used to create directory for type of files
      // campaignId or adId is use to store the pictures in those directories each respectivel

      let fileForFlag = false;
      let fileForObj = this.fileForObj;
      for (let key in fileForObj) {
        if (key === "fileFor") {
          fileForFlag = true;
        }
        this.configuration.meta[key] = fileForObj[key];
      } //for
      if (!fileForFlag) {
        throw new Error("FileFor key not found");
      }

      //embedded mode... 
      if (this.get('embedded')) {
        this.set('classNames', ["UploaderContainer"]);
        let dashboardConfig = this.get('dashboardConfig');
        dashboardConfig.height = 270;
        Ember.run.scheduleOnce('afterRender', this, this.embeddedRun);
      }
    },
    //didReceiveAttrs

    actions: {
      toggleIsPop() {
        this.set("showPromptDialog", !this.showPromptDialog);
        Ember.run.schedule('afterRender', () => {
          this.loadUploaderTask();
        });
      },
      closePromptDialog(toggleFg) {
        this.onSubmit({
          filesId: this.uploader.tusid
        });
        this.uploader.clearId();
        this.set("showPromptDialog", toggleFg);
      }
    } //actions
  });
});