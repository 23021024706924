define("adtikir3v1/models/v-ad-media-proposal", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    advertiser: attr('string'),
    campaignName: attr('string'),
    // Proposal Response
    projectObjectives: attr('string', {
      defaultValue: ''
    }),
    ourUnderstanding: attr('string', {
      defaultValue: ''
    }),
    aboutUs: attr('string', {
      defaultValue: ''
    }),
    responseURL: attr('string', {
      defaultValue: ''
    }),
    description: attr('string', {
      defaultValue: ''
    }),
    ourMethodology: attr('string', {
      defaultValue: ''
    }),
    discovery: attr('string', {
      defaultValue: ''
    }),
    visualize: attr('string', {
      defaultValue: ''
    }),
    implementation: attr('string', {
      defaultValue: ''
    }),
    ourTeam: attr('string', {
      defaultValue: ''
    }),
    ourResults: attr('string', {
      defaultValue: ''
    }),
    proposalNote: attr('string', {
      defaultValue: ''
    }),
    finalNote: attr('string', {
      defaultValue: ''
    }),
    taxIncluded: attr('boolean', {
      defaultValue: false
    }),
    clientOwnsIP: attr('boolean', {
      defaultValue: false
    }),
    timelines: attr('string', {
      defaultValue: ''
    }),
    terms: attr('string', {
      defaultValue: ''
    }),
    // status = new, vSubmitted, 
    // status = expired
    // status = award, vAccept, vReject, project, projectReject,
    // status = awardExpired, vAcceptExpired
    status: attr('string'),
    responseExpiredDate: attr('date'),
    //validityDate: attr('date'),
    responseExpiredDayCount: attr('number', {
      defaultValue: 14
    }),
    contactName: attr('string', {
      defaultValue: ''
    }),
    contactEmail: attr('string', {
      defaultValue: ''
    }),
    contactPhoneNumber: attr('string', {
      defaultValue: ''
    }),
    // responseDate               :attr('date',{defaultValue() { return new Date(); }}),
    // attachments             : array(),
    // Award
    quotedCurrency: attr('string'),
    //cost: attr('number', { defaultValue: 0}),

    responseTotalCost: attr('number', {
      defaultValue: 0
    }),
    // Date where agency submitted the proposal
    submittedDate: attr('date', {
      defaultValue() {
        return new Date();
      }
    }),
    //Date where award will expire if not accepted by agency
    awardedDate: attr('date'),
    awardExpiredDate: attr('date'),
    //Date where advertiser has not yet accepted agency accepted awarded proposal
    vResponseNote: attr('string'),
    vResponseDate: attr('date'),
    vAcceptExpiredDate: attr('date'),
    //StartDate for the project Submitted by Agency
    projectResponseDate: attr('date'),
    projectStartDate: attr('date'),
    awardedNote: attr('string'),
    // advertiserNote: attr('string'),
    //  agencyNote: attr('string'),
    awardedByFirstName: attr('string'),
    awardedByLastName: attr('string'),
    //  awardedByUserRoleId: attr('string'),
    awardedByRole: attr('string'),
    // for vAccept or vReject
    vRespondedByFirstName: attr('string'),
    vRespondedByLastName: attr('string'),
    //  vRespondedByUserRoleId: attr('string'),
    vRespondedByRole: attr('string'),
    //Project
    //Project - status = project, projectReject,
    projectResponseFirstName: attr('string'),
    projectResponseLastName: attr('string'),
    //  projectResponseUserRoleId: attr('string'),
    projectResponseRole: attr('string'),
    //Relationships
    adMediaRfpBody: belongsTo('adMediaRfpBody'),
    adRfp: belongsTo('adRfp'),
    company: belongsTo('company'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    vFinalCurrencyRate: attr('number'),
    vFinalCurrencyRateDate: attr('date'),
    advertiserCompanyName: attr('string'),
    adNameLocal: attr('string'),
    adName: attr('string'),
    proposalDeadline: attr('date'),
    // awardResponseMessage: attr('string'),
    // awardResponseDate: attr('date'),
    // awardResponseFirstName: attr('string'),
    // awardResponseLastName: attr('string'),
    // awardResponseRole: attr('string'),

    statusString: Ember.computed('status', function () {
      let status = this.get('status');
      let statusString = {
        new: "New",
        vSubmitted: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " vendor proposal received",
        expired: "Expired " + this.intl.t('ad') + ' ' + this.intl.t('media') + " proposal",
        award: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " proposal awarded,  waiting to be accepted by ad media vendor",
        awardCancel: 'Proposal canceled',
        vAccept: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " accepted the award",
        vReject: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.int.t('media') + " rejected the award",
        project: Ember.String.capitalize(this.intl.t("advertiser")) + " accepted the " + this.intl('ad') + ' ' + this.intl('media'),
        projectReject: Ember.String.capitalize(this.intl.t("advertiser")) + " rejected the " + this.intl.t('ad') + ' ' + this.intl.t('media'),
        awardExpired: "The award expired, " + this.intl.t("advertiser") + " did not action",
        vAcceptExpired: "The award expired, " + this.intl.t('ad') + ' ' + this.intl.t('media') + " did no action"
      };
      return statusString[status];
    }),
    daysToDeadline: Ember.computed('status', 'responseExpiredDate', 'awardExpiredDate', 'vAcceptExpiredDate', function () {
      let now = this.get('adkSessionAccount').get('myNow');
      let deadline;
      let status = this.get('status');
      if (status === 'award') {
        deadline = (0, _moment.default)(this.get('awardExpiredDate'));
      } else if (status === 'vAccept') {
        deadline = (0, _moment.default)(this.get('vAcceptExpiredDate'));
      } else {
        deadline = (0, _moment.default)(this.get('responseExpiredDate'));
      }
      if (!deadline) {
        return '';
      }
      let diffDay = deadline.diff(now, "seconds");
      if (diffDay <= 86400 && diffDay > 0) {
        diffDay = 'Today is the deadline';
      } else if (diffDay > 86400) {
        diffDay = deadline.diff(now, "days");
        diffDay += " Days to go";
        //do nothing;
      } else {
        diffDay = 'Past Deadline';
      }
      return diffDay;
    }),
    passDeadline: Ember.computed("daysToDeadline", function () {
      let daysToDeadline = this.get('daysToDeadline');
      if (daysToDeadline === 'Past Deadline' || daysToDeadline == '') {
        return true;
      }
      return false;
    })
  });
});