define("adtikir3v1/pods/profile/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UcmqC7VR",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex layout-column md-padding\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[5,\"adk-user-profile\",[],[[\"@me\",\"@fieldTitle\",\"@saveAction\"],[[22,\"model\"],\"User Profile\",[28,\"action\",[[23,0,[]],\"saveMe\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/profile/index/template.hbs"
    }
  });
});