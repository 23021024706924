define("adtikir3v1/pods/campaign/details/ads/adnew/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "d+c2cylQ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"isRunning\"]]],null,{\"statements\":[[0,\"    loading....\\n\"]],\"parameters\":[]},{\"statements\":[[5,\"adk-newad\",[],[[\"@adCampaign\",\"@adCampaignDetail\",\"@mediaTypes\",\"@saveNewAd\"],[[22,\"adCampaign\"],[22,\"adCampaignDetail\"],[22,\"mediaTypes\"],[28,\"action\",[[23,0,[]],\"saveAd\"],null]]]],[0,\"\\n\"],[1,[22,\"paper-divider\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/ads/adnew/template.hbs"
    }
  });
});