define("adtikir3v1/models/ad-media-proposal-request-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    countOfList: attr('number'),
    adMediaRfpBody: belongsTo('ad-media-rfp-body'),
    //proposalInvitees: DS.hasMany('proposal-invitee'),

    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});