define("adtikir3v1/pods/campaign/details/activities/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init() {
      this._super();
      this.set('activity_col', [{
        propertyName: 'createdAt',
        title: 'Date',
        editable: false,
        component: 'date-display'
      }, {
        propertyName: 'adCampaignName',
        title: 'Ad Campaign Name'
      }, {
        propertyName: 'adName',
        title: 'Ad Name '
      }, {
        propertyName: 'username',
        title: 'Username'
      }, {
        propertyName: 'description',
        title: 'Description'
      }, {
        propertyName: 'status',
        title: 'Status'
      }]);
    }
  });
});