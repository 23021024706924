define("adtikir3v1/pods/components/adkpost-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "16Vyw0+N",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"paper-input\",null,[[\"value\",\"onChange\",\"onKeyUp\",\"placeholder\"],[[24,[\"value\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null],[28,\"perform\",[[24,[\"handleFilterEntry\"]]],null],\"Filter By Post Title\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adkpost-filter/template.hbs"
    }
  });
});