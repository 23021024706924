define("adtikir3v1/pods/campaign/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);
      let applicationController = this.controllerFor('application');
      applicationController.set('isHomeOffice', false);
    }
  });
});