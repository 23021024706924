define("adtikir3v1/models/a-ad-media-proposal", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    intl: Ember.inject.service,
    advertiser: attr('string'),
    campaignName: attr('string'),
    // Proposal Response
    projectObjectives: attr('string', {
      defaultValue: ''
    }),
    ourUnderstanding: attr('string', {
      defaultValue: ''
    }),
    description: attr('string', {
      defaultValue: ''
    }),
    aboutUs: attr('string', {
      defaultValue: ''
    }),
    responseURL: attr('string', {
      defaultValue: ''
    }),
    ourTeam: attr('string', {
      defaultValue: ''
    }),
    ourResults: attr('string', {
      defaultValue: ''
    }),
    finalNote: attr('string', {
      defaultValue: ''
    }),
    proposalNote: attr('string', {
      defaultValue: ''
    }),
    taxIncluded: attr('boolean', {
      defaultValue: false
    }),
    clientOwnsIP: attr('boolean', {
      defaultValue: false
    }),
    timelines: attr('string', {
      defaultValue: ''
    }),
    terms: attr('string', {
      defaultValue: ''
    }),
    // status = new, submitted, award, accept, awarded, rejected, expired 
    status: attr('string'),
    responseExpiredDate: attr('date'),
    //validityDate: attr('date'),
    contactName: attr('string', {
      defaultValue: ''
    }),
    contactEmail: attr('string', {
      defaultValue: ''
    }),
    contactPhoneNumber: attr('string', {
      defaultValue: ''
    }),
    // responseDate               :attr('date',{defaultValue() { return new Date(); }}),
    // attachments             : array(),
    // Award
    quotedCurrency: attr('string', {
      defaultValue: ''
    }),
    cost: attr('number', {
      defaultValue: 0
    }),
    responseTotalCost: attr('number', {
      defaultValue: 0
    }),
    // Date where agency submitted the proposal
    submittedDate: attr('date', {
      defaultValue() {
        return new Date();
      }
    }),
    //Date where award will expire if not accepted by agency
    awardedDate: attr('date'),
    awardExpiredDate: attr('date'),
    //Date where advertiser has not yet accepted agency accepted awarded proposal
    vResponseNote: attr('string'),
    vResponseDate: attr('date'),
    vAcceptExpiredDate: attr('date'),
    //StartDate for the project Submitted by Agency
    projectResponseDate: attr('date'),
    projectStartDate: attr('date'),
    // advertiserNote: attr('string'),
    //  agencyNote: attr('string'),
    awardedByFirstName: attr('string'),
    awardedByLastName: attr('string'),
    awardedByRole: attr('string'),
    awardedNote: attr('string'),
    submittedByFirstName: attr('string'),
    submittedByLastName: attr('string'),
    submittedByRole: attr('string'),
    // for vAccept or vReject
    vRespondedByFirstName: attr('string'),
    vRespondedByLastName: attr('string'),
    vRespondedByRole: attr('string'),
    //Project
    //Project - status = project, projectReject,
    projectResponseFirstName: attr('string'),
    projectResponseLastName: attr('string'),
    projectResponseRole: attr('string'),
    //Relationships
    adMediaRfpBody: belongsTo('adMediaRfpBody'),
    adRfp: belongsTo('adRfp'),
    company: belongsTo('company'),
    finalCurrencyRate: attr('number', {
      defaultValue: 1
    }),
    finalCurrencyRateDate: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    action: attr('string', {
      defaultValue: ''
    }),
    // awardResponseMessage: attr('string'),
    // awardResponseDate: attr('date'),
    // awardResponseFirstName: attr('string'),
    // awardResponseLastName: attr('string'),
    // awardResponseRole: attr('string'),

    statusString: Ember.computed('status', function () {
      let status = this.get('status');
      let statusString = {
        new: {
          icon: 'new_release',
          msg: "New"
        },
        vSubmitted: {
          icon: 'feedback',
          msg: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " vendor proposal received"
        },
        expired: {
          icon: 'send',
          msg: 'Expired ' + this.intl.t('media') + ' proposal'
        },
        //award: "Proposal awarded,  waiting to be accepted by vendor",
        award: {
          icon: "shield",
          msg: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " proposal awarded,  waiting to be accepted by the ad media vendor"
        },
        awardCancel: {
          icon: 'remove',
          msg: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + ' proposal canceled'
        },
        vAccept: {
          icon: 'add',
          msg: Ember.String.capitalize(this.intl.t('ad')) + ' ' + this.intl.t('media') + " vendor accepted award"
        },
        vReject: {
          icon: 'remove',
          msg: Ember.String.capitalize(this.intl.t('ad')) + this.intl.t('media') + " Vendor rejected award"
        },
        project: {
          icon: 'task_alt',
          msg: Ember.String.capitalize(this.intl.t("advertiser")) + " accepted the " + this.intl.t('ad') + ' ' + this.intl.t('media') + " vendor"
        },
        projectReject: {
          icon: 'warning',
          msg: Ember.String.capitalize(this.intl.t("advertiser")) + " rejected the  " + this.intl.t('ad') + ' ' + this.intl.t('media') + " vendor"
        },
        awardExpired: {
          icon: 'usb_off',
          msg: "The award expired, " + this.intl.t("advertiser") + " did not action"
        },
        vAcceptExpired: {
          icon: 'wifi_tethering',
          msg: "The award expired, " + this.intl.t('ad') + ' ' + this.intl.t('media') + " vendor no action"
        }
      };
      return statusString[status];
    }),
    //statusString

    // a multiple function count down base on the status and get the 
    // deadline from different property 
    daysToDeadline: Ember.computed('status', 'validityDate', 'awardExpiredDate', 'vAcceptExpiredDate', function () {
      let now = this.get('adkSessionAccount').get('myNow');
      let deadline;
      let status = this.get('status');
      if (status === 'award') {
        deadline = (0, _moment.default)(this.get('awardExpiredDate'));
      } else if (status === 'vAccept') {
        deadline = (0, _moment.default)(this.get('vAcceptExpiredDate'));
      } else {
        deadline = (0, _moment.default)(this.get('validityDate'));
      }
      if (!deadline) {
        return '';
      }
      let diffDay = deadline.diff(now, "seconds");
      if (diffDay <= 86400 && diffDay > 0) {
        diffDay = 'Today is the deadline';
      } else if (diffDay > 86400) {
        diffDay = deadline.diff(now, "days");
        diffDay += " Days to go";
        //do nothing;
      } else {
        diffDay = 'Past Deadline';
      }
      return diffDay;
    }),
    passDeadline: Ember.computed("daysToDeadline", function () {
      let daysToDeadline = this.get('daysToDeadline');
      if (daysToDeadline === 'Past Deadline' || daysToDeadline == '') {
        return true;
      }
      return false;
    })
  });
});