define("adtikir3v1/pods/components/adkcard-addashboard-activities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isEditing: false,
    adkToast: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('columns', [{
        "propertyName": "displayActivityDate",
        "title": "Date"
      },
      // {
      //   "propertyName": "campaignID",
      //   "title": "Campaign ID"
      // },
      // {
      //   "propertyName": "adID",
      //   "title": "AD ID"
      // },
      {
        "propertyName": "userName",
        "title": "User"
      },
      // {
      //   "propertyName": "companyName",
      //   "title": "Company Name"
      // },
      {
        "propertyName": "description",
        "title": "Description"
      }
      // {
      //   "propertyName": "status",
      //   "title": "Status"
      // }
      ]);
    },
    //init

    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      }
    }
  });
});