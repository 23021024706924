define("adtikir3v1/pods/components/adk-display-vendor-media-list/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    pageSize: 5,
    store: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('vendorMediaList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadVendorMediaListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    loadVendorMediaListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      this.closeVendorMediaDetail();
      let vendorMedia = yield this.store.query('vendor-media-detail', {
        page: page,
        pageSize: pagingObj.pageSize,
        vendor: this.get('vendorId')
      });
      Ember.set(pagingObj, "page", page);
      Ember.set(pagingObj, "result", vendorMedia);
    }).restartable(),
    actions: {
      showVendorMediaDetail(vendorMedia, event) {
        if (this.activeMdClass) {
          this.activeMdClass.classList.remove("adkListCardActive");
        }
        let currentRecord = event.currentTarget.parentElement.parentElement;
        currentRecord.classList.add('adkListCardActive');
        this.set('activeMdClass', currentRecord);
        this.showVendorMediaDetail(vendorMedia);
      }
    }
  });
});