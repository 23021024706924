define("adtikir3v1/pods/campaign/details/reports/financials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gdoW38eO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex layout-align-start-stretch\"],[8],[0,\"\\n\"],[5,\"adk-report-financials\",[],[[\"@adCampaignInvoice\",\"@fieldTitle\"],[[22,\"adCampaignInvoice\"],[28,\"concat\",[[28,\"capitalize\",[[28,\"t\",[\"campaign\"],null]],null],\" Financial Report\"],null]]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\" \\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/reports/financials/template.hbs"
    }
  });
});