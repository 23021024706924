define("adtikir3v1/helpers/get-time-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function getTimeZone([timeZone, timeFormat]) {
    const event = new Date();
    let hour12 = false;
    if (timeFormat == "12-hour") {
      hour12 = true;
    } else {
      hour12 = false;
    }
    let time = event.toLocaleTimeString('en-US', {
      timeZone: `${timeZone}`,
      hour12: hour12
    });
    return time;
  });
});