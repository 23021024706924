define("adtikir3v1/models/ad-media-vendor-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    profilePic: attr('string'),
    aboutMe: attr('string'),
    email: attr('string'),
    adMediaVendor: belongsTo('ad-media-vendor', {
      async: true
    })
  });
});