define("adtikir3v1/pods/components/adkcard-dashboard-activities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      this.set('columns', [{
        "propertyName": "displayActivityDate",
        "title": "Date"
      }, {
        "propertyName": "userName",
        "title": "User"
      }, {
        "propertyName": "description",
        "title": "Description"
      }]);
    }
  });
});