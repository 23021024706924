define("adtikir3v1/models/ad-campaign", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;

  //Campaign Data comprise of two models - adcampaign.js and adcampaigndetail.js
  //adcampaign.js is retrieved first and the date is kept to minimum to allow fast retrieval
  //and generate a listing of Campaigns.
  //adcampaigndetail.js model is additional data (details) for a selected Campaign.
  //adcampaigndetail contains reference to ad.js - detail listing of each ad

  //let campaignBudgetLevel = 10;  //percentage of the campaign level
  var _default = _exports.default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    //action for update
    action: attr('string', {
      default: ''
    }),
    updatedAt: attr('date'),
    createdAt: attr('date'),
    campaignCreator: attr('string'),
    status: attr('string', {
      default: "new"
    }),
    budgetIsApproved: attr('boolean', {
      default: false
    }),
    adCampaignDetail: belongsTo('adCampaignDetail', {
      async: true
    }),
    // proposal                      : belongsTo('proposal', { default: null} ),

    // ads                       : hasMany('ad'),

    //company will be null if memberType = homeOffice
    company: belongsTo('my-company', {
      async: true
    }),
    company2: belongsTo('company', {
      async: true
    }),
    isHomeOffice: attr('boolean'),
    myRole: belongsTo('myRole'),
    campaignName: attr('string'),
    campaignNameLocal: attr('string'),
    //
    campaignPic: attr('string'),
    campaignStartDate: attr('date'),
    campaignEndDate: attr('date'),
    campaignProductLaunchDate: attr('date'),
    clientRefId: attr('string'),
    versionNumber: attr('number'),
    currencyType: attr('string'),
    currency: attr('string'),
    exchangeRate: attr('number'),
    exchangeRateDate: attr('string'),
    foreignCurrency: attr('string'),
    exchangeRateType: attr('string'),
    proposalDeadline: attr('date'),
    //currency                : attr('string'),

    campaignBudgetFlag: Ember.computed('campaignBudget', 'campaignPlanned', 'budgetIsApproved', function () {
      let str = "success";
      let budgetIsApproved = this.get('budgetIsApproved');
      let campaignBadguetLevel = this.get('campaignBadguetLevel');
      if (!budgetIsApproved) {
        return "danger";
      }
      let campaignBudget = this.get('campaignBudget');
      let campaignPlanned = this.get('campaignPlanned');
      let percentage = (campaignBudget - campaignPlanned) / campaignBudget * 100;
      if (percentage <= 0 && campaignBudget > 0) {
        return "danger";
      }
      if (percentage <= campaignBadguetLevel) {
        return "warning";
      }
      return str;
    }),
    //syeo added
    //  in process
    //  review
    //  approaved
    //  update
    campaignBudgetStatus: attr('string', {
      defaultValue: 'In Process'
    }),
    /** below 3 fields is used for budget review process
     *   syeo, with sharing info for campaignBudgetComments
     */
    campaignBudgetReviewStatus: attr('string', {
      defaultValue: ''
    }),
    campaignBudgetReviewFlag: attr('boolean', {
      defaultValue: false
    }),
    campaignBudgetReview: attr('number', {
      defaultValue: 0
    }),
    // Change fragmentArray to hasMany
    // campaignBudgetComments : fragmentArray('budget-comment'),
    // campaignBudgetComments        : hasMany('budget-comment'),

    campaignUltimateBudget: attr('number', {
      defaultValue: 0
    }),
    campaignEstBudget: attr('number', {
      defaultValue: 0
    }),
    campaignBudget: attr('number', {
      defaultValue: 0
    }),
    campaignPlanned: attr('number', {
      defaultValue: 0
    }),
    campaignCommitted: attr('number', {
      defaultValue: 0
    }),
    campaignActualSpend: attr('number', {
      defaultValue: 0
    }),
    campaignBudgetAvailable: Ember.computed('campaignBudget', 'campaignPlanned', function () {
      let campaignBudget = this.get('campaignBudget');
      let campaignPlanned = this.get('campaignPlanned');
      return campaignBudget - campaignPlanned;
    }),
    campaignBudgetActualAvailable: Ember.computed('campaignBudget', 'campaignCommitted', function () {
      return this.get('campaignBudget') - this.get('campaignCommitted');
    }),
    // Performance Data
    // kpi added by Soon - To bind campaign performance to what client wants to measure
    // For example - measure of campaign performance as it relates to rating

    // kpi1Campaign                   : attr('number'),
    // kpi1CampaignDesc               : attr('string'),
    // kpi2Campaign                   : attr('number'),
    // kpi2CampaignDesc               : attr('string'),

    // url                            : attr('string'),
    // clientBriefShow                : attr('string'),
    // projectAccount                 : attr('string'),
    totalNumberAds: attr('number', {
      defaultValue: 0
    }),
    totalNumberAdsApproval: attr('number', {
      defaultValue: 0
    }),
    numberAdsApproved: attr('number', {
      defaultValue: 0
    }),
    numberAdsNotApproved: attr('number', {
      defaultValue: 0
    }),
    awardProposalResponseId: attr('string', {
      defaultValue: ''
    }),
    awardProposalResponseFg: attr('string', {
      defaultValue: 'no'
    }),
    //no, 
    awardedByFirstName: attr('string', {
      defaultValue: ''
    }),
    awardedByLastName: attr('string', {
      defaultValue: ''
    }),
    awardedByRole: attr('string', {
      defaultValue: ''
    }),
    // created by syeo.. 
    // add in for awarded proposal
    // 3 statuses, award, vAccepted or emptyString   
    aProposalStatus: attr('string', {
      default: ''
    }),
    aProposalResponseAwarded: attr('string', {
      default: ''
    }),
    // financials
    // invoiceCampaigns              : hasMany('invoiceCampaign'),

    daysToLaunch: Ember.computed('campaignProductLaunchDate', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let now = (0, _moment.default)(adkSessionAccount.get('myToday'));
      let launchDate = (0, _moment.default)(this.get('campaignProductLaunchDate'));
      let diffDay = launchDate.diff(now, 'days');
      if (diffDay > 0) {
        diffDay += " Days";
        //do nothing;
      } else if (diffDay == 0) {
        diffDay = 'Today is the day';
      } else if (diffDay < 0) {
        diffDay = 'Product Already Launched';
      }
      return diffDay;
      // return moment(new Date(this.get('launchDate'))).diff(now,'days');
    })
  });
});