define("adtikir3v1/pods/components/adk-cancel-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isPop: false,
    willInsertElement: function () {
      this.set("emberId", this.get('elementId'));
    },
    actions: {
      actionCancel() {
        this.set('isPop', true);
      },
      cancelCancelDialog() {
        this.set('isPop', false);
      },
      continueCancelDialog() {
        this.set('isPop', false);
        this.get('theCancelAction')();
      }
    }
  });
});