define("adtikir3v1/pods/components/adkcard-addashboard-deadlines/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {
  //   sort
  // } from '@ember/object/computed';
  //pass in
  // @ad
  // @acWorkFlows
  // @saveDeadlinesAction
  // @loadDeadlinesAction
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    showDeadlineForm: false,
    //showPromptDialog: false,
    showDeleteForm: false,
    editFlag: false,
    adkToast: Ember.inject.service(),
    pageNo: 0,
    pageSize: 3,
    theDeadline: null,
    refreshLocal: 0,
    refreshGlobal: 0,
    didReceiveAttrs() {
      this._super();
      this.set('refreshLocal', new Date().getTime());
      let component = this;
      let adkSessionAccount = this.get('adkSessionAccount');
      let currentCompany = adkSessionAccount.get('myCompany').companyName;
      component.set('currentCompany', currentCompany);
    },
    // workflowStatus... contrauct the enble array of workFlow
    acWorkFlowOptions: Ember.computed('acWorkFlows.[]', function () {
      let array = [];
      let acWorkFlows = this.get('acWorkFlows');
      acWorkFlows.forEach(function (item, index) {
        if (item.isSelected && item.id != acWorkFlows.length - 1) {
          array.pushObject({
            index: index,
            wflowstatus: item.workFlowStatus
          });
        }
      });
      return array;
    }),
    adDeadlinesTask: Ember.computed('refreshLocal', 'refreshGlobal', function () {
      return this.loadDeadlinesAction(this.get('ad').id, this.pageNo, this.pageSize);
    }),
    totalPage: Ember.computed('adDeadlinesTask.value.total', function () {
      //let total = this.adDeadlinesTask.value.total;
      let total = 0;
      if (this.adDeadlinesTask.value && this.adDeadlinesTask.value.total) {
        total = this.adDeadlinesTask.value.total;
      }
      let pageSize = this.pageSize;
      return Math.ceil(total / pageSize) - 1;
    }),
    // this.campaignStartDate = ''
    //dateFreeze: Object.freeze(['workFlowStatusNumber:asc', 'deadlineDate:desc']),
    //newDeadlineSorted: sort('ad.adDeadlines', 'dateFreeze'),

    resetForm: function () {
      this.set('editFlag', false);
      this.set('theDeadline', null);
    },
    actions: {
      actionDeleteDeadline: function () {
        //let theDeadline = this.get('theDeadline');
        let theComponent = this;
        let adkToast = this.get('adkToast');
        //let ad = this.get('ad');
        let theDeadline = this.get('theDeadline');
        // let deadlineAdRelatedField = theDeadline.deadlineAdRelatedField;

        theDeadline.deleteRecord();
        theDeadline.save().then(function () {
          adkToast.display("Deadline deleted successfully", "warning");
          //thisComponent.resetForm();
          theComponent.set('showDeleteForm', false);
          theComponent.set('pageNo', 0);
          theComponent.set('refreshLocal', new Date().getTime());
          theComponent.get('ad').reload();
          // theComponent.set("adDeadlinesTask",         
          //     theComponent.loadDeadlinesAction(
          //        theComponent.get('ad').id, 
          //        theComponent.pageNo, 
          //        theComponent.pageSize
          //     )
          // );   
          document.getElementById("scripted").focus();
        }, function () {
          if (theDeadline.get('hasDirtyAttributes')) {
            theDeadline.rollbackAttributes();
          }
          adkToast.display("Fail to delete deadline", "warning");
        });
      },
      prevPage() {
        if (this.pageNo > 0) {
          this.set('pageNo', this.pageNo - 1);
        }
        this.set('refreshLocal', new Date().getTime());

        //this.set("adDeadlinesTask", this.loadDeadlinesAction(this.get('ad').id, this.pageNo, this.pageSize));
      },
      nextPage() {
        if (this.pageNo < this.get('totalPage')) {
          this.set('pageNo', this.pageNo + 1);
        }
        this.set('refreshLocal', new Date().getTime());
        //this.set("adDeadlinesTask", this.loadDeadlinesAction(this.get('ad').id, this.pageNo, this.pageSize));
      },
      showDeleteDeadline(deadLine, event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('theDeadline', deadLine);
        this.set('showDeleteForm', true);
      },
      cancelForm: function () {
        this.resetForm();
        this.set('showDeleteForm', false);
        this.set('showDeadlineForm', false);
      },
      showAddNewDeadline(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.resetForm();
        this.set('showDeadlineForm', true);
      },
      showEditDeadline(deadline, event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('editFlag', true);
        this.set('theDeadline', deadline);
        this.set('showDeadlineForm', true);
      },
      addNewDeadline(theDeadLine) {
        let theComponent = this;
        let adkToast = this.get('adkToast');
        return this.get('saveDeadlinesAction')(theDeadLine).then(function () {
          adkToast.display("Record created", "warning");
          theComponent.resetForm();
          theComponent.set('showDeadlineForm', false);
          //rerun the adDeadLinesTask;
          theComponent.set('pageNo', 0);
          theComponent.set('refreshLocal', new Date().getTime());
          //  theComponent.set("adDeadlinesTask", 
          //                   theComponent.loadDeadlinesAction(
          //                       theComponent.get('ad').id, 
          //                       theComponent.pageNo, 
          //                       theComponent.pageSize
          //                     )
          //                   );
        });
      },
      editDeadline(theDeadLine) {
        let theComponent = this;
        let adkToast = this.get('adkToast');
        return this.get('saveDeadlinesAction')(theDeadLine).then(function () {
          adkToast.display("Record updated", "warning");
          theComponent.resetForm();
          theComponent.set('showDeadlineForm', false);
          theComponent.set('refreshLocal', new Date().getTime());
          // theComponent.set("adDeadlinesTask", 
          //                    theComponent.loadDeadlinesAction(
          //                           theComponent.get('ad').id, 
          //                           theComponent.pageNo, 
          //                           theComponent.pageSize
          //                    )
          //                 );
          document.getElementById("scripted").focus();
        }, function () {
          if (theDeadLine.get('hasDirtyAttributes')) {
            theDeadLine.rollbackAttributes();
          }
          adkToast.display("Record fail to update", "warning");
        });
      }
    } //actions
  });
});