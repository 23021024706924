define("adtikir3v1/pods/components/adkpanel-adpost-discussion-comment/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('adPDCmtList', {
        page: 0,
        pageSize: 2,
        action: this.get("loadAdPostDiscussionCmtFunc"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      let component = this;
      let adPost = this.get('adPost');
      adPost.get('latestDiscussion').then(function (latestDiscussion) {
        component.set('latestDiscussion', latestDiscussion);
      });
    },
    loadAdPostDiscussionCmtFunc: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adPostDiscussionId = this.get('aPD').id;
      let result = yield this.store.query('ad-post-discussion-comment', {
        adPostDiscussion: adPostDiscussionId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    newAdPDCmt: (0, _emberConcurrency.task)(function* () {
      yield this.saveNewAdPDCmt({
        comment: this.get('newComment')
      }, this.get('aPD'));
      this.actions.closeCommentDialog.apply(this);
      this.set('adPDCmtList', {
        page: 0,
        pageSize: 2,
        action: this.get("loadAdPostDiscussionCmtFunc"),
        result: null,
        refresh: true,
        type: "emberdata"
      });
    }),
    actions: {
      openCommentDialog(event) {
        this.set('dialogOrigin', Ember.$(event.currentTarget));
        this.set('showNewComment', true);
      },
      closeCommentDialog() {
        this.set('showNewComment', false);
        this.set('newComment', '');
      }
    }
  });
});