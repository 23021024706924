define("adtikir3v1/models/targeting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    male1: attr('boolean', {
      defaultValue: false
    }),
    male2: attr('boolean', {
      defaultValue: false
    }),
    male3: attr('boolean', {
      defaultValue: false
    }),
    male4: attr('boolean', {
      defaultValue: false
    }),
    male5: attr('boolean', {
      defaultValue: false
    }),
    male6: attr('boolean', {
      defaultValue: false
    }),
    male7: attr('boolean', {
      defaultValue: false
    }),
    male8: attr('boolean', {
      defaultValue: false
    }),
    female1: attr('boolean', {
      defaultValue: false
    }),
    female2: attr('boolean', {
      defaultValue: false
    }),
    female3: attr('boolean', {
      defaultValue: false
    }),
    female4: attr('boolean', {
      defaultValue: false
    }),
    female5: attr('boolean', {
      defaultValue: false
    }),
    female6: attr('boolean', {
      defaultValue: false
    }),
    female7: attr('boolean', {
      defaultValue: false
    }),
    female8: attr('boolean', {
      defaultValue: false
    }),
    targetSet: attr('boolean', {
      defaultValue: false
    }),
    targetIndustry: attr('boolean', {
      defaultValue: false
    }),
    isMarried: attr('boolean', {
      defaultValue: false
    }),
    hasChildren: attr('boolean', {
      defaultValue: false
    }),
    incomeLevel: attr('boolean', {
      defaultValue: false
    }),
    lowIncome: attr('boolean', {
      defaultValue: false
    }),
    middleIncome: attr('boolean', {
      defaultValue: false
    }),
    upperMiddleIncome: attr('boolean', {
      defaultValue: false
    }),
    highIncome: attr('boolean', {
      defaultValue: false
    }),
    adCampaignDetail: belongsTo('adCampaignDetail')
  });
});