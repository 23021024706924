define("adtikir3v1/pods/campaign/details/ads/ad/vendors/media/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    setupController(controller, model) {
      this._super(...arguments);
      let router = this.get('router');
      Ember.run.later(controller, function () {
        let routeArray = ['rfpmanagement', 'rfp', 'proposal'];
        let lastPcs = router.currentRouteName.split(".").slice(-1)[0];
        let tabIdx = routeArray.indexOf(lastPcs);
        if (tabIdx < 0) {
          tabIdx = 0;
        }
        this.set('selectedBasicTab', tabIdx);
      }, 500);
    }
  });
});