define("adtikir3v1/pods/components/adk-standard-paging/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //syeo
  // by calling this component, the parent need to repare this object for the component to work 
  // action is a concurency task.. 
  // with 2 parameters
  // loadXxxxxTask: task (function* (page, xxxxx))
  // this.set('xxxxx', {
  //   page: 0,
  //   pageSize: 20,
  //   action: this.get("loadXxxxxTask"),  
  //   refresh: false;
  //   type: "emberdata" OR "array"   
  // });
  var _default = _exports.default = Ember.Component.extend({
    refresh2Func() {
      this.refreshIsRunning = 1;
      let pagingObj = this.get('pagingObj');
      let refresh = this.get('refresh');
      if (refresh) {
        Ember.set(pagingObj, "refresh", false);
        Ember.set(this, "refresh", false);
      }
      Ember.set(pagingObj, "page", 0);
      //set(pagingObj, "actionInstance", pagingObj.action.perform(0, pagingObj));
      this.loadPageTask.perform();
      this.refreshIsRunning = 0;
    },
    refreshFunc() {
      let pagingObj = this.get('pagingObj');
      if (pagingObj.refresh) {
        Ember.set(pagingObj, "refresh", false);
      }
      Ember.set(pagingObj, "page", 0);
      //set(pagingObj, "actionInstance", pagingObj.action.perform(0, pagingObj));
      if (this.refreshIsRunning != 1) {
        this.loadPageTask.perform();
      }
    },
    pageArray: Ember.computed('totalPage', function () {
      let totalPage = this.get('totalPage');
      if (totalPage > 1) {
        return Array.from(Array(totalPage).keys(), x => x + 1);
      } else {
        return [];
      }
    }),
    totalPage: Ember.computed('pagingObj.result.[]', function () {
      let pagingObj = this.get('pagingObj');
      let result = pagingObj.result;
      let total = 0;
      if (pagingObj.type === "emberdata") {
        // if (result && result.meta && result.meta.page) {
        //   total = result.meta.page.total;
        // }
        total = pagingObj.total;
      } else if (pagingObj.type === "array") {
        total = result.length;
      }
      let totalPage = Math.ceil(total / pagingObj.pageSize);
      if (result && result.totalPage != totalPage) {
        return totalPage;
      }
      return 0;
    }),
    arrayIndex: Ember.computed('pagingObj.{page,result.[]}', function () {
      let pagingObj = this.get('pagingObj');
      if (pagingObj.type === 'array') {
        let startIndex = pagingObj.page * pagingObj.pageSize;
        let endIndex = startIndex + pagingObj.pageSize - 1;
        if (endIndex >= pagingObj.result.length) {
          endIndex = pagingObj.result.length - 1;
        }
        return {
          startIndex: startIndex,
          endIndex: endIndex
        };
      }
      return {};
    }),
    init() {
      this._super(...arguments);
    },
    loadPageTask: (0, _emberConcurrency.task)(function* () {
      let pagingObj = this.get('pagingObj');
      if (pagingObj.action && pagingObj.action.perform) {
        Ember.set(pagingObj, "actionInstance", pagingObj.action.perform(pagingObj.page, pagingObj));
      }
      yield pagingObj.actionInstance;
      let result = pagingObj.result;
      let total = 0;
      if (pagingObj.type === "emberdata") {
        if (result && result.meta && result.meta.page) {
          total = result.meta.page.total;
        }
      } else if (pagingObj.type === "array") {
        total = result.length;
      }
      Ember.set(pagingObj, "total", total);
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      let pagingObj = this.get('pagingObj');
      //add observer function..so, when refresh flag change, call page to refresh...    
      Ember.addObserver(this, 'pagingObj.refresh', this.refreshFunc);
      Ember.addObserver(this, 'refresh', this.refresh2Func);
      //addObserver(this, 'pagingObj.result.[]', this.setTotalFunc);
      //always load data when first time...

      if (pagingObj.pageSize <= 0) {
        pagingObj.pageSize = 1;
      }

      // if (pagingObj.action && pagingObj.action.perform) {
      //    set(pagingObj, "page", 0);       
      //    set(pagingObj, "actionInstance", pagingObj.action.perform(pagingObj.page, pagingObj));
      // }
      //when start, always set to page 1;
      Ember.set(pagingObj, "page", 0);
      this.loadPageTask.perform();
    },
    willDestroyElement() {
      Ember.removeObserver(this, 'pagingObj.refresh', this.refreshFunc);
      Ember.removeObserver(this, 'refresh', this.refresh2Func);
      //removeObserver(this, 'pagingObj.result.[]', this.setTotalFunc);
    },
    actions: {
      //paging functions

      setPage(pagingObj, number) {
        number = number - 1;
        //let obj = this.get(loadObj);
        if (number >= this.get('totalPage')) {
          number = this.get('totalPage') - 1;
        } else if (number < 0) {
          number = 0;
        }
        Ember.set(pagingObj, "page", number);
        //set(pagingObj, "actionInstance", pagingObj.action.perform(number, pagingObj));
        this.loadPageTask.perform();
      },
      nextPage(pagingObj) {
        this.actions.setPage.call(this, pagingObj, pagingObj.page + 2);

        //this.actions.setPage.call(this, this.get('page') + 1);
      },
      prevPage(pagingObj) {
        this.actions.setPage.call(this, pagingObj, pagingObj.page);
        //this.actions.setPage.call(this, this.get('page')-1);
      },
      toTop() {
        //let target = event.target;
        this.element.scrollIntoView();
        //target.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
      }
    } //actions
  });
});