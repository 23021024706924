define("adtikir3v1/helpers/get-image-url", ["exports", "adtikir3v1/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function getImageUrl(url) {
    let wip = url.firstObject.substr(7);
    wip = wip.substring(wip.indexOf('/'));
    return _environment.default.cdnHost + wip;
  });
});