define("adtikir3v1/pods/profile/company/prefer-vendor/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {
  //     set
  // } from '@ember/object';
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    showPVendorInvitationDialog: false,
    init() {
      this._super(...arguments);
      this.setProperties({
        emailValidation: [{
          message: 'Please provide email in a valid format',
          validate: inputValue => {
            let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailPattern.test(inputValue);
          }
        }] //emailValidation       
      });
    },
    // resetData() {
    //     //clear invitation form
    // },

    actions: {
      // actionToEditMode() {
      //     this.set('editMode', true);
      // },

      openAddInvitationDialog(ev) {
        this.resetData();
        this.set('dialogOrigin', ev.currentTarget);
        this.set('showPVendorInvitationDialog', true);
      },
      closeAddInvitationDialog() {
        this.set('showPVendorInvitationDialog', false);
      },
      insertAddInvitation() {
        //send invitation function
        this.set('showInvitationDialog', false);
      }

      // updateProfile() {

      // },

      // selectVendors() {

      // },

      // removeVendor() {

      // }
    }
  });
});