define("adtikir3v1/models/planned-budget-comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    userRoleId: attr('string'),
    role: attr('string'),
    action: attr('string'),
    comment: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    workFlowStatusNumber: attr('number'),
    amountLocal: attr('number'),
    chargeType: attr('string'),
    status: attr('string'),
    adCampaignPlannedBudgetApproved: belongsTo('adCampaignPlannedBudgetApproved')
  });
});