define("adtikir3v1/pods/components/adk-vendor-proposal-compare-tbd/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {
  //   A
  // } from '@ember/array';
  // import {
  //   inject as service
  // } from '@ember/service';
  // import {
  //   computed
  // } from '@ember/object';
  // import Bootstrap4 from "../../../themes/bootstrap4";
  var _default = _exports.default = Ember.Component.extend({
    //   init() { //Need to initialize the values of the selectedItems first.
    //     this._super(...arguments);
    //   },

    //   compareList: computed("proposalResponses.[]", "proposalShortlisteds.[]", function() {
    //     let proposalResponses = this.get('proposalResponses');
    //     let proposalShortlisteds = this.get('proposalShortlisteds');

    //     if (!proposalResponses) {
    //       return A();
    //     }
    //     //proposalResponses = proposalResponses.toArray();
    //     if (!proposalShortlisteds) {
    //       proposalShortlisteds = A();
    //     }

    //     //c which one bigger...
    //     let rtnVal = proposalResponses.filter(function(item) {
    //        if (proposalShortlisteds) {
    //           for (let i=0; i<proposalShortlisteds.length;i++) {
    //              if (proposalShortlisteds.objectAt(i).belongsTo('proposalResponse').id() == item.id) {
    //                 return false;
    //              }
    //           }
    //           return true;
    //        }
    //        return true;
    //     });
    //     return rtnVal;
    //   }),

    //   bootstrap4Theme: Bootstrap4.create(),

    // //  isSelected: false,
    //   showComponentFooter: false,
    //   //showColumnsDMediaStreamTrackAudioSourceNode
    //   useFilteringByColumns: false,
    //   showGlobalFilter: false,
    // //  useNumericPagination: false,
    // //  doFilteringByHiddenColumns: false,
    // //  filteringIgnoreCase: false,
    // //  multipleColumnsSorting: true,
    // //  showPageSize: false,
    //   // export default class ComplexComponent extends Component {

    //   store: service(), //Injected store to be able to use it

    get columns() {
      return [{
        "title": "Vendor name",
        "propertyName": 'agency.companyName'
      }, {
        "title": "Rating",
        "component": "allRating"
      }, {
        "title": "Comments",
        "propertyName": 'ratingComment'
      }, {
        "title": "Short listing action",
        "component": "shortlisted"
      }];
    },
    get columns2() {
      return [{
        "title": "Vendor name",
        "propertyName": 'proposalResponse.agency.companyName'
      }, {
        "title": "Rating",
        "component": "allRating"
      }, {
        "title": "Rate Comments",
        "propertyName": 'proposalResponse.ratingComment'
      }, {
        "title": "Shortlisted By",
        "propertyName": 'shortlistedBy.userName'
      }, {
        "title": "Shortlisted Comments",
        "propertyName": 'shortlistedComment'
      }, {
        "title": "Short listing action",
        "component": "shortlisted"
      }];
    }

    //   // get selectedColumns() {
    //   //   return [{
    //   //       propertyName: 'name'
    //   //     },
    //   //     {
    //   //       propertyName: 'country'
    //   //     },
    //   //     {
    //   //       propertyName: 'languages'
    //   //     },
    //   //     {
    //   //       propertyName: 'industry'
    //   //     },
    //   //     {
    //   //       propertyName: 'rating'
    //   //     },
    //   //     {
    //   //       propertyName: 'description'
    //   //     },
    //   //     {
    //   //       propertyName: 'chosen'
    //   //     },

    //   //   ];
    //   // },

    //   actions: {
    //     onSaveRow(param) {
    //       // return a truthy value (or Promise) for the row to exit edit mode
    //       console.log(param.record.country);
    //       return param.record.save();
    //     },

    //     // Example of ES6 destructuring parameters
    //     onCancelRow({
    //       record
    //     }) {
    //       record.rollbackAttributes();
    //       // return a truthy value for the row to exit edit mode
    //       return true;
    //     },

    //     onAddVendor({
    //       record
    //     }) {
    //       record.chosen
    //       return record.save();
    //     },
    //   },
  });
});