define("adtikir3v1/pods/components/adk-ad-budget-card/component", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { reads } from '@ember/object/computed';
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    comment: '',
    showApproval: false,
    // ad: this.get('ad'),
    // adBudgetWorkflowStep: this.ad.get('adBudgetWorkflowStep'),

    // ad: 'this.get(ad)',
    // ad: this.get('ad'),
    // adBudgetWorkflowStep: this.get(ad.adBudgetWorkflowStep),

    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        adBudgetWorkflowStep: this.ad.get('adBudgetWorkflowStep'),
        exchangeRate: this.adCampaign.get('exchangeRate')
      });
      if (!this.mode) {
        this.mode = "normal";
      }
      let ad = this.get('ad');
      this.set('escapeAdId', ad.id.replace(/^[^a-z]+|[^\w:-]+/gi, ""));
      this.maxEditStep = 2;

      // myCompany will locked to the lastLoginRole->company id...
      // so what ever send in also will return back the lastLoginRole company id
      let component = this;
      let adkSessionAccount = this.adkSessionAccount;
      if (this.adkSessionAccount.myCompany.memberType === "homeOffice") {
        ad.get("adCampaign").then(function (myAdcampaign) {
          myAdcampaign.get('company2').then(function (company2) {
            if (adkSessionAccount.myCompany.id != company2.id) {
              component.set('maxEditStep', 4);
            }
          });
        });
      } else if (this.adkSessionAccount.myCompany.memberType === "territoryOffice") {
        ad.get("adCampaign").then(function (myAdcampaign) {
          myAdcampaign.get('company2').then(function (company2) {
            if (adkSessionAccount.myCompany.id != company2.id) {
              component.set('maxEditStep', 3);
            }
          });
        });
      } //else if memberTy[e] 
    },
    adMediaType: Ember.computed('ad.mediatype', function () {
      let ad = this.get('ad');
      let adMediaIconMediaTypeLocation = this.store.peekRecord('mediaType', ad.mediatype);
      return adMediaIconMediaTypeLocation;
    }),
    adMediaFormat: Ember.computed('ad.mediaformat', function () {
      let ad = this.get('ad');
      let result = '';
      if (ad.mediaformat) {
        result = this.store.findRecord('mediaFormat', ad.mediaformat);
      }
      return result;
    }),
    adTotalLocal: Ember.computed('ad.{estMediaBudget,estAgencyBudget,estMiscBudget}', function () {
      let totalBudget = 0;
      let ad = this.get('ad');
      totalBudget = ad.estMediaBudget + ad.estAgencyBudget + ad.estMiscBudget;
      return totalBudget;
    }),
    actions: {
      adBudgetWorkflowStep() {
        this.set('showApproval', true);
      },
      closeErr() {
        this.set('showErrorFg', false);
      },
      openDialogWithParent(dialogName, event) {
        this.set('dialogOrigin', event.target);
        this.set(dialogName, true);

        //console.log(event.target.parentNode.parentNode.parentNode.parentNode.parentNode.id);
        let ad = this.get('ad');
        let escapeAdId = this.get('escapeAdId');
        this.set('dialogParent', "#md-card-" + escapeAdId);
        let adBudgetComment = this.store.query('budgetComment', {
          'ad': ad.id
        });
        this.set('adBudgetComment', adBudgetComment);
        // this.set('adBudgetWorkflowStep', adBudgetWorkflowStep);
        this.set('adBudgetWorkflowStep', this.ad.get('adBudgetWorkflowStep').toString());
        // this.set('budgetStatus', adBudgetComment.adBudgetWorkflowStep);
      },
      closeDialogWithParent(dialogName) {
        this.set('comment', '');
        this.set(dialogName, false);
      },
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      async budgetCommentSave() {
        // event.preventDefault();

        let adkToast = this.get('adkToast');
        this.toggleProperty('isEditing');
        let ad = this.get('ad');
        if (this.comment) {
          let sanitizeComment = (0, _sanitizeHtml.default)(this.comment);
          const budgetComment = this.store.createRecord('budgetComment', {
            comment: sanitizeComment,
            adBudgetWorkflowStep: parseInt(this.adBudgetWorkflowStep, 10),
            // adBudgetWorkflowStep: this.adBudgetWorkflowStep.,
            adId: ad.id
          });

          // console.log("************######***********");
          // console.log('adk-ad-budget-card adBudgetWorkflowStep save ',this.adBudgetWorkflowStep );
          // console.log('adk-ad-budget-card budgetComment.adBudgetWorkflowStep save ',budgetComment.adBudgetWorkflowStep );
          // console.log("************######***********");        
          await budgetComment.save();
          this.set('comment', '');
          this.set('adBudgetWorkflowStep', '');
          adkToast.display("Comment successfully saved.", "warning");
          ad.reload();
          this.refreshCurrentRoute();
        }
        this.set('showDialogWithParent', false);

        // budgetComment.save().then(() => {
        // this.set('showDialogWithParent', false); 
        // });
        // this.parentSaveName({
        //   estAgencyBudget:  this.get('estAgencyBudget'),
        //   estMediaBudget:   this.get('estMediaBudget'),
        //   estMiscBudget:    this.get('estMiscBudget'),
        // }); 
        //End of localComponentSaveName
        // this.set('showDialogWithParent', false);
      },
      editCampaignDate() {}
    }
  });
});