define("adtikir3v1/models/vendor-mediatype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
    // belongsTo 
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // mediaType                       : belongsTo('mediaType', {async: true}),
    // vendor                          : belongsTo('vendor', {async: true}),
    MediaTypeId: attr('string'),
    VendorId: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});