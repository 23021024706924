define("adtikir3v1/pods/components/adk-agency-rfp/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    campaignName: '',
    campaignNameLocal: '',
    loadIndustryTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignRfp = this.get('adCampaignRfp');
      if (!adCampaignRfp.get('targetingIndustry')) {
        return [];
      }
      let targetingIndustryArray = adCampaignRfp.get('targetingIndustry').split(',');
      let returnArray = [];
      for (let i = 0; i < targetingIndustryArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('industry-type', targetingIndustryArray[i]);
      } //for
      this.set('industryArray', returnArray);
    }),
    loadLanguageTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignRfp = this.get('adCampaignRfp');
      if (!adCampaignRfp.get('targetingLanguage')) {
        return [];
      }
      let targetingLanguageArray = adCampaignRfp.get('targetingLanguage').split(',');
      let returnArray = [];
      for (let i = 0; i < targetingLanguageArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('language', targetingLanguageArray[i]);
      } //for
      this.set('languageArray', returnArray);
    }),
    loadLocationTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignRfp = this.get('adCampaignRfp');
      if (!adCampaignRfp.get('targetingLocation')) {
        return [];
      }
      let targetingLocationArray = adCampaignRfp.get('targetingLocation').split(',');
      let returnArray = [];
      for (let i = 0; i < targetingLocationArray.length; i++) {
        let model = '';
        if (targetingLocationArray[i] === "City") {
          model = "location-city";
        } else if (targetingLocationArray[i] === "State") {
          model = "location-state";
        } else {
          model = "location-country";
        }
        let location = yield this.store.findRecord(model, targetingLocationArray[i + 1]);
        i++;
        returnArray.push(location);
      } //for
      this.set('locationArray', returnArray);
    }),
    loadRfpAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignRfpId = this.get('adCampaignRfp').id;
      let attachments = yield this.store.query('rfp-attachment', {
        adCampaignRfp: adCampaignRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }
      Ember.set(pagingObj, "result", attachments);
      //return attachments;
    }).restartable(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('loadIndustryTaskInstance', this.loadIndustryTask.perform());
      this.set('loadLanguageTaskInstance', this.loadLanguageTask.perform());
      this.set('loadLocationTaskInstance', this.loadLocationTask.perform());

      // this.setProperties({

      //   // adCampaignRfp:    this.store.findRecord('adCampaignRfp', this.get('adCampaignRfpId')),
      //   adCampaignRfp: this.get('adCampaignRfp'),
      //   daysToDeadline: this.get('daysToDeadline'),
      // })
    },
    // industryArray: computed("adCampaignRfp.targetingIndustry", function() {

    // }),

    actions: {
      nothing() {}
    }
  });
});