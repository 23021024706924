define("adtikir3v1/pods/components/adk-models-table-edit-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    showDialog: false,
    deleteDialog: false,
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs() {
      this.set('roleOptions', ['OWNER', 'MANAGER', 'USER']);
      this.set('statusOptions', ['active', 'inactive']);
      this.set('modifiedRole', this.get('record').get('role'));
      this.set('modifiedStatus', this.get('record').get('status'));
    },
    actions: {
      editMe: function () {
        this.set('showDialog', true);
      },
      closeUserDialog: function () {
        this.set('showDialog', false);
      },
      updateUser: function () {
        let adkToast = this.get('adkToast');
        let record = this.get('record');
        record.set('status', this.get('modifiedStatus'));
        record.set('role', this.get('modifiedRole'));
        try {
          record.save();
        } catch (e) {
          adkToast.display(e.message, "error", 1000);
          throw e;
        }
        this.set('showDialog', false);
      },
      deleteMe: function () {
        let adkToast = this.get('adkToast');
        let record = this.get('record');
        try {
          record.destroyRecord();
          if (record.role == "OWNER") {
            let ownerEmail = this.store.queryRecord('email-group-owner', {
              CompanyId: record.company.get('id'),
              email: record.companyUser.get('email')
            });
            ownerEmail.then(email => {
              email.destroyRecord();
            });
          }
        } catch (e) {
          adkToast.display(e.message, "error", 1000);
          throw e;
        }
        this.set('deleteDialog', true);
      },
      closeDeleteDialog: function () {
        this.set('deleteDialog', false);
      },
      deleteUser: function () {
        this.set('deleteDialog', false);
      }
    }
  });
});