define("adtikir3v1/services/adk-session-account", ["exports", "ember-concurrency", "adtikir3v1/config/environment", "moment"], function (_exports, _emberConcurrency, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6; //import adkError from '../utils/adk-error';
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // import {
  //   A
  // } from '@ember/array';
  // import {
  //   computed
  // } from '@ember/object';
  // creator: syeo
  //
  // account session to give few info after login
  //   adkSessionAccount.lastLoginRole : the current role for the user and company
  //   adkSessionAccount.myCompany     : the current company the user work on
  //   adkSessionAccount.me            : the user info information for current login
  //   adkSessionAccount.noRole        : to check should force go create new  company upon login
  //   adkSessionAccount.term          : to check should force go term aggreement upon login
  //   adkSessionAccount.privacy       : to check should force go privacy aggreement upon login
  let AdkSessionAccountClass = _exports.default = (_dec = Ember.inject.service('keycloakSession'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('lastLoginRole'), _dec6 = Ember.computed('me.myRoles.[]'), _dec7 = Ember.computed('timeZone', 'currentTime'), _dec8 = Ember.computed('myNow'), _dec9 = Ember.computed('timeZone.gmtOffset'), _dec10 = Ember.computed('utcOffset'), _dec11 = Ember.computed('myCompanyDetail'), _dec12 = (0, _emberConcurrency.task)(function* (reloadRole = false) {
    //let adkSessionAccount = this.get('adkSessionAccount');

    let session = this.get('session');
    let me = null;
    let myCompany = null;
    let lastLoginRole = null;
    let myCompanyDetail = null;
    let timeZone = null;
    try {
      if (!session.authenticated) {
        return;
      }
      //from bareer
      if (!reloadRole) {
        session.loadUserProfile();
        let uid = session.keycloak.idTokenParsed.sub;
        if (Ember.isEmpty(uid)) {
          throw new Ember.Error('User ID is empty');
        }
        this.set('uid', uid);
        me = yield this.store.findRecord('me', uid);
      } else {
        //this is from loadRole.. so... get the me from global       
        me = this.get('me');
        //yield me.reload();
      }

      //initialize 
      myCompany = null;
      myCompanyDetail = null;
      lastLoginRole = yield me.get('lastLoginRole');
      yield this.set('lastLoginRole', lastLoginRole);
      if (lastLoginRole) {
        myCompany = yield lastLoginRole.get('company');
        //yield myCompany.reload();
      }
      yield this.set('myCompany', myCompany);

      //alert(this.get('needSetupCompany'));
      if (!this.get('needSetupCompany')) {
        myCompanyDetail = yield myCompany.get('myCompanyDetail');
        //yield myCompanyDetail.reload();
        //trigger the timeZone computed
        let timeZoneId = yield myCompanyDetail.get('timeZoneId');
        timeZone = yield this.store.findRecord('timezone', timeZoneId);
      }
      if (!reloadRole) {
        this.set('me', me);
      }
      this.set('myCompanyDetail', myCompanyDetail);
      this.set('timeZone', timeZone);
      // if (timeZone) {
      //   this.get('momentServis').setTimeZone(timeZone.zoneName);
      // }
      this.set('isLoaded', true);
      this.get('mytoday');
    } catch (e) {
      throw e;
    } //catch
  }), _dec13 = (0, _emberConcurrency.task)(function* (role) {
    let store = this.get('store');
    let me = this.get('me');
    me.set('lastLoginRole', role);
    me.set('action', "saveLastLoginRole");
    //      console.log("me last login role:", yield me.get('lastLoginRole'));
    try {
      this.set('me', yield me.save());

      //let all = this.store.peekAll();
      //console.log(all);
      // yield this.store.unloadAll('adCampaign');
      // yield this.store.unloadAll('adCampaignDetail');
      // yield this.store.unloadAll('acWorkFlow');
      // yield this.store.unloadAll('ad');

      //this unloadAll method.. suggested by the workaraound in 
      //ember data issues: #5447
      yield store.peekAll('adCampaign').map(model => model.unloadRecord());
      yield store.peekAll('adCampaignDetail').map(model => model.unloadRecord());
      yield store.peekAll('acWorkFlow').map(model => model.unloadRecord());
      yield store.peekAll('ad').map(model => model.unloadRecord());

      //yield this.store.unloadAll();
      yield this.loadLoginUserTask.perform(true);
    } catch (e) {
      throw e;
    }
  }).restartable(), (_class = class AdkSessionAccountClass extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "adkToast", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "loadLoginUserTask", _descriptor5, this);
      _initializerDefineProperty(this, "loadRoleTask", _descriptor6, this);
    }
    //  @service ('moment') momentServis;

    setCurrentTime() {
      return Ember.run.later(this, function () {
        this.set('currentTime', new Date());
        //this.runner = 
        this.setCurrentTime();
      }, 1000);
    }
    init() {
      super.init(...arguments);
      this.acceptGDPR = false;
      let thisService = this;
      let session = this.get('session');
      let options = {
        url: _environment.default.keyCloakHost,
        realm: _environment.default.Realm,
        clientId: _environment.default.ClientId
      };
      this.lang = "en-us";
      session.set('onTokenExpired', function () {
        thisService.logout();
      });
      session.installKeycloak(options);
      session.set('onLoad', 'check-sso');
      this.set('isLoaded', false);
      this.set('lastLoginRole', null);
      this.set('myCompany', null);
      this.set('myCompanyDetail', null);
      //this.set('isLoaded', false);
      this.set('changeRole', false);

      // next(this, function() {
      //   //this.runner = 
      //   this.setCurrentTime();
      // })
      this.setCurrentTime();

      //this function is for powerCalendar today
      this.addObserver('timeZone', 'currentTime', function () {
        let powerCalender;
        powerCalender = Ember.getOwner(thisService).lookup('service:power-calendar');
        if (powerCalender) {
          let d = new Date();
          let utc = d.getTime() + d.getTimezoneOffset() * 60000;
          let nd = new Date(utc + thisService.get('utcOffset') * 60000);
          powerCalender.date = (0, _moment.default)(nd);
        }
      });
    }
    logout() {
      let service = this;
      this.set('isLoaded', false);
      this.set('lastLoginRole', null);
      this.set('myCompany', null);
      this.set('myCompanyDetail', null);
      this.set('changeRole', false);
      let me = this.get('me');
      me.set('action', 'logout');
      me.save().then(function () {
        alert("You are logged out safely.\n See you soon.");
        service.store.unloadAll();
        service.get('session').logout(window.location.protocol + "//" + window.location.host);
      });
    }

    //input: dateObj in UTC format
    //output: date in company timezone, with company prefer format (string)

    getDate(dateObj = null, defaultFormat = false) {
      if (!dateObj) {
        dateObj = this.get('myToday');
      }
      let utcOffset = this.get('utcOffset');
      let dateFormat;
      if (defaultFormat) {
        dateFormat = "YYYY-MM-DD";
      } else {
        dateFormat = this.get('myCompanyDetail').get('dateFormat');
      }

      //return a date object of now Time
      return (0, _moment.default)(dateObj).utcOffset(utcOffset).format(dateFormat);
      //calcuate today date... for calendar...
    }

    //this not yet tested.. to be test
    getTime(dateObj = null, defaultFormat = false) {
      if (!dateObj) {
        dateObj = this.get('myToday');
      }
      let utcOffset = this.get('utcOffset');
      let timeFormat;
      if (defaultFormat) {
        timeFormat = "HH:mm:ss";
      } else {
        timeFormat = this.get('myCompanyDetail').get('tineFormat');
      }

      //return a date object of now Time
      return (0, _moment.default)(dateObj).utcOffset(utcOffset).format(timeFormat);
      //calcuate today date... for calendar...
    }

    //return the dateObj from gmt to the computer local timezone dateobj
    getComopanyLocalDateObj(dateObj) {
      let utcOffset = this.get('utcOffset');
      let dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
      let newDate = (0, _moment.default)(dateObj).utcOffset(utcOffset).format(dateFormat);
      newDate = (0, _moment.default)(new Date(newDate));
      return newDate;
    }
    get needSetupCompany() {
      if (this.get('lastLoginRole')) {
        return false;
      }
      return true;
    }
    get noRole() {
      let me = this.get('me');
      //let myRoles;
      if (me) {
        let myRoles = me.get('myRoles');
        if (myRoles && myRoles.length > 0) {
          // if (this.get('lastLoginRole')) {
          return false;
        }
      }
      return true;
    }
    get myNow() {
      let utcOffset = this.get('utcOffset');
      //return a date object of now Time
      //console.log(moment.utc(new Date()).utcOffset(utcOffset).format('YYYY-MM-DD HH:mm:ss ZZ'));
      //console.log(moment.utc(new Date()).utcOffset(utcOffset).toDate());
      return _moment.default.utc(new Date()).utcOffset(utcOffset);
      //calcuate today date... for calendar...
    }
    get myToday() {
      //return a now object with time set to 00:00:00 ....
      let today = (0, _moment.default)(this.get('myNow'));
      today.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      return today;
    }
    get utcOffset() {
      let gmtOffset = 0;
      if (this.get('timeZone')) {
        gmtOffset = this.get('timeZone').get('gmtOffset');
      }
      return Math.floor(gmtOffset / 60);
    } //utcOffset

    get utcOffsetStr() {
      let str = '';
      let utcOffset = this.get('utcOffset');
      str = utcOffset < 0 ? '-' : '+';
      utcOffset = utcOffset < 0 ? utcOffset * -1 : utcOffset;
      str += (Math.floor(utcOffset / 60) + '').padStart(2, '0') + ':' + (utcOffset % 60 + '').padStart(2, '0');
      return str;
    }
    get dateFormat() {
      return this.get('myCompanyDetail').get('dateFormat');
    }

    //task

    //this is to switch role

    actionAcceptGDPR() {
      this.set("acceptGDPR", true);
    }

    //     let session = this.get('session');
    //     if (!session.authenticated) {
    //       return
    //     }

    //     let uid = this.get('uid'); //set fix uid here...
    //     if (!isEmpty(uid)) {
    //       let me;

    //       me = yield this.store.findRecord('me', uid);

    //       this.set('isLoaded', true);
    //       this.set('me', me);
    //       let lastLoginRole = yield me.get('lastLoginRole');
    //       this.set('lastLoginRole', lastLoginRole);
    //       this.set('myCompany', (yield lastLoginRole.get('company')));
    //     } //if
    //   }) reloadCurrentUserTask; //loadCurrentUse
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adkToast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "needSetupCompany", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "needSetupCompany"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noRole", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "noRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "myNow", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "myNow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "myToday", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "myToday"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "utcOffset", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "utcOffset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "utcOffsetStr", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "utcOffsetStr"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateFormat", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dateFormat"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadLoginUserTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadRoleTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});