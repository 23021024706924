define("adtikir3v1/pods/components/adk-display-client-portfolio/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    pageSize: 3,
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('vendorClientPortfolioList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadVendorClientPortfolioTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    loadVendorClientPortfolioTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let vendorClientPortfolios = yield this.store.query('vendor-client-portfolio', {
        page: page,
        pageSize: pagingObj.pageSize,
        vendor: this.get('vendorId')
      });
      Ember.set(pagingObj, "page", page);
      Ember.set(pagingObj, "result", vendorClientPortfolios);
    }).restartable()
  });
});