define("adtikir3v1/pods/components/adk-dashboard-campaign-agency/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    didReceiveAttrs() {
      this.set('loadVendorRolesTaskInstance', this.get('loadVendorRolesTask').perform(this));
    },
    actions: {
      closeErr() {
        this.set('showErrorFg', false);
      },
      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },
      closeDialogWithParent() {
        this.set('showDialogWithParent', false);
        this.set('showAboutMe', false);
      },
      removePerson(record) {
        let adkToast = this.get('adkToast');
        record.set('status', "left");
        record.save().then(() => {
          record.vendorUser.then(user => {
            let emailUser = this.store.queryRecord('emailGroup', {
              AdCampaignId: this.get('adCampaign').id,
              email: user.email
            });
            emailUser.then(user => {
              user.destroyRecord();
            });
          });
          adkToast.display("User removed succesfully", "warning", 3000);
        }, () => {
          record.set('status', "accepted");
          adkToast.display("Failed to remove user", "error");
        });
      },
      addPerson(record) {
        let adkToast = this.get('adkToast');
        record.set('status', "accepted");
        record.save().then(() => {
          record.vendorUser.then(user => {
            let emailUser = this.store.createRecord('emailGroup', {
              AdCampaignId: this.get('adCampaign').id,
              email: user.email
            });
            emailUser.save();
          });
          adkToast.display("User accecpted successfully", "warning", 3000);
        }, () => {
          record.set('status', "pending");
          adkToast.display("Failed to add user", "error");
        });
      },
      rejectPerson(record) {
        let adkToast = this.get('adkToast');
        record.set('status', "rejected");
        record.save().then(() => {
          adkToast.display("User rejected successfully", "warning", 3000);
        }, () => {
          record.set('status', "pending");
          adkToast.display("Failed to reject user", "error");
        });
      },
      aboutMe(record) {
        if (record.showAboutMeFlag === true) {
          record.set('showAboutMeFlag', false);
        } else {
          record.set('showAboutMeFlag', true);
        }
      }
    }
  });
});