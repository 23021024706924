define("adtikir3v1/pods/campaign/details/vendor/agency/clientbrief/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    //adkSessionAccount: service(),

    loadAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaign = this.get('adCampaign');
      let attachments = yield this.store.query('rfp-attachment', {
        adCampaign: adCampaign.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", attachments);
      //return attachments;
    }),
    saveAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adCampaignRfpSetting = this.get('adCampaignRfpSetting');
      let attachment = this.store.createRecord('rfp-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        adCampaignRfpSetting: adCampaignRfpSetting
      });
      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        throw e;
      }
    }).drop(),
    actions: {
      saveAndCreateRfp: function () {}
    }
  });
});