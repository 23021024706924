define("adtikir3v1/models/ad-deadline", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  //import { attr } from 'ember-decorators/data';
  var _default = _exports.default = Model.extend({
    workFlowStatusNumber: attr('number'),
    deadlineDate: attr('date'),
    deadlineDescription: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    companyName: attr('string'),
    lastModifiedFirstName: attr('string'),
    lastModifiedLastName: attr('string'),
    lastModifiedRole: attr('string'),
    action: attr('string'),
    status: attr('string'),
    //the field that this deadline that in ad related to...
    //so when delete, need to clear this
    deadlineAdRelatedField: attr('string', {
      default: ''
    }),
    fixWorkflowStatus: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    ad: belongsTo('ad')
  });
});