define("adtikir3v1/models/ad-invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    invoiceNumber: attr('string'),
    description: attr('string'),
    invoiceAmount: attr('number'),
    agencyCost: attr('number'),
    mediaCost: attr('number'),
    miscCost: attr('number'),
    notes: attr('string'),
    comment: attr('string'),
    workFlowStep: attr('string'),
    invoiceDoc: attr('string'),
    dueDate: attr('date'),
    status: attr('string', {
      defaultValue: 'new'
    }),
    //new approved rejected
    accountNumber: attr('string'),
    //The one who create the invoice
    submissionDate: attr('date'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    //The one who reject / approve the invoice
    reactedByDate: attr('date'),
    reactedByFirstName: attr('string'),
    reactedByLastName: attr('string'),
    reactedByRole: attr('string'),
    CompanyId: attr('string'),
    companyName: attr('string'),
    adCampaign: belongsTo('adCampaign'),
    ad: belongsTo('ad'),
    // company                : belongsTo('company', { inverse: null} ),

    uploadedBy: Ember.computed('firstName', 'lastName', function () {
      return `${this.firstName} ${this.lastName}`;
    }),
    reactedBy: Ember.computed('reactedByFirstName', 'reactedByLastName', function () {
      return `${this.reactedByFirstName} ${this.reactedByLastName}`;
    }),
    statusLog: Ember.computed('comment', 'reactedBy', function () {
      return `${this.comment} - By: ${this.reactedBy}`;
    }),
    invoiceAmountFormat: Ember.computed('invoiceAmount', function () {
      let invoiceAmount = this.get('invoiceAmount');
      return invoiceAmount.toFixed(2);
    })
  });
});