define("adtikir3v1/pods/application/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  //import { getOwner } from '@ember/application';
  let ApplicationClass = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed("router.currentRouteName"), _dec5 = (0, _emberConcurrency.task)(function* (role) {
    let adkSessionAccount = this.get('adkSessionAccount');
    try {
      yield adkSessionAccount.loadRoleTask.perform(role);
    } catch (e) {
      throw e;
    }
    this.get('router').transitionTo('index');
  }).drop(), (_class = class ApplicationClass extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "adkSessionAccount", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _defineProperty(this, "spopen", false);
      _defineProperty(this, "langSupport", [['en-us', 'english'], ['ms-my', 'malay']]);
      _defineProperty(this, "isHomeOffice", false);
      _initializerDefineProperty(this, "updateCompanyTask", _descriptor4, this);
      _defineProperty(this, "actions", {
        changeLanguage(lang) {
          Ember.set(this.adkSessionAccount, "lang", lang);
          this.intl.setLocale([lang[0]]);
        },
        openTerms() {},
        logout() {
          this.get('adkSessionAccount').logout();
          //this.transitionToRoute('/');
          //return false;
        },
        nowTime() {
          alert('the current local time is ' + this.get('adkSessionAccount').get('myNow').toString());
        },
        actionAcceptGDPR() {
          this.get('adkSessionAccount').actionAcceptGDPR();
        }
      });
    } //use in template for currentpath
    get currentRouteName() {
      let router = this.get("router");
      let currentRouteName = router.get("currentRouteName");
      currentRouteName = currentRouteName.split('.')[0];
      return currentRouteName;
    }
    init() {
      super.init(...arguments);
      Ember.set(this.adkSessionAccount, "lang", this.langSupport[0]);
      this.intl.setLocale([this.langSupport[0][0]]);
      Ember.set(this, "isHomeOffice", false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteName"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updateCompanyTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});