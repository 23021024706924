define("adtikir3v1/models/budget-ultimate-comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    userRoleId: attr('string'),
    role: attr('string'),
    comment: attr('string'),
    // budgetRequestUpdate:  attr('number'),
    adCampaignId: attr("string"),
    adId: attr("string"),
    createdAt: attr('date'),
    oldValue: attr('number'),
    newValue: attr('number'),
    type: attr('string'),
    adCampaign: belongsTo('adCampaign'),
    oldExchangeRateDate: attr('string'),
    newExchangeRateDate: attr('string'),
    exchangeRateType: attr('string')
  });
});