define("adtikir3v1/pods/components/adk-ad-media-proposal-item-editor/component", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    isNewFlag: true,
    selectedTab: "mediainfo",
    init: function () {
      this._super(...arguments);
      this.set('tabObject', ["mediainfo", "dateNcosting", "noteNpromises", "vendorMediaDetail"]);
    },
    didReceiveAttrs() {
      this.set('adMediaProposalItemLists', {
        page: 0,
        pageSize: 10,
        action: this.get("loadAdMediaProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      let vAdMediaProposal = this.get('vAdMediaProposal');
      this.set('adRfp', vAdMediaProposal.get('adRfp'));
      this.set('adMediaRfpBody', vAdMediaProposal.get('adMediaRfpBody'));
      // let adRfp = vAdMediaProposal.get('adRfp');
      // this.set('campaignStartDate', adRfp.get('campaignStartDate'));
      // this.set('campaignEndDate', adRfp.get('campaignEndDate'));
      this.set('openConfirmationDialogBox', false);
    },
    didInsertElement() {
      let adMediaProposalCostItemEditor = document.getElementById("adMediaProposalCostItemEditor");
      adMediaProposalCostItemEditor.style.display = "none";
    },
    saveFormActionTask: (0, _emberConcurrency.task)(function* (toClose = false) {
      let adMediaProposalItem = this.get('adMediaProposalItem');
      Ember.set(adMediaProposalItem, 'mediaType', this.get('selectedMediaType'));
      Ember.set(adMediaProposalItem, 'vendorMediaDetail', this.get('selectedVendorMediaDetail'));
      yield adMediaProposalItem.save();
      this.adkToast.display("Record save", "warning", 2000);
      let vAdMediaProposal = this.get('vAdMediaProposal');
      yield vAdMediaProposal.reload();
      Ember.set(this.get('adMediaProposalItemLists'), 'refresh', true);
      if (toClose) {
        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }
        this.resetForm();
      }
    }),
    checkMediaInfo: function () {
      let adkToast = this.get('adkToast');
      let adMediaProposalItem = this.get('adMediaProposalItem');
      if (!adMediaProposalItem.mediaName || adMediaProposalItem.mediaName.trim().length === 0) {
        adkToast.display("Media name must be filled", "error", 1500);
        return false;
      }
      let mediaType = this.get('selectedMediaType');
      if (!mediaType) {
        adkToast.display("Media type must be selected", "error", 1500);
        return false;
      }
      return true;
    },
    checkDateNcosting: function () {
      let adkToast = this.get('adkToast');
      let adMediaProposalItem = this.get('adMediaProposalItem');
      if (!adMediaProposalItem.cost || adMediaProposalItem.cost.trim && adMediaProposalItem.cost.trim().length === 0) {
        adkToast.display("Media cost must be filled", "error", 1500);
        return false;
      }
      let re = new RegExp('(:?^|\\s)(?=.)((?:0|(?:[1-9](?:\\d*|\\d{0,2}(?:,\\d{3})*)))?(?:\\.\\d*[0-9])?)(?!\\S)');
      if (!re.test(adMediaProposalItem.cost)) {
        adkToast.display("Media cost should be a number", "error", 1500);
        return false;
      }
      return true;
    },
    checkFields: function (tab) {
      if (tab === 'mediainfo') {
        return this.checkMediaInfo();
      } else if (tab === "dateNcosting") {
        return this.checkDateNcosting();
      }
      return true;
    },
    delayAWhileActive(mediaProposalItemId) {
      let parentDiv = document.getElementById('div-' + mediaProposalItemId);
      let adMediaProposalCostItemEditor = document.getElementById("adMediaProposalCostItemEditor");

      //let parentDiv = divParent.previousElementSibling;

      parentDiv.classList.add('adkListCardActive');
      this.set('activeDiv', parentDiv);
      parentDiv.parentElement.insertBefore(adMediaProposalCostItemEditor, parentDiv.nextElementSibling);
      adMediaProposalCostItemEditor.style.display = "block";
      adMediaProposalCostItemEditor.scrollIntoView();
    },
    resetForm() {
      //clear form variable
      this.set('selectedMediaType', null);
      this.set('adStartDate', this.adkSessionAccount.get('myNow'));
      this.set('adStartDateCenter', this.adkSessionAccount.get('myNow'));
      this.set('adEndDate', this.adkSessionAccount.get('myNow'));
      this.set('adEndDateCenter', this.adkSessionAccount.get('myNow'));
      //reset the tab
      this.set('selectedTab', this.tabObject[0]);

      //clear flag
      this.set('isNewFlag', false);
      this.set('editorIsOpen', false);
      this.set('activeDiv', null);
      this.set('selectedVendorMediaDetail', null);

      //move div and hide
      let adMediaProposalCostItemEditor = document.getElementById("adMediaProposalCostItemEditor");
      adMediaProposalCostItemEditor.style.display = "none";
      this.get('element').appendChild(adMediaProposalCostItemEditor);
    },
    //reset form, move div to end

    deleteMediaProposalItemTask: (0, _emberConcurrency.task)(function* () {
      let adMediaProposalItem = this.get('adMediaProposalItem');
      let adkToast = this.get('adkToast');
      try {
        //move here... b4 the html code get deleted....
        let el = this.get('dialogOrigin').parentElement.parentElement.parentElement;
        yield adMediaProposalItem.destroyRecord();
        this.set('openConfirmationDialogBox', false);
        adkToast.display("Media deleted successfully");
        let vAdMediaProposal = this.get('vAdMediaProposal');
        yield vAdMediaProposal.reload();
        el.scrollIntoView();
        Ember.set(this.get('adMediaProposalItemLists'), 'refresh', true);
        this.set('adMediaProposalItem', null);
      } catch (e) {
        adkToast.display("Failed to delete media", "error");
      }
    }),
    //deleteMediaProposalItemTask

    checkActive() {
      if (this.editorIsOpen) {
        alert('Editor form already open, close it first');
        let adMediaProposalCostItemEditor = document.getElementById("adMediaProposalCostItemEditor");
        adMediaProposalCostItemEditor.scrollIntoView();
        return false;
      }
      return true;
    },
    actions: {
      deleteMediaProposalItem: function (adMediaProposalItem, event) {
        event.preventDefault();
        if (!this.checkActive()) {
          return false;
        }
        this.set('dialogOrigin', event.currentTarget);
        this.set('openConfirmationDialogBox', true);
        this.set('adMediaProposalItem', adMediaProposalItem);
      },
      cancelDeleteVendorMedia: function () {
        this.set('openConfirmationDialogBox', false);
      },
      updateMediaProposalItem: function (adMediaProposalItem, event) {
        event.preventDefault();
        let thisComponent = this;
        if (!this.checkActive()) {
          return false;
        }
        this.set('adMediaProposalItem', adMediaProposalItem);
        adMediaProposalItem.get('mediaType').then(function (mediaType) {
          thisComponent.set('selectedMediaType', mediaType);
        });
        this.set('adStartDate', adMediaProposalItem.adStartDate);
        this.set('adStartDateCenter', adMediaProposalItem.adStartDate);
        this.set('adEndDate', adMediaProposalItem.adEndDate);
        this.set('adEndtDateCenter', adMediaProposalItem.adEndDate);
        adMediaProposalItem.get('vendorMediaDetail').then(function (item) {
          thisComponent.set('selectedVendorMediaDetail', item);
        });
        this.set('isNewFlag', false);
        this.set('editorIsOpen', true);

        //something wrong with the ember component..
        //i need to use a later to activate the editor later...
        //maybe ember latest version dont have this bug 
        Ember.run.later(this, function () {
          thisComponent.delayAWhileActive(adMediaProposalItem.id);
        }, 250);

        // let i=0;
        // let el=parentDiv;
        // while (el) {
        //   console.log(i);
        //   console.dir(el);
        //   el = el.nextSibling;
        //   i++;
        // }
      },
      addNewAdMediaProposalItem: function (event) {
        if (!this.checkActive()) {
          return false;
        }
        let adMediaProposalItem = this.store.createRecord('ad-media-proposal-item', {
          vAdMediaProposal: this.get('vAdMediaProposal'),
          aAdMediaProposal: null,
          mediaType: null,
          vendorMedialDetail: null
        });
        this.set('adMediaProposalItem', adMediaProposalItem);
        this.set('selectedMediaType', null);
        this.set('selectedVendorMediaDetail', null);

        //let el=this.get('element');
        let myNow = this.adkSessionAccount.get('myNow');
        this.set('adStartDate', myNow);
        this.set('adStartDateCenter', myNow);
        this.set('adEndDate', myNow);
        this.set('adEndDateCenter', myNow);
        let newEndDateStr = (0, _moment.default)(myNow).format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
        adMediaProposalItem.set('adStartDate', new Date(newEndDateStr));
        adMediaProposalItem.set('adEndDate', new Date(newEndDateStr));
        let adMediaProposalCostItemEditor = document.getElementById("adMediaProposalCostItemEditor");
        let buttonDiv = event.currentTarget.parentElement;
        buttonDiv.parentElement.insertBefore(adMediaProposalCostItemEditor, buttonDiv.nextElementSibling);
        adMediaProposalCostItemEditor.style.display = "block";
        adMediaProposalCostItemEditor.scrollIntoView();
        this.set('activeDiv', buttonDiv);
        this.set('isNewFlag', true);
        this.set('editorIsOpen', true);
      },
      unsetSelectedVendorMediaDetail() {
        this.set('selectedVendorMediaDetail', null);
      },
      cancelEdit() {
        let adMediaProposalItem = this.get('adMediaProposalItem');
        if (!(this.get('isNew') && adMediaProposalItem.hasDirtyAttributes)) {
          adMediaProposalItem.rollbackAttributes();
        }
        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }
        this.resetForm();
        this.element.scrollIntoView();
        //cancel edit here
      },
      updateDate(field, selected) {
        let adkToast = this.get('adkToast');
        let newDate = (0, _moment.default)(selected.date);
        //let adRfp = this.get('adRfp');
        //let startDate = moment(adRfp.get('adPlayStartDate')); //assume is a moment obj
        //let endDate = moment(adRfp.get('adPlayEndDate')); //assume is a moment 
        let adMediaProposalItem = this.get('adMediaProposalItem');
        //check newCenterDate > endDate
        //compare with endDate...
        // if (adkDateTime.compareDate(newDate, endDate) > 0) {
        //   newDate = '';
        //   adkToast.display("Date should not be later than the end date, it will not be set", "error", 1500);
        //   return false;
        // }

        //cannot put else.. cause productLaunchDate need check both

        //compare with startDate
        // if (adkDateTime.compareDate(newDate, startDate) < 0) {
        //   newDate = '';
        //   adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 1500);
        //   return false;
        // }

        if (field === "adEndDate") {
          let adStartDate = this.get('adStartDate');
          if (_adkDateTime.default.compareDate(newDate, adStartDate) <= 0) {
            newDate = '';
            adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 1500);
            return false;
          }
        } else {
          let adEndDate = this.get('adEndDate');
          if (_adkDateTime.default.compareDate(newDate, adEndDate) >= 0) {
            this.set('adEndDate', newDate);
            this.set('adEndDateCenter', newDate);
            let newEndDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
            adMediaProposalItem.set('adEndDate', new Date(newEndDateStr));
            // newDate = '';
            // adkToast.display("Date should not be later than the end date, it will not be set", "error", 1500);
            //return false;
          }
        }
        if (newDate) {
          this.set(field, newDate);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          adMediaProposalItem.set(field, new Date(newDateStr));
        }
      },
      // updateDate()

      checkCenter(field, newCenterDate) {
        newCenterDate = (0, _moment.default)(newCenterDate.date);

        //let adRfp = this.get('adRfp');
        //let startDate = moment(adRfp.get('adPlayStartDate')); //assume is a moment obj
        //let endDate = moment(adRfp.get('adPlayEndDate')); //assume is a moment 

        // let productLaunchDate = this.get('productLaunchDate');

        //check newCenterDate > endDate
        //compare with endDate...

        // if (adkDateTime.compareYearMonth(newCenterDate, endDate) >= 0) {
        //   newCenterDate = endDate;
        // }
        //compare with startDate

        // if (adkDateTime.compareYearMonth(newCenterDate, startDate) <= 0) {
        //   newCenterDate = startDate;
        // }

        this.set(field, newCenterDate);
      },
      //checkCenter()    

      // setMediaTypeAction(mediaType) {
      //   let adMediaProposalItem = this.get('adMediaProposalItem');
      //   adMediaProposalItem.set('mediaType', mediaType);
      // }

      backAction: function (tab) {
        if (!this.checkFields(tab)) {
          return false;
        }
        let idx = this.tabObject.indexOf(tab);
        if (idx != 0) {
          this.set('selectedTab', this.tabObject[idx - 1]);
        }
      },
      nextAction: function (tab) {
        if (!this.checkFields(tab)) {
          return false;
        }
        let idx = this.tabObject.indexOf(tab);
        if (idx < this.tabObject.length - 1) {
          this.set('selectedTab', this.tabObject[idx + 1]);
        }
      },
      changeTabAction: function (currentTab, moveToTab) {
        if (!this.checkFields(currentTab)) {
          return false;
        }
        this.set('selectedTab', moveToTab);
      }
    }
  });
});