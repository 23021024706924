define("adtikir3v1/pods/components/adk-delete-row/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import layout from './template';
  var _default = _exports.default = Ember.Component.extend({
    layout: _component.layout,
    record: null,
    click(event) {
      if (this.onClick) {
        this.onClick(this.record);
        event.stopPropagation();
      }
    }
  });
});