define("adtikir3v1/services/adk-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { getOwner } from '@ember/application';
  var _default = _exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    display: function (text, type = "warning", duration = 3000) {
      let toaster = this.get('paperToaster');
      let typeClass;
      if (type == "warning") {
        typeClass = "md-accent";
      } else if (type == "error") {
        typeClass = "md-warn";
      } else if (type == "systemError") {
        this.get('router').transitionTo('error');
      } else {
        typeClass = "md-primary";
      } //if

      console.dir(text);
      toaster.show(text, {
        position: 'bottom right',
        duration: duration,
        toastClass: typeClass,
        capsule: true
      });
    }
  });
});