define("adtikir3v1/pods/components/adk-campaign-activities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "f7DSqoF7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex adkcardTable\"],[8],[0,\"\\n    \"],[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@showGlobalFilter\",\"@useFilteringByColumns\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@columnComponents\"],[[22,\"activities\"],[22,\"activity_col\"],[23,0,[\"showGlobalFilter\"]],[23,0,[\"useFilteringByColumns\"]],[23,0,[\"showComponentFooter\"]],[23,0,[\"showColumnsDropdown\"]],[28,\"hash\",null,[[\"date-display\"],[[28,\"component\",[\"adk-models-table-display-date\"],null]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-campaign-activities/template.hbs"
    }
  });
});