define("adtikir3v1/models/ad-activity", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
    // belongsTo,
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    username: attr('string'),
    status: attr('string'),
    AdId: attr('string'),
    AdCampaignId: attr('string'),
    description: attr('string'),
    createdAt: attr('date'),
    displayActivityDate: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('activityDate')).format('lll');
    })
  });
});