define("adtikir3v1/models/data-research", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    dataTitle: attr('string'),
    dataCategory: attr('string'),
    dataCost: attr('string'),
    dataLanguage: attr('string'),
    dataTerritory: attr('string'),
    dataDesc: attr('string'),
    dataColSpan: attr('string'),
    dataRowSpan: attr('string'),
    dataURL: attr('string')
  });
});