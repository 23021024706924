define("adtikir3v1/pods/campaign/details/ads/ad/reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4p/+jRbL",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"adkAdCardTitle flex layout-row layout-align-space-between-center\"],[8],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `card.title` to be a contextual component but found a string. Did you mean `(component card.title)`? ('adtikir3v1/pods/campaign/details/ads/ad/reports/template.hbs' @ L4:C7) \"],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"text\"]],\"expected `title.text` to be a contextual component but found a string. Did you mean `(component title.text)`? ('adtikir3v1/pods/campaign/details/ads/ad/reports/template.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,3,[\"headline\"]],\"expected `text.headline` to be a contextual component but found a string. Did you mean `(component text.headline)`? ('adtikir3v1/pods/campaign/details/ads/ad/reports/template.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"Ad Report\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('adtikir3v1/pods/campaign/details/ads/ad/reports/template.hbs' @ L12:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[5,\"adk-ad-report\",[],[[\"@ad\",\"@acWorkFlows\"],[[22,\"ad\"],[22,\"acWorkFlows\"]]],{\"statements\":[],\"parameters\":[]}],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/ads/ad/reports/template.hbs"
    }
  });
});