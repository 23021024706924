define("adtikir3v1/pods/components/adk-agency-media-project-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8uK6rKZd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-column\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row card-newline\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"card-desc\"],[8],[0,\"\\n            Project Name: \\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n            \"],[5,\"paper-input\",[],[[\"@value\",\"@onChange\",\"@required\",\"@label\"],[[24,[\"mediaDetail\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"mediaDetail\",\"name\"]]],null]],null],true,\"Project name\"]]],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"adk-media-type-selection\",[],[[\"@selectedMediaType\",\"@mediaTypes\"],[[22,\"selectedMediaType\"],[22,\"mediaTypes\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flex adkFigure\"],[8],[0,\"\\n    \"],[5,\"paper-input\",[],[[\"@class\",\"@textarea\",\"@value\",\"@block\",\"@passThru\",\"@onChange\",\"@label\",\"@maxlength\",\"@errorMessages\"],[\"flex\",true,[24,[\"mediaDetail\",\"mediaFormatDetails\"]],true,[28,\"hash\",null,[[\"rows\"],[5]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"mediaDetail\",\"mediaFormatDetails\"]]],null]],null],[28,\"concat\",[[28,\"capitalize\",[[28,\"t\",[\"media\"],null]],null],\" Format Details, Instructions, Size\"],null],1000,[28,\"hash\",null,[[\"maxlength\"],[\"Max length exceeded.\"]]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-agency-media-project-info/template.hbs"
    }
  });
});