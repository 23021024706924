define("adtikir3v1/helpers/is-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function isActive([routeName, activeRoute]) {
    if (activeRoute === routeName) {
      return true;
    }
    routeName += ".";
    return !activeRoute.indexOf(routeName);
  });
});