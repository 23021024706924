define("adtikir3v1/pods/components/adk-model-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super();
      this.setProperties({
        showGlobalFilter: false,
        useFilteringByColumns: false,
        showComponentFooter: false,
        showColumnsDropdown: false,
        editRow: true
      });
      // this.set('deleteRow',false);
      // this.set('editRow',false);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        data: this.data,
        columns: this.columns
      });

      // if (this.selectToggle) {
      //   if (this.columns[0].component != 'adk-models-table-checkbox') {
      //     this.columns.splice(0, 0, {
      //       component: 'adk-models-table-checkbox',
      //       useFilter: false,
      //       editable: false,
      //       mayBeHidden: false
      //     });
      //   }
      // }

      if (this.editRow) {
        if (this.columns.slice(-1)[0].component != 'edit-row') {
          this.columns.splice(this.columns.length, 0, {
            "title": "Edit",
            "component": "edit-row",
            "editable": false
          });
        }
      }
      if (this.deleteRow) {
        if (this.columns.slice(-1)[0].component != 'delete-row') {
          this.columns.splice(this.columns.length, 0, {
            "title": "Delete",
            "component": "delete-row",
            "editable": false
          });
        }
      }
      if (this.showGlobalFilter) {
        this.set('showGlobalFilter', this.showGlobalFilter);
      }
      if (this.useFilteringByColumns) {
        this.set('useFilteringByColumns', this.useFilteringByColumns);
      }
      if (this.showComponentFooter) {
        this.set('showComponentFooter', this.showComponentFooter);
      }
      if (this.showColumnsDropdown) {
        this.set('showColumnsDropdown', this.showColumnsDropdown);
      }
    },
    actions: {
      onSaveRow({
        record
      }) {
        this.onSaveRow(record);
        return true;
        // return param.record.save();
      },
      onCancelRow({
        // record
      }) {
        // record.rollback();
        return true;
      },
      onColorChanged(record) {
        this.onColorChanged(record);
      },
      deleteRow(record) {
        console.log(record);
      }
    }
  });
});