define("adtikir3v1/pods/campaign/details/ads/ad/dashboard/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { moment } from 'moment';
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    refreshGlobal: 0,
    loadDeadlinesTask: (0, _emberConcurrency.task)(function* (adId, page = 0, pageSize = 10) {
      let result = yield this.store.query('ad-deadline', {
        ad: adId,
        page: page,
        pageSize: pageSize
      }); //this.store

      let total = 0;
      if (result.meta.page) {
        total = result.meta.page.total;
      }
      return {
        adDeadlines: result,
        total: total
      };
    }).restartable(),
    actions: {
      updateRefreshGlobal: function () {
        this.set('refreshGlobal', new Date().getTime());
      },
      updateAd: function (action, saveToDb, ad, fieldName) {
        let adkToast = this.get('adkToast');
        if (!saveToDb) {
          return;
        }
        if (!ad) {
          ad = this.get('ad');
        }

        // let ad = this.get('ad');
        let controller = this;

        //save the action here
        ad.set('action', action);
        if (fieldName) {
          ad.set(fieldName, ad.get(fieldName));
        }
        return ad.save().then(function () {
          adkToast.display("Ad Saved Successfully", "warning");
          //this.transitionToRoute('campaign.details.dashboard');
        }, function () {
          if (controller.ad.get('hasDirtyAttributes')) {
            controller.ad.rollbackAttributes();
          }
          adkToast.display("Ad Save Failed", "error");
        });
      },
      //updateAd

      // updateAdDashboardInfo: function (action){
      //     let ad = this.get('ad');
      //     let controller = this;

      //     ad.action = action;
      //     ad.save().then(function(){
      //         let adkToast = this.get('adkToast');
      //         adkToast.display("Info Saved Sucess", "warning");
      //     },
      //     function(){
      //         let adkToast = this.get('adkToast');
      //         if(controller.ad.get('hasDirtyAttributes')){
      //             controller.ad.rollbackAttributes();
      //         }
      //         adkToast.display("Info Save Failed", "error");
      //     })
      // },

      // updateAdImpression: function (componentValues) {
      //     this.ad.setProperties (componentValues);
      //     this.ad.save().then(() => {
      //         let adkToast = this.get('adkToast');
      //         adkToast.display("Data Saved Success", "warning");
      //     }, () => {
      //         let adkToast = this.get('adkToast');
      //         adkToast.display("Ad Save Failed", "error");
      //     })
      // },

      // updateDashboardDetail: function (componentValues){
      //     this.ad.setProperties(componentValues);
      //     this.ad.save().then(() => {
      //         let adkToast = this.get('adkToast');
      //         adkToast.display("Data Saved Success", "warning");
      //     }, () => {
      //         let adkToast = this.get('adkToast');
      //         adkToast.display("Ad Save Failed", "error");
      //     });
      // },

      saveDeadlinesAction: function (theDeadline) {
        return theDeadline.save();
      },
      //deadlinesAction

      loadDeadlinesAction: function (adId, pageNo, pageSize) {
        return this.loadDeadlinesTask.perform(adId, pageNo, pageSize);
      },
      cancelModification: function () {
        if (this.ad.get('hasDirtyAttributes')) {
          this.ad.rollbackAttributes();
        }
      }
    } //actions
  });
});