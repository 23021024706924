define("adtikir3v1/pods/campaign/details/ads/ad/vendors/agency/rfp/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from 'moment';
  // import adkDateTime from 'adtikir3v1/utils/adk-date-time';
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    sentPanelFlag: true,
    //listRefresh: false,

    init() {
      this._super(...arguments);
    },
    //init

    loadAdAgencyRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfpId = this.get('adRfp').id;
      let adAgencyRfpBodies = yield this.store.query('ad-agency-rfp-body', {
        adRfp: adRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adAgencyRfpBodies);
      //return attachments;
    }),
    //loadAdAgencyRfpBodyListTask

    loadAdAgencyProposalRequestListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let adCampaignProposalRequestListObj = this.get('adCampaignProposalRequestList');

      let result = yield this.store.query('ad-agency-proposal-request-list', {
        adAgencyRfpBody: this.adAgencyRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadAdAgencyProposalInviteeTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeObj = this.get('aProposalInvitee');
      let result = yield this.store.query('ad-agency-proposal-invitee', {
        adAgencyProposalRequestList: this.get('adAgencyProposalRequestList').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadAdAgencyProposalInviteeByCompanyTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeByCompanyObj = this.get('aProposalInviteeByCompany');    
      let result = yield this.store.query('ad-agency-proposal-invitee', {
        adAgencyRfpBody: this.get('adAgencyRfpBody').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadNewAdAgencyInviteeListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let newAdAgencyInviteeList = yield this.get('newAdAgencyInviteeList');
      Ember.set(pagingObj, 'result', newAdAgencyInviteeList);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    newAdAgencyInviteeListSearch(vendorCompanyId) {
      let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
      return newAdAgencyInviteeList.findIndex(obj => {
        if (obj.id === vendorCompanyId) {
          return true;
        }
      });
    },
    //** to check
    saveNewAdAgencyInviteeListTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let adkToast = this.get('adkToast');
      let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
      let adAgencyRfpBody = this.get('adAgencyRfpBody');
      let adAgencyProposalRequestList = store.createRecord('adAgencyProposalRequestList', {
        countOfList: 0,
        adAgencyRfpBody: adAgencyRfpBody
      });
      try {
        yield adAgencyProposalRequestList.save();
      } catch (e) {
        //if this fail... exit
        adkToast.display("Cannot create proposal request list", "error", 3000);
        this.set("inviteeList", []);
        this.set('showDialogCheckout', false);
        console.error(e);
        return false;
      }
      for (let i = newAdAgencyInviteeList.length - 1; i >= 0; i--) {
        let invitee = store.createRecord('adAgencyProposalInvitee', {
          isPrefer: newAdAgencyInviteeList[i].isPrefer,
          company: newAdAgencyInviteeList[i].vendor,
          adAgencyProposalRequestList: adAgencyProposalRequestList
        });
        //put try here... if error occur.. staill can move to next invitee
        try {
          yield invitee.save();
          newAdAgencyInviteeList.splice(i, 1); //by end.. the inviteeList should be empty
        } catch (e) {
          adkToast.display("Invitee " + invitee.vendor.companyName + " cannot be saved", "error", 2000);
          console.error(e);
        }
        //adkToast display tq note..     
      } //for

      if (newAdAgencyInviteeList.length != 0) {
        adkToast.display("That is " + newAdAgencyInviteeList.length + " invitee(s) cannot be saved", "error", 3000);
      }

      //clear everything.. 
      this.set('adAgencyInviteeList', []);
      this.set('showDialogCheckout', false);
      //reload requestList
      //set(this, "listRefresh", true);
      Ember.set(this.adAgencyProposalRequestList, "refresh", true);
      Ember.set(this.adAgencyProposalInviteeByCompany, "refresh", true);
      //done

      //first..
    }),
    //saveMediaInviteeListTask

    cleanUp: function () {
      this.set('adAgencyRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdAgencyRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('adAgencyRfpBody', null);
      this.set('firstTime', false);
      this.set('showDialogInvitee', false);
      this.set('adAgencyRespondList', null);
      this.set('adAgencyInvitee', {});
      this.set('newAdAgencyInviteeList', []);
      this.set('newAdAgencyInviteeListPageObj', null);
    },
    //actions section start

    actions: {
      openDialogInvitee(adAgencyRespondList) {
        this.set('showDialogInvitee', true);
        this.set('adAgencyRespondList', adAgencyRespondList);
        this.set('adAgencyProposalInvitees', {
          page: 0,
          pageSize: 10,
          result: null,
          action: this.get('loadAdAgencyProposalInviteeTask'),
          refresh: false,
          type: "emberdata"
        });
      },
      closeDialogInvitee() {
        this.set('showDialogInvitee', false);
        this.set('respondList', null);
        this.set('adAgencyProposalInvitee', {});
      },
      openDialogCheckout() {
        let adkToast = this.get('adkToast');
        let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
        if (newAdAgencyInviteeList.length == 0) {
          //alert("Please select some companies first");
          adkToast.display('Please select a vendor first', "error", 2000);
          return false;
        }
        if (!this.get('newAdAgencyInviteeListPageObj')) {
          alert;
          this.set('newAdAgencyInviteeListPageObj', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadNewAdAgencyInviteeListTask"),
            refresh: false,
            type: "array"
          });
        }
        this.set('showDialogCheckout', true);
      },
      closeDialogCheckout() {
        this.set('showDialogCheckout', false);
      },
      setAdAgencyRfpBody: function (adAgencyRfpBody) {
        //this is clear the new invitee list 
        let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
        if (newAdAgencyInviteeList && newAdAgencyInviteeList.length > 0) {
          let answer = confirm("You have selection in the list, do you want to continue? it will clear the list");
          if (!answer) {
            return;
          }
        }
        //reset createInviteeList to null
        this.set('newAdAgencyInviteeList', []);
        this.set('adAgencyRfpBody', adAgencyRfpBody);
        this.set('adAgencyProposalRequestList', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAdAgencyProposalRequestListTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('adAgencyProposalInviteeByCompany', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAdAgencyProposalInviteeByCompanyTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('byBatch', true);
      },
      //setAdAgencyRfpBody

      toggleByBatch() {
        this.set("byBatch", !this.get('byBatch'));
        if (this.firstTime) {
          this.set('firstTime', false);
        }
      },
      addList(isPrefer, company) {
        let adkToast = this.get('adkToast');
        let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
        if (this.newAdAgencyInviteeListSearch(company.id) == -1) {
          newAdAgencyInviteeList.pushObject({
            id: company.id,
            isPrefer: isPrefer,
            vendor: company
          });
          adkToast.display("Vendor " + company.companyName + " added to the list", "warning", 2000);
        } else {
          adkToast.display("Vendor " + company.companyName + " already in the list", "error", 2000);
        }

        //if newAdAgencyInviteeListPAgeObj.. not exist.. create new
        // if (!this.get('newAdAgnecyInviteeListPageObj')) {
        //   this.set('newAdAgnecyInviteeListPageObj', {
        //     page: 0,
        //     pageSize: 5,
        //     result: null,
        //     action: this.get("loadNewAdAgencyInviteeListTask"),
        //     refresh: false,
        //     type: "array"
        //   });
        // }
      },
      //addList

      removeList(idx) {
        let newAdAgencyInviteeList = this.get('newAdAgencyInviteeList');
        //let index = this.inviteeListSearch(vendor.id);
        //if (index > -1) {
        this.set('newAdAgencyInviteeList', newAdAgencyInviteeList.slice(0, idx).concat(newAdAgencyInviteeList.slice(idx + 1, newAdAgencyInviteeList.length)));

        //let createInviteeList = this.get("createInviteeList");
        Ember.set(newAdAgencyInviteeList, "refresh", true);
        if (newAdAgencyInviteeList.length == 1) {
          this.set('showDialogCheckout', false);
        }
      } //removeList    

      // clickRefresh() {
      //   //this.listRefresh=true;
      //   set(this, 'listRefresh', true);
      // }
    }
  });
});