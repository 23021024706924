define("adtikir3v1/pods/campaign/new/controller", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdCampaignNewClass = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class AdCampaignNewClass extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "adkSessionAccount", _descriptor, this);
      _initializerDefineProperty(this, "adkToast", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _defineProperty(this, "selectedGraphic", '');
      _defineProperty(this, "actions", {
        // Soon - This function is called from component - adk-imageselector to set the selected graphicImage, index
        // selectCampaignImage: function (campaignImage) {
        //     this.set('selectedGraphic', campaignImage.campaignImageURL);
        // },

        // setMoreDetail () {
        //   this.set('moreDetail',true);
        // },

        saveNewCampaign(componentValues) {
          // let controller = this;
          let adkToast = this.get('adkToast');
          let router = this.get('router');
          let sanitizeName = (0, _sanitizeHtml.default)(componentValues.campaignName);
          let sanitizeNameLocal = (0, _sanitizeHtml.default)(componentValues.campaignNameLocal);
          let sanitizeClientRefId = (0, _sanitizeHtml.default)(componentValues.clientRefId);
          let newCampaign = this.store.createRecord('adCampaign', {
            campaignName: sanitizeName,
            campaignNameLocal: sanitizeNameLocal,
            clientRefId: sanitizeClientRefId,
            campaignPic: componentValues.campaignPic,
            campaignStartDate: componentValues.campaignStartDate,
            campaignEndDate: componentValues.campaignEndDate,
            campaignProductLaunchDate: componentValues.campaignProductLaunchDate
          });
          newCampaign.save().then(() => {
            adkToast.display(Ember.String.capitalize(this.intl.t("campaign")) + " Save Success", "secondary");
            router.transitionTo('campaign');
          }, () => {
            adkToast.display(Ember.String.capitalize(this.intl.t("campaign")) + " Save Failed", "error");
          });
        },
        //end of saveNewCampaign

        cancelNewCampaign() {
          this.get('router').transitionTo('campaign');
        }
      });
    }
    init() {
      super.init(...arguments);
    }

    //action
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkToast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});