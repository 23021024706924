define("adtikir3v1/pods/components/menu-item-tbd/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hfeLh6Em",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-item\",null,[[\"onClick\",\"href\",\"class\"],[[24,[\"onClick\"]],[24,[\"href\"]],[28,\"concat\",[\"menu-item\",[28,\"if\",[[24,[\"active\"]],\"-active\"],null],[28,\"if\",[[24,[\"expanded\"]],\"-expanded\"],null]],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"canExpand\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"expand-more\"],[[\"class\"],[\"expand-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/menu-item-tbd/template.hbs"
    }
  });
});