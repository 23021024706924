define("adtikir3v1/pods/campaign/details/ads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lW+PsYr+",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"adkFabAdBtnContainer flex\"],[8],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"raised\",\"fab\",\"mini\",\"href\"],[\"adkAdFabBtn\",true,true,true,[28,\"href-to\",[\"campaign.details.ads.adnew\"],null]]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"paper-icon\",[\"add\"],null],false],[0,\"\\n\\t\\t\"],[4,\"paper-tooltip\",null,[[\"position\"],[\"top\"]],{\"statements\":[[0,\"Add New \"],[1,[28,\"capitalize\",[[28,\"t\",[\"ad\"],null]],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/ads/template.hbs"
    }
  });
});