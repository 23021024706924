define("adtikir3v1/services/adk-uppy-uploader", ["exports", "@uppy/core", "@uppy/dashboard", "@uppy/tus"], function (_exports, _core, _dashboard, _tus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import config from '../config/environment';
  var _default = _exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      this.setProperties({
        uppy: null
      });
      this.clearId();
    },
    //  * Convert a base64 string in a Blob according to the data and contentType.
    //  * @param b64Data {String} Pure base64 string without contentType
    //  * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
    //  * @param sliceSize {Int} SliceSize to process the byteCharacters
    //  * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    //  * @return Blob

    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, {
        type: contentType
      });
      return blob;
    },
    setUploader(configuration, dashboardConfig, tusConfig) {
      this.set('uppy', (0, _core.default)(configuration).use(_dashboard.default, dashboardConfig).use(_tus.default, tusConfig));
      this.uppy.on('upload-error', error => {
        //console.log(error);
        throw error;
      });
      this.uppy.on('file-added', file => {
        if (file.type.includes('video')) {
          this.uppy.setOptions({
            restrictions: {
              maxNumberOfFiles: 2
            }
          });

          //* visit https://github.com/SCRMHub/clientside-video-thumbnails for more information
          const thumbnailCount = 1;
          const thumbnails = new window.VideoThumbnails({
            count: thumbnailCount,
            maxWidth: 1280,
            maxHeight: 1280
          });
          let uppy = this.uppy;
          let b64toBlob = this.get('b64toBlob');
          thumbnails.on('capture', function (image) {
            uppy.adkImage = image;
            let block = image.split(";");
            let contentType = block[0].split(":")[1];
            let realData = block[1].split(",")[1];
            let blob = b64toBlob(realData, contentType);
            let tFileId = uppy.addFile({
              name: file.data.name.split('.')[0] + ".jpeg",
              type: 'image/jpeg',
              data: blob,
              isRemote: false,
              source: "Dashboard"
            });

            //crate a signature in the uppy file object for reference later... 
            uppy.setFileState(tFileId, {
              adkThumbnailFor: file.id
            });
            uppy.setFileState(file.id, {
              adkThumbnailId: tFileId
            });

            //set thumbnail to preview for video file
            uppy.setFileState(file.id, {
              preview: image
            });
          }); // on capture

          thumbnails.capture(file.data);
        } // if (file.type.includes('video')) {
      }); //on file added

      this.uppy.on('file-removed', () => {
        this.uppy.reset();
        this.uppy.setOptions({
          restrictions: {
            maxNumberOfFiles: 1
          }
        });
      });

      //this handle each individual upload
      this.uppy.on('upload-success', (file, response) => {
        //const extsV = config.videoExt;      
        let videoWithThumbnails = this.get('videoWithThumbnails');
        let id = response.uploadURL.split("/").slice(-1)[0].split("+")[0];

        //base on the signature... decide where the result go...
        //if video or thumbnail, goto videoWithThumbnails
        //if notmal.. go to tusid

        if (file.adkThumbnailId) {
          if (!videoWithThumbnails[file.id]) {
            videoWithThumbnails[file.id] = {};
          }
          videoWithThumbnails[file.id].videoFile = id;

          // this.set('thumbnail', "adkfid://" + id + '/video')
        }
        //check to see no adkThumbnailFor.. 
        else {
          if (!file.adkThumbnailFor) {
            //dont have adkThumbnailFor in file.. push it 
            this.tusid.push("adkfid://" + id);
          } else {
            if (!videoWithThumbnails[file.adkThumbnailFor]) {
              videoWithThumbnails[file.adkThumbnailFor] = {};
            }
            videoWithThumbnails[file.adkThumbnailFor].thumbnailFile = id;
          }
        } // this.set('thumbnail', "adkfid://" + id + '/thumb')
        //this.set('thumbnail', "adkfid://" + id);
      });

      //this is the whole upload (if multiple files)
      // result => result.failed[] and result.successful[] 2 array;
      // this.uppy.on('complete', (result) => {
      //   console.log("syeo ****", result);
      //   result.successful.forEach((file) => {
      //     console.log('Successfully uploaded file ' + file.name);
      //   });
      // });
    },
    setComplete(completeFunc) {
      let thisService = this;
      this.uppy.on('complete', result => {
        //here where we push the videoWithThumbnails into the tusid before call completion
        for (const key in thisService.videoWithThumbnails) {
          let str;
          if (thisService.videoWithThumbnails[key]['videoFile']) {
            str = "adkfid://" + thisService.videoWithThumbnails[key]['videoFile'];
            if (thisService.videoWithThumbnails[key]['thumbnailFile']) {
              str += "/" + thisService.videoWithThumbnails[key]['thumbnailFile'] + "/video";
            }
            thisService.tusid.push(str);
          }
          delete thisService.videoWithThumbnails[key];
        } //for
        completeFunc(result);
      });
    },
    //this get called when component get destroy...    
    clearId() {
      this.tusid = [];
      this.videoWithThumbnails = {};
    }
  });
});