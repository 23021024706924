define("adtikir3v1/pods/components/adk-ad-media-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('listAds', {
        page: 0,
        pageSize: 10,
        action: this.get('loadAdMediaListTasks'),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    loadAdMediaListTasks: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let companyId = this.get('company').CompanyId;
      let result = yield this.store.query('list-ad-media-vendor', {
        company: companyId,
        page: page,
        pageSize: pagingObj.pageSize
      });

      // if(result.meta && result.meta.page && result.meta.page.total) {
      //     set(pagingObj, "total", result.meta.page.total);
      // }
      Ember.set(pagingObj, 'result', result);
      // set(pagingObj, 'page', page);

      return true;
    })
  });
});