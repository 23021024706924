define("adtikir3v1/pods/components/adk-cancel-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kV61JexE",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[4,\"paper-button\",null,[[\"raised\",\"warn\",\"onClick\"],[true,true,[28,\"action\",[[23,0,[]],\"actionCancel\"],null]]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isPop\"]]],null,{\"statements\":[[4,\"paper-dialog\",null,[[\"class\",\"onClose\",\"origin\",\"clickOutsideToClose\"],[\"flex-50\",[28,\"action\",[[23,0,[]],\"continueCancelDialog\"],null],[28,\"concat\",[\"#\",[23,0,[\"emberId\"]]],null],true]],{\"statements\":[[0,\"\\n\"],[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"          \"],[7,\"h2\",true],[8],[0,\"Cancelation dialog\"],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n          \"],[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[28,\"action\",[[23,0,[]],\"continueCancelDialog\"],null]]],{\"statements\":[[1,[28,\"paper-icon\",null,[[\"icon\"],[\"close\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-dialog-content\",null,[[\"class\"],[\"adk-industryTypeDailogHigh\"]],{\"statements\":[[0,\"          Are you sure you want to the operation?\\n\"]],\"parameters\":[]},null],[4,\"paper-dialog-actions\",null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n        \"],[4,\"paper-button\",null,[[\"raised\",\"onClick\"],[true,[28,\"action\",[[23,0,[]],\"cancelCancelDialog\"],null]]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"paper-button\",null,[[\"raised\",\"warn\",\"onClick\"],[true,true,[28,\"action\",[[23,0,[]],\"continueCancelDialog\"],null]]],{\"statements\":[[0,\"OK\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-cancel-dialog/template.hbs"
    }
  });
});