define("adtikir3v1/models/media-format", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    mediaAdType: attr('string'),
    pictureLocation: attr('string'),
    description: attr('string'),
    mediaCompany: attr('string'),
    promisedImpressions: attr('string'),
    actualImpressions: attr('string'),
    comment: attr('string'),
    selectedMedia: attr('string'),
    mediaType: belongsTo('mediaType')
  });
});