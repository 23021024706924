define("adtikir3v1/models/project-awarded", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    AdvertiserCompanyId: attr('string'),
    VendorCompanyId: attr('string'),
    AdCampaignId: attr('string')
  });
});