define("adtikir3v1/pods/components/adk-display-media/component", ["exports", "ember-concurrency", "adtikir3v1/config/environment", "@recogito/annotorious", "@recogito/annotorious-toolbar", "@recogito/annotorious/dist/annotorious.min.css"], function (_exports, _emberConcurrency, _environment, _annotorious, _annotoriousToolbar, _annotoriousMin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    CDN: Ember.inject.service('adk-cdn-url'),
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nolink: false,
    popUp: false,
    annoEditor: false,
    annoView: false,
    showAttachment: false,
    randId: Ember.computed('randomId', function () {
      let id = Math.random();
      return id;
    }),
    author: Ember.computed('adkSessionAccount.session.profile.{firstName,lastName}', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let firstName = adkSessionAccount.get('session').get('profile').firstName;
      let lastName = adkSessionAccount.get('session').get('profile').lastName;
      return `${firstName} ${lastName}`;
    }),
    userId: Ember.computed('adkSessionAccount.lasLoginRole', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let userId = adkSessionAccount.get('lastLoginRole').id;
      return userId;
    }),
    init() {
      this._super(...arguments);
      this.setProperties({
        url: '',
        videoType: ''
      });
      this.reset();
    },
    //init

    reset() {
      this.setProperties({
        isImage: false,
        isVideo: false,
        isPdf: false,
        isAudio: false,
        isDoc: false,
        isLandscape: false
      });
    },
    didReceiveAttrs() {
      //     fileid: this.fileid,
      if (this.width > this.height) {
        this.isLandscape = true;
      }
      if (this.title) {
        this.set('myTitle', this.title);
      } else {
        // this.myTitle = this.fileid;
        this.set('myTitle', this.fileid);
      }
      let geturl = this.get('getUrl');
      geturl.perform();
    },
    didUpdate() {
      this._super(...arguments);
      let author = this.get('author');
      let userId = this.get('userId');
      let aPD = this.get('aPD');
      let adkToast = this.get('adkToast');
      let ajax = this.get('ajax');
      let anno;

      //** Encode an object as querystring parameters **/
      function encodeQueryString(params) {
        const keys = Object.keys(params);
        return keys.length ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&") : "";
      }
      if (this.annoEditor) {
        // let imageSrc = document.getElementById('anno').src;
        let image = document.getElementById(this.randId);

        // alert(document.getElementById('anno'));
        //** Initialize the annotation */
        if (!image) {
          return adkToast.display("Image Src Not Found", "error", 3000);
        } else {
          anno = _annotorious.default.init({
            // image: document.getElementById('anno')
            image: this.randId,
            widgets: [{
              widget: "COMMENT",
              editable: true
            }]
          });

          //** Initialize the toolbar */
          (0, _annotoriousToolbar.default)(anno, document.getElementById('my-toolbar-container'));

          //** Able to see who is commenting */
          anno.setAuthInfo({
            id: userId,
            displayName: author
          });

          //** Split the url on "/", slice the element end to 3 and create a new string by using join with "/" */
          //** Slice method will return the selected elements in an array */
          let newSource = aPD + "/" + image.src.split("/").slice(3).join("/");

          // **Load the annotation from backend */
          //** encodeQueryString function output will be shown as ?source=imageSrc */
          ajax.request("/annotations" + encodeQueryString({
            source: newSource
          }), {
            method: 'GET'
          }).then(annotations => {
            anno.setAnnotations(annotations);
            return annotations;
          });

          //** Create new annotations */
          anno.on('createAnnotation', async function (annotation) {
            let source = annotation.target.source;
            let newSource = source.split("/").slice(3).join("/");
            const data = {
              body: annotation,
              source: aPD + "/" + newSource
            };
            ajax.request('/annotations', {
              method: 'POST',
              data: data
            }).then(annotation => {
              anno.getAnnotations(annotation);
              adkToast.display("Annotation is added successfully");
              return annotation;
            });
          });

          //** Update the annotations Eg: Reply / Edit the annotation shape */
          //** The reason to substr(1) in the previous.id is because the id is taken from original anno id with #, so skip the #
          anno.on('updateAnnotation', async function (annotation, previous) {
            ajax.request('/annotations' + "/" + previous.id.substr(1), {
              method: 'PATCH',
              data: annotation
            }).then(annotations => {
              anno.updateSelected(annotations);
              anno.saveSelected();
              adkToast.display("Annotation is updated");
              return annotations;
            });
          });

          //* Delete annotation */
          anno.on('deleteAnnotation', async function (annotation) {
            ajax.request('/annotations' + "/" + annotation.id.substr(1), {
              method: 'DELETE',
              data: annotation
            }).then(annotations => {
              adkToast.display("Annotation is deleted successfully");
              return annotations;
            });
          });
        }
      }
      if (this.annoView) {
        let image = document.getElementById(this.randId);
        if (!image) {
          return adkToast.display("Image Src Not Found");
        } else {
          anno = _annotorious.default.init({
            image: this.randId,
            readOnly: true
          });
          let newSource = aPD + "/" + image.src.split("/").slice(3).join("/");
          ajax.request("/annotations" + encodeQueryString({
            source: newSource
          }), {
            method: 'GET'
          }).then(annotations => {
            anno.setAnnotations(annotations);
            return annotations;
          });
        }
      }
    },
    preloadImage(url) {
      //let thisComponent = this;
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = resolve(image);
        image.onerror = reject;
        image.src = url;
        //thisComponent.set("img", image);
      });
    },
    getUrl: (0, _emberConcurrency.task)(function* () {
      //need check fileid first to decide what to do...
      let fileid = this.get('fileid');
      let preloadReturn;
      if (!fileid) {
        return '';
      }
      if (!fileid.substr) {
        throw new Error('fileid is not a string');
      } //no substr . throw error;

      if (fileid.substr(0, 7) === "adkf://") {
        let wip = fileid.substr(7);
        wip = wip.substring(wip.indexOf('/'));
        //need to plug in the bunny host...cdnHost
        this.set('url', _environment.default.cdnHost + wip);
      } else if (fileid.substr(0, 8) === "/assets/") {
        this.set('url', _environment.default.cdnHost + fileid);
      } else if (fileid.substr(0, 9) === "adkfid://") {
        try {
          let wip = fileid.substr(9);
          let response = yield this.CDN.getCdnUrl(wip);
          //filetype = response.data.filetype.split('/');
          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        }
        // } else if (fileid.substr(0, 7) === "/assets/" || 
      } else if (fileid.substr(0, 7) === "http://" ||
      //  fileid.substr(0, 7) === "http://" ||
      fileid.substr(0, 8) === "https://") {
        this.set('url', fileid);
      } else {
        try {
          //old id      
          let response = yield this.CDN.getCdnUrl(fileid);
          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        }
      } //if

      //ext...
      this.reset();
      let url = this.get('url');
      let ext = '.' + url.split('.').pop();
      // alert(url);
      if (_environment.default.imageExt.indexOf(ext) >= 0) {
        this.set('isImage', true);
      }
      if (_environment.default.videoExt.indexOf(ext) >= 0) {
        this.set('isVideo', true);
        if (ext === '.mp4') {
          this.set('videoType', 'video/mp4');
        }
      }
      if (_environment.default.pdfExt.indexOf(ext) >= 0) {
        this.set('isPdf', true);
      }
      if (_environment.default.docExt.indexOf(ext) >= 0) {
        this.set('isDoc', true);
      }
      if (_environment.default.audioExt.indexOf(ext) >= 0) {
        this.set('isAudio', true);
      }

      //need to detect file type base on file extention...
      //isImage

      //check video or image
      if (this.get('isImage')) {
        // if (this.isLandscape) {
        //   this.set('imageUrl', this.get('url') + '?height=' + this.height);
        // } else {
        //   this.set('imageUrl', this.get('url') + '?width=' + this.width);
        // }
        if (this.isLandscape && this.height && this.height !== '' && this.height !== 0) {
          this.set('imageUrl', this.get('url') + '?height=' + this.height);
        } else if (this.width && this.width !== '' && this.width !== 0) {
          this.set('imageUrl', this.get('url') + '?width=' + this.width);
        } else if (this.height && this.height !== '' && this.height !== 0) {
          this.set('imageUrl', this.get('url') + '?height=' + this.height);
        } else {
          this.set('imageUrl', this.get('url'));
        }
        preloadReturn = yield this.preloadImage(this.get('imageUrl'));
      } else if (this.get('isVideo')) {
        this.set('url', this.get('url') + '#t=0.1');
        //            this.set('videoType', response.data.filetype)
      } //else isVideo
      else if (this.get('isPdf')) {
        this.set('url', this.get('url'));
      } else if (this.get('isDoc')) {
        this.set('url', this.get('url'));
      } else if (this.get('isAudio')) {
        this.set('url', this.get('url'));
      }
      if (this.setIsImage) {
        this.setIsImage(this.isImage, preloadReturn);
      }
    }),
    //task

    actions: {
      // imageLoad(ev) {
      //   if (this.isImage) {
      //     let img = ev.target;
      //     if (this.isLandscape) {
      //       if (img.width > this.width) {
      //         img.width = this.width;
      //         // console.log("************######***********");
      //         // console.log('adkdisplayimg width',this.width);
      //         // console.log("************######***********");
      //       }
      //     } else if (img.height > this.height){
      //        img.height = this.height;
      //         // console.log("************######***********");
      //         // console.log('adkdisplayimg height',this.height);
      //         // console.log("************######***********");
      //       }
      //   }
      //  },

      imageLoad(ev) {
        if (this.isImage) {
          let img = ev.target;
          let imgLandscape = false;

          //detect
          if (img.width >= img.height) {
            imgLandscape = true;
          }
          if (this.width == 0 || this.width == '') {
            this.width = img.width;
          }
          if (this.height == 0 || this.height == '') {
            this.height = img.height;
          }

          // image is landscape and display size is landscape
          // image is not landscape (portrait) and display size is landscape

          // check the width first... 
          // then only check the height

          if (imgLandscape && this.isLandscape || !imgLandscape && this.isLandscape) {
            if (img.width > this.width) {
              img.width = this.width;
            }
            if (img.height > this.height) {
              img.height = this.height;
            }
          } else {
            // this should be the rest combination
            // image is landscape and display size is not landscape (portrait)
            // image is not landscape (portrait) and display size is not landscape (portrait) 

            // check the height first... 
            // then only check the width 
            if (img.height > this.height) {
              img.height = this.height;
            }
            if (img.width > this.width) {
              img.width = this.width;
            }
          } //else if check image landscape and display landscape 
        } //if isImage
      },
      //imageLoad

      showAttachmentTask() {
        this.set('showAttachment', true);
        return false;
      },
      closeShowAttachment() {
        this.set('showAttachment', false);
      }
    } //action
  });
});