define("adtikir3v1/pods/components/adkcard-quickbudget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    isEditable: false,
    isEditing: false,
    listFields: Ember.A(),
    enableExchangeRate: false,
    init() {
      this._super(...arguments);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      //set display field array
      // let displayField = A();
      // let ad = this.get('ad');
      // //  console.log("budgetad: ", ad)

      // if (ad.get('workFlowStatusNumber') == 0) {
      //   this.set('isEditable', true);
      // }

      // if (ad.get('workFlowStatusNumber') < 1) {
      //   //use estimate budget
      //   displayField.pushObject({
      //     'estAgencyBudget': 'Estimated Agency Budget'
      //   });
      //   displayField.pushObject({
      //     'estMediaBudget': 'Estimated Media Budget'
      //   });
      //   displayField.pushObject({
      //     'estMiscBudget': 'Estimated Misc Budget'
      //   });
      //   displayField.pushObject({
      //     'estBudget': 'Estimated Budget'
      //   });

      // } else if (ad.get('workFlowStatusNumber') <= 8) {
      //   //show  planned budget
      //   displayField.pushObject({
      //     'plannedAgencyBudget': 'Planned Agency Budget'
      //   });
      //   displayField.pushObject({
      //     'plannedMediaBudget': 'Planned Media Budget'
      //   });
      //   displayField.pushObject({
      //     'plannedMiscBudget': 'Planned Misc Budget'
      //   });
      //   displayField.pushObject({
      //     'plannedBudget': 'Planned Budget'
      //   });
      //   //show show committed budget
      //   displayField.pushObject({
      //     'committedAgencyBudget': 'Committed Agency Budget'
      //   });
      //   displayField.pushObject({
      //     'committedMediaBudget': 'Committed Media Budget'
      //   });
      //   displayField.pushObject({
      //     'committedMiscBudget': 'Committed Misc Budget'
      //   });
      //   displayField.pushObject({
      //     'committedBudget': 'Committed Budget'
      //   });
      //   //show actual spend budget
      //   displayField.pushObject({
      //     'actualAgencySpend': 'Agency Actual Spend'
      //   });
      //   displayField.pushObject({
      //     'actualMediaSpend': 'Media Actual Spend'
      //   });
      //   displayField.pushObject({
      //     'actualMiscSpend': 'Media Misc Spend'
      //   });
      //   displayField.pushObject({
      //     'actualSpend': 'Actual Spend'
      //   });
      // }

      // this.set('displayField', displayField);
      //  console.log('displayField', displayField)
    },
    // totalSum: computed('ad.{estMediaBudget,estAgencyBudget,estMiscBudget}', function() {
    estTotalSum: Ember.computed('ad.{estMediaBudget,estAgencyBudget,estMiscBudget}', function () {
      let total = 0;
      let ad = this.get('ad');
      //string input will be change to int after multiple by 1
      // total = (ad.estAgencyBudget * 1) + (ad.estMediaBudget * 1) + (ad.estMiscBudget * 1);
      total = ad.estAgencyBudget * 1 + ad.estMediaBudget * 1 + ad.estMiscBudget * 1;
      return total;
    }),
    actions: {
      enableExchangeRate() {
        this.set('enableExchangeRate', true);
      },
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      cancel() {
        this.toggleProperty('isEditing');
        let ad = this.get('ad');
        if (ad.get('hasDirtyAttributes')) {
          this.ad.rollbackAttributes();
        }
      },
      saveQuickBudget() {
        let component = this;
        let adkToast = this.get('adkToast');
        let ad = this.get('ad');
        if (parseFloat(ad.get('estMediaBudget')) <= 0) {
          alert("media budget is not correct");
          return;
        }
        if (parseFloat(ad.get('estAgencyBudget')) <= 0) {
          alert("agency budget is not correct");
          return;
        }
        if (parseFloat(ad.get('estMiscBudget')) <= 0) {
          alert("misc budget is not correct");
          return;
        }
        ad.set('estBudget', parseFloat(ad.get('estMediaBudget')) + parseFloat(ad.get('estAgencyBudget')) + parseFloat(ad.get('estMiscBudget')));
        ad.save().then(function () {
          adkToast.display("Quick Budget successfully saved", "warning");
          component.set('isEditing', false);
        }, function () {
          if (this.ad.get('hasDirtyAttributes')) {
            this.ad.rollbackAttributes();
          }
          adkToast.display("Quick Budget not saved", "error");
        });
        // this.toggleProperty('isEditing');
      }
    }
  });
});