define("adtikir3v1/models/exchange-rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    fromCurrency: attr('string'),
    toCurrency: attr('string'),
    exchangeRate: attr('number'),
    exchangeDate: attr('date'),
    exchangeDateStr: attr('string')
  });
});