define("adtikir3v1/pods/components/adk-models-table-add-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VjH+pcpU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-button\",null,[[\"onClick\",\"iconButton\"],[[28,\"action\",[[23,0,[]],\"addAction\"],null],true]],{\"statements\":[[1,[28,\"paper-icon\",[\"AddCircle\"],[[\"size\"],[28]]],false]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-add-button/template.hbs"
    }
  });
});