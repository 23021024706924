define("adtikir3v1/helpers/debounce", ["exports", "@abcum/ember-helpers/helpers/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "debounce", {
    enumerable: true,
    get: function () {
      return _debounce.debounce;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _debounce.default;
    }
  });
});