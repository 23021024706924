define("adtikir3v1/pods/agency/admanager/ad/dashboard/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AgencyAdDashboardRoute = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    let adAgencyModel = yield this.modelFor('agency.admanager.ad');
    let targeting = yield adAgencyModel.adCampaignDetail.get('targeting');
    let locations = yield this.store.query('location', {
      adCampaignTargeting: adAgencyModel.adCampaign.get('id')
    });
    return {
      adAgency: adAgencyModel.ad,
      adCampaign: adAgencyModel.adCampaign,
      adCampaignDetail: adAgencyModel.adCampaignDetail,
      acWorkFlows: adAgencyModel.acWorkFlows,
      targeting: targeting,
      locationList: locations.toArray()
    };
  }).restartable(), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      let adAgency = yield this.modelFor('agency.admanager.ad').ad;
      let adVendor = yield this.store.query('adVendor', {
        AdId: adAgency.id
      });
      return adVendor;
    } catch (e) {
      this.intermediateTransitionTo('error', e);
      throw e;
    }
  }).restartable(), (_class = class AgencyAdDashboardRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);
      _initializerDefineProperty(this, "loadModelTask", _descriptor3, this);
      _initializerDefineProperty(this, "loadAdVendorTask", _descriptor4, this);
    }
    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    }
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('adAgency', model.adAgency);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('acWorkFlows', model.acWorkFlows);
      controller.set('targeting', model.targeting);
      controller.set('locationList', model.locationList);
      controller.set('loadAdVendorTaskInstance', this.loadAdVendorTask.perform());
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadModelTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadAdVendorTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});