define("adtikir3v1/pods/components/adk-project-users/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    init() {
      this._super();
      let adkSessionAccount = this.get('adkSessionAccount');
    },
    //init;

    actions: {
      secondaryMessageClick() {}
    }
  });
});