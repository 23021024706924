define("adtikir3v1/models/vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    aboutUs: attr('string'),
    rating: attr('number'),
    vendorType: attr('string'),
    mediaTypes: hasMany('media-type'),
    //industriesAgencyServes       : hasMany('', {inverse: null}),
    company: belongsTo('company'),
    vendorAgencyLanguages: hasMany('vendor-agency-language'),
    vendorMediaMediaType: belongsTo('vendor-media-media-type'),
    vendorAgencyIndustryTypes: hasMany('industry-type')

    //vendorSupportedTypes: hasMany('vendor-support-type'),
    //industryTypeServed: hasMany('')
  });
});