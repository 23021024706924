define("adtikir3v1/pods/components/adk-media-proposal/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    isOpen: false,
    //Variables
    campaignName: '',
    advertiser: '',
    editMode: Ember.computed('vMediaProposal.status', 'mediaProposalInvitedExipred', function () {
      let vMediaProposal = this.get('vMediaProposal');
      if (this.mediaProposalInvitedExipred) {
        return false;
      }
      if (vMediaProposal.status === "new") {
        return true;
      }
      return false;
    }),
    didReceiveAttrs() {
      //this is hack for aProposalResponse

      if (this.get('aProposalMediaResponse')) {
        this.set('vMediaProposal', this.get('aProposalMediaResponse'));
        this.set('aProposalMediaResponseType', true);
      } else {
        this.set('aProposalMediaResponseType', false);
      }

      //a patch for vProposalMedia to vMediaPRoposal
      if (this.get('vProposalMedia')) {
        this.set('vMediaProposal', this.get('vProposalMedia'));
      }
      let vMediaProposal = this.get('vMediaProposal');
      this.set('refresh', vMediaProposal.id);
      this.set('adCampaignMediaRfp', vMediaProposal.get('adCampaignMediaRfp'));
      this.set('adCampaignMediaRfpBody', vMediaProposal.get('adCampaignMediaRfpBody'));
      this.set('openedTarget', null);
      if (vMediaProposal.status === "new") {
        let currencyAccept = this.get('adCampaignMediaRfp').get('currencyAccept');
        let currencyOption = currencyAccept.split(',');
        this.set('currencyOption', currencyOption);
      }
      this.set('mediaProposalItemLists', {
        page: 0,
        pageSize: 200,
        //set it very big
        action: this.get("loadMediaProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('showMediaVendorDetailId', false);
      if (!('mediaProposalInvitedExipred' in this)) {
        this.set('mediaProposalInvitedExipred', false);
      }
    },
    //didReceiveAttrs

    loadMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };
      if (this.aProposalMediaResponseType) {
        let aMediaProposalResponseId = this.get('aMediaProposalResponse').id;
        findObj.aMediaProposalResponse = aMediaProposalResponseId;
      } else {
        let vMediaProposalId = this.get('vMediaProposal').id;
        findObj.vMediaProposal = vMediaProposalId;
      }
      let attachments = yield this.store.query('media-proposal-attachment', findObj);

      // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }
      Ember.set(pagingObj, "result", attachments);
      // if (pagingObj.refresh) { 
      //     this.set('refresh', false); 
      // }
    }).restartable(),
    loadMediaProposalItemTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };
      if (this.aProposalMediaResponseType) {
        let aMediaProposalResponseId = this.get('aMediaProposalResponse').id;
        findObj.aMediaProposalResponse = aMediaProposalResponseId;
      } else {
        let vMediaProposalId = this.get('vMediaProposal').id;
        findObj.vMediaProposal = vMediaProposalId;
      }
      let mediaProposalItems = yield this.store.query('media-proposal-item', findObj);
      // if (mediaProposalItems.meta && mediaProposalItems.meta.page && mediaProposalItems.meta.page.total) {
      //   set(pagingObj, "total", mediaProposalItems.meta.page.total);
      // }

      Ember.set(pagingObj, "result", mediaProposalItems);
    }),
    proposalExpiredDateComputed: Ember.computed('vMediaProposal.responseExpiredDayCount', function () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      let vMediaProposal = this.get('vMediaProposal');
      let responseExpiredDayCount = vMediaProposal.get('responseExpiredDayCount');
      let validDate = (0, _moment.default)(adCampaignMediaRfpBody.get('proposalDeadline')).add(responseExpiredDayCount, 'days');
      //let dateObj = new Date(adkSessionAccount.getTime());
      let rtnDate = new Date(validDate);
      return rtnDate;
    }),
    actions: {
      toggleEdit(field, even) {
        let fieldAllow = ['projectObjectives', 'ourUnderstanding', 'aboutUs', 'description',
        // 'ourMethodology',
        // 'discovery',
        // 'visualize',
        'currency', 'ourTeam', 'ourResults', 'cost', 'timelines', 'terms', 'responseExpiredDate', 'taxIP', 'finalNote', 'proposalNote'];
        //check if allow to edit
        if (!this.editMode) {
          return true;
        }
        let idx = fieldAllow.indexOf(field);
        if (idx != -1) {
          if (this.isOpen) {
            alert("Field is opened, please close it first");
            this.openedTarget.scrollIntoView();
            return;
          }
          //this.set('isOpen', true);
          this.toggleProperty('isEdit' + fieldAllow[idx]);
          if (this.get('isEdit' + fieldAllow[idx])) {
            this.set('isOpen', field);
            let target = even.currentTarget;
            this.set('openedTarget', target.parentNode.parentNode.parentNode);
            if (field === 'cost') {
              this.set('editorIsOpen', false);
            }
          } else {
            this.set('isOpen', false);
            this.set('openedTarget', null);
          }
          return;
        }
        this.toggleProperty('isEditing');
      },
      //toggleEdit

      openDialogWithParent(event) {
        if (this.isOpen) {
          alert("Some field is opened, please close it first");
          return;
        }
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },
      closeDialogWithParent() {
        // this.set('result', result);
        this.set('showDialogWithParent', false);
        //this.actions.cancelAction();
      },
      toggleProjObjectives() {
        this.toggleProperty('isEditProjectObjectives');
      },
      cancelAction(field) {
        let adkToast = this.get('adkToast');
        let vMediaProposal = this.get('vMediaProposal');
        if (field === 'cost') {
          if (this.get('editorIsOpen')) {
            alert('The editor form is opened, please close it first');
            return false;
          }
          vMediaProposal.reload();
          adkToast.display("Editor closed", "warning");
        } else {
          if (vMediaProposal.get('hasDirtyAttributes')) {
            vMediaProposal.rollbackAttributes();
          }
          adkToast.display("Action cancel", "warning");
        } //else
        this.set('isOpen', false);
        this.actions.toggleEdit.call(this, field);
      },
      saveAdkMidiaProposal(field) {
        // event.preventDefault();
        let component = this;
        let adkToast = this.get('adkToast');
        let vMediaProposal = this.get('vMediaProposal');
        if (field === "responseExpiredDate") {
          vMediaProposal.set("responseExpiredDate", this.get('proposalExpiredDateComputed'));
        }
        this.saveProposal().then(function () {
          component.set('isOpen', false);
          component.actions.toggleEdit.call(component, field);
          adkToast.display("Proposal successfully saved.", "warning");
        });
      },
      submitAdkMediaProposal(event) {
        // event.preventDefault();
        let target = event.target;
        let thisComponent = this;
        if (this.isOpen) {
          alert("Some field is opened, please close it first");
          this.set('openedTarget', target.parentNode.parentNode.parentNode);
          return;
        }

        // let component = this;
        let adkToast = this.get('adkToast');
        let vMediaProposal = this.get('vMediaProposal');
        vMediaProposal.set('status', 'vSubmitted');
        this.saveProposal('submitted').then(function () {
          thisComponent.set('showDialogWithParent', false);
          thisComponent.set('editMode', false);
          adkToast.display("Proposal submitted successfully.", "warning");
        });
        //need to save invitee to submitted....
      },
      showVendorMediaDetailAction: function (vendorMediaDetailId, event) {
        event.preventDefault();
        if (this.showMediaVendorDetailId) {
          alert('Other detail already opened... please close it first');
          return false;
        }
        Ember.set(this, 'showMediaVendorDetailId', vendorMediaDetailId);
        //set(this,'showVendorMediaDetailFlag',true);
      },
      closeVendorMediaDetailAction: function (event) {
        event.preventDefault();
        Ember.set(this, 'showMediaVendorDetailId', false);
        //set(this,'showVendorMediaDetailFlag',false);
      },
      nothing() {
        // Do nothing. Used just to highlight sections.
      }
    }
  });
});