define("adtikir3v1/pods/profile/company/management/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2G9CaNUU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-column flex layout-xs-column\"],[10,\"style\",\"margin-bottom:100px\"],[8],[0,\"\\n\\n\"],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-column flex flex-gt-xs-50\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loadCompanyRolesTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"           company role is loading ..... \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"           \"],[5,\"adk-companyinfo-users\",[],[[\"@existingUser\"],[[24,[\"loadCompanyRolesTaskInstance\",\"value\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"adkSessionAccount\",\"lastLoginRole\",\"role\"]],\"OWNER\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"layout-column flex flex-gt-xs-50\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loadInvitationsTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"               company role is loading ..... \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"               \"],[5,\"adk-companyinfo-add-user\",[],[[\"@invitations\",\"@oldInvitations\",\"@saveAction\"],[[24,[\"loadInvitationsTaskInstance\",\"value\",\"invitations\"]],[24,[\"loadInvitationsTaskInstance\",\"value\",\"oldInvitations\"]],[28,\"action\",[[23,0,[]],\"saveInvitationAction\"],null]]],{\"statements\":[[0,\"\\n               \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/profile/company/management/template.hbs"
    }
  });
});