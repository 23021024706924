define("adtikir3v1/models/budget-work-flow", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    wfStep: attr('number'),
    stepName: attr('string'),
    stepNameLocalLanguage: attr('string')
  });
});