define("adtikir3v1/pods/components/adk-dashboard-plannercommon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super();
      this.options = {
        height: 300,
        width: 400,
        animation: {
          startup: true,
          easing: 'inAndOut'
        }
      };
    },
    aggregatedTotals: Ember.computed("ads.@each.[estimatedImpressions,actualImpression,committedBudget,actualSpend]", function () {
      let ads = this.get('ads');
      let adCampaign = this.get('adCampaign');
      let sum = [0, 0, 0, 0, 0];
      if (ads && ads.length > 0) {
        ads.forEach(function (item) {
          sum[0] += Ember.get(item, "estimatedImpressions");
          sum[1] += Ember.get(item, "actualImpression");
          sum[2] += Ember.get(item, "plannedBudget");
          // sum[2] += get(item, "plannedAgencyBudget")+get(item,"plannedMediaBudget")+get(item,"plannedMiscBudget");
          sum[3] += Ember.get(item, "actualSpend");
          // sum[3] += adCampaign.invoiceCampaign.get('invoiceAmount');
        });
        // sum[4] = adCampaign.get('campaignPlanned') - sum[2]; 
        sum[4] = adCampaign.get('campaignUltimateBudget') - sum[2];
      }
      return sum;
    }),
    numberEstimatedImpressionsData: Ember.computed("ads", function () {
      let ads = this.get('ads');
      let google = window.google;
      let dataArray = [['Ad Name', 'Estimated Impressions', {
        role: 'style'
      }]];
      ads.forEach(ad => {
        let d = [];
        d.pushObject(ad.adName);
        d.pushObject(ad.estimatedImpressions);
        d.pushObject(ad.adColor);
        dataArray.pushObject(d);
      });
      let dataTable = new google.visualization.arrayToDataTable(dataArray);
      return dataTable;
    }),
    numberAdBudgetData: Ember.computed("ads", function () {
      let ads = this.get('ads');
      let google = window.google;
      let dataArray = [['Ad Name', 'Planned Budget', {
        role: 'style'
      }]];
      ads.forEach(ad => {
        let d = [];
        // let plannedBudget = ad.get('plannedMediaBudget')+ad.get('plannedAgencyBudget')+ad.get('plannedMiscBudget');
        d.pushObject(ad.adName);
        d.pushObject(ad.plannedBudget);
        // d.pushObject(plannedBudget)
        d.pushObject(ad.adColor);
        dataArray.pushObject(d);
      });
      let dataTable = new google.visualization.arrayToDataTable(dataArray);
      return dataTable;
    }),
    // numberAdBudgetData: computed("ads.@each.committedBudget", function () {
    //   //let controller = this;
    //   let data = {labels:[],datasets:[]};  
    //   let ads=this.get('ads');
    //   if (ads) {
    //       data = {
    //         labels: ads.mapBy("adName"),
    //         datasets: [{
    //           label: "Ad Budgets",
    //           backgroundColor: ads.mapBy('adColor'),
    //           data: ads.mapBy('plannedBudget')
    //         }]
    //       };
    //   }
    //   console.log(data);
    //     data.labels.pushObject("Remaining Budget");
    //   if (data.datasets.length > 0) {
    //      data.datasets[0].backgroundColor.pushObject('rgba(0, 0, 0, 0.1)');
    //      let remainBudget = 0;
    //      // remainBudget = this.get('adcampaignModel.adcampaign.adcampaigndetail.budget') - this.get('aggregatedTotals')[2];
    //      remainBudget = this.get('aggregatedTotals')[4];
    //      data.datasets[0].data.pushObject(remainBudget);
    //   }
    //   return data;
    // }),

    cpmData: Ember.computed("ads", function () {
      let ads = this.get('ads');
      let google = window.google;
      let dataArray = [['Ad Name', 'CPM - cost per mile', {
        role: 'style'
      }]];
      ads.forEach(ad => {
        let d = [];
        d.pushObject(ad.adName);
        d.pushObject(ad.cpm);
        d.pushObject(ad.adColor);
        dataArray.pushObject(d);
      });
      let dataTable = new google.visualization.arrayToDataTable(dataArray);
      return dataTable;
    })
  });
});