define("adtikir3v1/pods/campaign/details/ads/ad/financials/quickbudget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XVRcleRc",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"ad\",\"workFlowStatusNumber\"]],0],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"adkcard-quickbudget\",null,[[\"ad\"],[[24,[\"ad\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex layout-column md-padding\"],[8],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"adkAdNewHeader\"],[8],[1,[22,\"mediaType\"],false],[9],[0,\"\\n\"],[4,\"paper-content\",null,null,{\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('adtikir3v1/pods/campaign/details/ads/ad/financials/quickbudget/template.hbs' @ L10:C19) \"],null]],null,{\"statements\":[[0,\"                    Work flow status now is greater than 0... cannot set estimate budget here\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/ads/ad/financials/quickbudget/template.hbs"
    }
  });
});