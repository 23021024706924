define("adtikir3v1/models/my-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    company: belongsTo('my-company', {
      async: true,
      inverse: 'myRole'
    }),
    user: belongsTo('me', {
      async: true,
      inverse: 'myRoles'
    }),
    role: attr('string'),
    //owner, manager, user, agency, adagency, client
    companyName: attr('string'),
    status: attr('string'),
    lastLoginDate: attr('date'),
    updatedAt: attr('date'),
    createdAt: attr('date')
  });
});