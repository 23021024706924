define("adtikir3v1/pods/components/adk-models-table-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jCP6iLO8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-checkbox\",null,[[\"value\",\"disabled\",\"onChange\"],[[23,0,[\"record\",\"isSelected\"]],[24,[\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"clickOnRow\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-checkbox/template.hbs"
    }
  });
});