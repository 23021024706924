define("adtikir3v1/pods/components/adk-ad-invoice/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment", "ember-concurrency"], function (_exports, _adkDateTime, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import date from 'ember-data/transforms/date';
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    invoiceNumber: '',
    description: '',
    invoiceAmount: 0,
    agencyCost: 0,
    mediaCost: 0,
    miscCost: 0,
    notes: '',
    // invoiceDate: '',
    // Set up today's date as center for the calendar
    //currentDate: moment(new Date()).format('YYYY-MM-DD'),

    workFlowOptions: Ember.computed('acWorkFlows.[]', function () {
      let array = [];
      let acWorkFlows = this.get('acWorkFlows');
      acWorkFlows.forEach(function (item, index) {
        if (item.isSelected && item.id != acWorkFlows.length - 1) {
          array.pushObject({
            index: index,
            workflowStatus: item.workFlowStatus
          });
        }
      });
      return array;
    }),
    /*
      img: computed('ad.invoiceAd.invoiceImage', function(){
          let ad=this.get('ad');
          return ad.invoiceAd.invoiceImage;
      }),
      */

    init() {
      // let modelType = this.get('modelType');
      this._super(...arguments);
      this.numberValidation = [{
        message: 'Please provide  a valid value (99999999.99)',
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      // if (modelType== 'invoiceAd') {
      //    this.set('invoiceTypeArray', ['agency', 'media']);
      // }

      this.formClear();
    },
    saveAdInvoice: (0, _emberConcurrency.task)(function* () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      yield this.saveNewAdInvoice({
        workFlowStep: this.get('initialWF.workflowStatus'),
        invoiceNumber: this.get('invoiceNumber'),
        description: this.get('description'),
        invoiceAmount: this.get('invoiceAmount'),
        agencyCost: this.get('agencyCost'),
        mediaCost: this.get('mediaCost'),
        miscCost: this.get('miscCost'),
        invoiceDoc: this.get('invoices'),
        notes: this.get('notes'),
        submissionDate: new Date(this.set('submissionDate', todayDate)),
        dueDate: this.get('dueDate').toDate()
      }, this.get('ad'));
      this.formClear();
      this.cancelAction();
    }),
    formClear: function () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      this.set('invoiceNumber', '');
      this.set('invoiceDoc', '');
      this.set('invoiceAmount', 0);
      this.set('description', '');
      this.set('notes', '');
      this.set('initialWF', '');
      this.set('dueDate', todayDate);
      this.set('dueDateCenter', todayDate);
    },
    actions: {
      uploadInvoiceDoc: function () {
        //do nothing
        return;
      },
      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let newDate = selected.moment;
        let dueDate = this.get('dueDate');
        if (theField != 'dueDate') {
          if (dueDate != '') {
            newDate = '';
          }
        }
        if (newDate != '') {
          this.set(theField, newDate);

          // let theActualField = theField.substring(0, theField.length - 5);

          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theField, (0, _moment.default)(new Date(newDateStr)));
          adkToast.display("Date successfully set", "warning", 1000);
        }
      },
      checkCenter(theField, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let dueDate = this.get('dueDate');
        if (theField != 'dueDate') {
          if (dueDate != '') {
            if (_adkDateTime.default.compareYearMonth(newCenterDate, dueDate) <= 0) {
              newCenterDate = dueDate;
            }
          }
        }
        this.set(centerField, newCenterDate);
      },
      closeDialog() {
        this.formClear();
      },
      calCost(field, value) {
        let controller = this;
        this.set(field, value);
        function calTotal() {
          let a = parseFloat(controller.get("agencyCost"));
          let b = parseFloat(controller.get("mediaCost"));
          let c = parseFloat(controller.get("miscCost"));
          if (isNaN(a)) {
            a = 0;
          }
          if (isNaN(b)) {
            b = 0;
          }
          if (isNaN(c)) {
            c = 0;
          }
          controller.set("invoiceAmount", a + b + c);
        }
        Ember.run.scheduleOnce('actions', controller, calTotal);
      }

      // updateInvoiceDoc: function (url) {
      //   this.set('invoiceDoc', url);
      // },

      // actionSaveInvoice: function () {
      //   let sessionAccount = this.get('sessionAccount');
      //   let company= sessionAccount.get('lastLoginRole').get('company');
      //   let user=sessionAccount.get('user');

      //   let adkInvoiceComponent = this;
      //   let adkToast = this.get('adkToast');
      //   let dateTime = '_' + moment(new Date()).format('YYYYMMDDHmmss');
      //   let adcampaign = this.get('adcampaign');
      //   let modelType = this.get('modelType');
      //   let ad = null;
      //   if (modelType == 'invoiceAd') {
      //     ad = this.get('ad');
      //   }

      //    //error checking here
      //    //need to check media type
      //    //check invoice amount is number here

      //   if (this.get('invoiceNumber') == '') {
      //     adkToast.display("Please enter invoice number", "error",3000);
      //     return false;
      //   }
      //   /*
      //   if (this.get('invoiceImage') == '') {
      //     adkToast.display("Please upload an invoice", "error",3000);
      //     return false;
      //   }
      //   */
      //   if (isNaN(this.get('invoiceAmount')) || this.get('invoiceAmount') < 0) {
      //     adkToast.display("Please enter a valid invoice amount", "error",3000);
      //     return false;
      //   }

      //   let id;
      //   if (modelType == 'invoiceAd') {
      //     id = this.get('adcampaign.id').concat("_").concat(this.get('ad.id')).concat('_invoice').concat(dateTime);
      //   } else {
      //     id = this.get('adcampaign.id').concat('_invoice').concat(dateTime);
      //   }

      //   let newInvoice = this.store.createRecord(modelType, {
      //     id: id,
      //     invoiceNumber: this.get('invoiceNumber'),
      //     description: this.get('description'),
      //     invoiceAmount: this.get('invoiceAmount'),
      //     notes: this.get('notes'),
      //     invoiceDoc: this.get('invoiceDoc'),
      //     submissionDate: new Date(),
      //     activityDate: new Date(), //to be confirmed
      //     dueDate: new Date(this.get('dueDate')),
      //   });

      //   newInvoice.set('company', company);
      //   newInvoice.set('uploadBy', user);
      //   //set the relationship here and invoiceAd spec 
      //   newInvoice.set('adcampaign', adcampaign);

      //   if (modelType== 'invoiceAd') {
      //     newInvoice.set('invoiceType', this.get('invoiceType'));
      //     newInvoice.set('workflowstep', this.get('initialWF').index);

      //     newInvoice.set('ad', ad);
      //     ad.invoiceAds.addObject(newInvoice);
      //   } else {
      //     adcampaign.invoiceCampaigns.addObject(newInvoice);
      //   }

      //   newInvoice.save().then(onAdSuccess, onAdFailure);

      //   function cleanUp() {
      //     adkInvoiceComponent.formClear();
      //     //adkInvoiceComponent.get('returnToCaller')();
      //   }

      //   function onAdSuccess() {
      //     if (modelType=='invoiceAd') {
      //        ad.save().then(cleanUp);
      //     } else {
      //        adcampaign.save().then(cleanUp);
      //     }       
      //     adkToast.display("Invoice saved successfully", "warning",3000);
      //     if (this.cancelAction) {
      //       this.cancelAction();
      //     }        

      //   }

      //   function onAdFailure() {
      //     adkToast.display("Invoice cannot be saved", "error",3000);
      //     adkInvoiceComponent.get('returnToCaller')();
      //     if (this.cancelAction) {
      //       this.cancelAction();
      //     }        

      //   }
      // },

      // actionInvoiceCancel: function () {
      //   this.formClear();
      //   if (this.cancelAction) {
      //     this.cancelAction();
      //   }
      //      this.get('returnToCaller')();
      // },
    }
  });
});