define("adtikir3v1/models/ad-agency-proposal-invitee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    company: belongsTo('company', {
      async: true
    }),
    adAgencyProposalRequestList: belongsTo('ad-agency-proposal-request-list'),
    advertiserCompanyName: attr('string'),
    status: attr('string'),
    isPrefer: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});