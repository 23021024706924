define("adtikir3v1/pods/components/adk-delete-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "e4/xCiH+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"type\",\"button\"],[8],[0,\"\\n  Delete\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-delete-row/template.hbs"
    }
  });
});