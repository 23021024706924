define("adtikir3v1/pods/campaign/details/vendor/media/rfp/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //route for campaign.detail.vemdor.rfp.agency
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
      let adCampaignMediaRfps = yield this.store.query('ad-campaign-media-rfp', {
        adCampaign: adCampaign.id
      });

      //let mediaTypes = yield this.get('store').findAll('media-type');

      //let mediaTypes = yield this.store.findAll('mediaType');

      return {
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail,
        adCampaignMediaRfp: adCampaignMediaRfps.firstObject
        //   mediaTypes: mediaTypes
      };
    }),
    model: function () {
      return this.loadModelTask.perform();
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      let adCampaignMediaRfpsIsCreated = false;
      if (model.adCampaignMediaRfp) {
        adCampaignMediaRfpsIsCreated = true;
      }
      controller.set('adCampaignMediaRfpsIsCreated', adCampaignMediaRfpsIsCreated);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adCampaignMediaRfp', model.adCampaignMediaRfp);
      controller.cleanUp();
    } //setupConntroller
  });
});