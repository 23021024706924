define("adtikir3v1/pods/components/adk-user-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  //import { computed } from '@ember/object'; 
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('newPasswordConfirmValidation', [{
        message: 'New password is not match with confrim new password.',
        validate: inputValue => {
          let confirmNewPassword = this.get('confirmNewPassword');
          if (!confirmNewPassword) {
            return true;
          }
          if (confirmNewPassword && inputValue) {
            if (confirmNewPassword == inputValue) {
              return true;
            } //if conformPAssword == inputvalue
          } //if 
          return false;
        } //validate
      }]);
      this.set('confirmNewPasswordConfirmValidation', [{
        message: 'Confirm new password is not match with new password.',
        validate: inputValue => {
          let newPassword = this.get('newPassword');
          if (newPassword && inputValue) {
            if (newPassword == inputValue) {
              return true;
            } //if conformPAssword == inputvalue
          } //if 
          return false;
        } //validate
      }]);
    },
    //For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        isEditing: false,
        // localVariable1:      this.modelName.get('localVariable1'),
        // localVariable2:      this.modelName.get('localVariable2'),
        email: this.me.get('email'),
        aboutMe: this.me.get('aboutMe')
      });
    },
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      cancelEdit() {
        this.toggleProperty('isEditing');
        if (this.me.get('hasDirtyAttributes')) {
          this.me.rollbackAttributes();
        }
        this.set('oldPassword', null);
        this.set('newPassword', null);
        this.set('confirmNewPassword', null);
      },
      saveUserProfile(action, saveToDb) {
        // if (action) {
        //   this.me.set('action', action);
        // }
        if (action == "updatePassword") {
          this.me.set('action', action);
          this.me.set('email', this.get('email'));
          this.me.set('aboutMe', this.get('aboutMe'));
          this.me.set('oldPassword', this.get('oldPassword'));
          this.me.set('newPassword', this.get('newPassword'));
          this.saveAction().then(() => {
            let adkToast = this.get('adkToast');
            adkToast.display("Data Saved Success", "primary");
          }, () => {
            let adkToast = this.get('adkToast');
            this.me.rollbackAttributes(); // roll back the dirty attribute.. 
            // display error

            adkToast.display("Campaign Save Failed", "error");
            this.set('oldPassword', null);
            this.set('newPassword', null);
            this.set('confirmNewPassword', null);
          });
          this.toggleProperty('isEditing');
        } else if (action == "saveProfilePic") {
          if (saveToDb) {
            // this.me.set('profilePic', this.filesId[0]);
            this.me.set('action', action);
            this.saveAction().then(() => {
              let adkToast = this.get('adkToast');
              adkToast.display("Data Saved Success", "primary");
            }, () => {
              let adkToast = this.get('adkToast');
              this.me.rollbackAttributes(); // roll back the dirty attribute.. 
              // display error

              adkToast.display(Ember.String.capitalize(this.intl.t("campaign")) + " Save Failed", "error");
            });
          } //saveToDb
        } //action saveProfilePic
        //this.saveAction();
      }

      // toggleResetPassDialog() {
      //   this.set("showResetPassDialog", !this.showResetPassDialog);
      // },

      //     closeResetPassDialog(ev) {
      //       if (ev == 'save') {
      // //        this.set("showResetPassDialog", false);
      //         ///password not saved yet
      //       } else {
      //         this.set('oldPassword', '');
      //         this.set('newPassword', '');
      //         this.set('confirmNewPassword', '');
      //         this.set("showResetPassDialog", false);
      //       }
      //     },

      // uploadImage(attrs) {
      //   if (attrs.filesid.length != 0) {
      //     this.set("filesid", attrs.filesid);
      //   }
      //   if (this.filesid) {
      //     this.model.set('profilePic', this.filesid[0]);
      //     this.model.set("action", "saveProfilePic");
      //   }
      //   this.me.save();
      // },
      // localSaveName(){

      //     this.toggleProperty('isEditing');

      //     this.parentSaveName({
      //       localVariable1:      this.get('localVariable1'),
      //       localVariable2:      this.get('localVariable2'),
      //     }); //End of localComponentSaveName

      // },
    }
  });
});