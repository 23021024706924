define("adtikir3v1/initializers/helpers", ["exports", "@abcum/ember-helpers/initializers/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'ember-helpers',
    initialize: _helpers.default
  };
});