define("adtikir3v1/pods/media/projectmgmt/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from 'moment';
  // import {
  //   set
  // } from '@ember/object';
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    proposalInvited: '',
    proposalAgency: false,
    awardedProj: '',
    adCampaignRfp: '',
    showAward: false,
    adCampaignInvoice: '',
    selectedAdvertiserRfp: null,
    // loadMediaRfpTask: task(function* () {
    //   let adCampaignMediaRfpBody = yield this.get('proposalMedia').get('adCampaignMediaRfpBody');
    //   this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);

    // }),

    // loadAttachmentTask: task(function* (page, pagingObj) {
    //   let adCampaignRfpId = pagingObj.parentObj.id

    //   let attachments = yield this.store.query('rfp-attachment', {
    //     adCampaignRfp: adCampaignRfpId,
    //     page: page,
    //     pageSize: pagingObj.pageSize
    //   });
    //   if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
    //     set(pagingObj, "total", attachments.meta.page.total);
    //   }
    //   set(pagingObj, "result", attachments);
    //   //return attachments;
    // }).restartable(),

    loadVMediaProposalTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let result = yield this.store.query('v-proposal-media', {
        page: page,
        pageSize: pagingObj.pageSize,
        project: 'project'
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
    }),
    resetForm() {
      this.set('vProposalMediaPagingObj', {
        page: 0,
        pageSize: 20,
        action: this.get("loadVMediaProposalTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    clearVar() {
      this.set('vProposalMedia', null);
      this.set('adCampaignMediaRfpBody', null);
      this.set('adCampaignMediaRfp', null);
      this.set('vMediaInvoice', null);
    },
    setAwardTask: (0, _emberConcurrency.task)(function* (vProposalMedia) {
      this.clearVar();
      this.set('vProposalMedia', vProposalMedia);
      let adCampaignMediaRfpBody = yield this.get('vProposalMedia').get('adCampaignMediaRfpBody');
      this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
      this.set('adCampaignMediaRfp', yield adCampaignMediaRfpBody.get('adCampaignMediaRfp'));
    }),
    loadNewInvoiceTask: (0, _emberConcurrency.task)(function* (component) {
      let vProposalMedia = this.get('vProposalMedia');
      let vMediaInvoice = yield this.store.query('vMediaInvoice', {
        vProposalMedia: vProposalMedia.id
      });
      component.set("vMediaInvoice", vMediaInvoice);
    }),
    // loadInvoiceTask: task(function* (component) {
    //   let adCampaignRfp = this.get('adCampaignRfp');
    //   let adCampaignInvoice = yield this.store.query('adAgencyInvoice', {
    //     adCampaignRfp: adCampaignRfp.id,
    //   });
    //   component.set("adCampaignInvoice", adCampaignInvoice);
    // }),

    // loadVendorRolesTask: task(function* (component) {
    //   let adCampaignRfp = this.get('adCampaignRfp');
    //   let vendorRoles = yield this.store.query('vendorRole', {
    //     adCampaignRfp: adCampaignRfp.id
    //   });
    //   component.set("vendorRoles", vendorRoles.sortBy('status'));
    // }),

    actions: {
      error() {
        if (this.get('vProposalMedia')) {
          this.set('vProposalMedia', null);
        }
      },
      setAward(vProposalMedia) {
        this.set('setAwardTaskInstance', this.get('setAwardTask').perform(vProposalMedia));
      },
      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateMediaProposal(componentValues) {
        this.vProposalMedia.setProperties(componentValues);
        this.vProposalMedia.save();
      },
      createNewInvoice(componentValues, vProposalMedia) {
        let vMediaInvoice = this.store.createRecord('vMediaInvoice', {
          invoiceNumber: componentValues.invoiceNumber,
          description: componentValues.description,
          invoiceAmount: componentValues.invoiceAmount,
          mediaCost: componentValues.mediaCost,
          invoiceDoc: componentValues.invoiceDoc,
          notes: componentValues.notes,
          submissionDate: componentValues.submissionDate,
          dueDate: componentValues.dueDate,
          createdAt: componentValues.createdAt
        });
        vMediaInvoice.set('vProposalMedia', vProposalMedia);
        return vMediaInvoice.save();
      }
    } //End of actions
  });
});