define("adtikir3v1/models/place-state-tbd", ["exports", "ember-data", "adtikir3v1/models/place-tbd"], function (_exports, _emberData, _placeTbd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //const { Model } = DS;
  const {
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = _placeTbd.default.extend({
    name: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    country: belongsTo('country'),
    cities: hasMany('city')
  });
});