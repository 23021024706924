define("adtikir3v1/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === "v-proposal-media") {
        return payloadKey;
      } else {
        return this._super(payloadKey);
      }
    }
  });
});