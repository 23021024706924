define("adtikir3v1/pods/campaign/details/vendor/media/accepted/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from 'moment';
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    queryParams: ['aMediaProposalResponseId'],
    aMediaProposalResponseId: null,
    runLoadAMediaProposalResponseTask: (0, _emberConcurrency.task)(function* (aMediaProposalResponseId) {
      // GET /blog-posts/1
      let aMediaProposalResponse = yield this.store.findRecord('a-media-proposal-response', aMediaProposalResponseId);
      this.set('aMediaProposalResponse', aMediaProposalResponse);
      this.set('adCampaignMediaRfpBody', aMediaProposalResponse.get('adCampaignMediaRfpBody'));
    }),
    loadAMediaProposalResponsesTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adCampaignMediaRfp = this.get('adCampaignMediaRfp');
      let result = yield this.store.query('a-media-proposal-response', {
        adCampaignMediaRfp: adCampaignMediaRfp.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('aMediaProposalResponseList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAMediaProposalResponsesTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    //cleanUp

    actions: {
      setAMediaProposalResponse: function (aMediaProposalResponse) {
        this.set('aMediaProposalResponse', aMediaProposalResponse);
        this.set('adCampaignMediaRfpBody', aMediaProposalResponse.get('adCampaignMediaRfpBody'));
      },
      updateAMediaProposalResponse() {}
    }
  });
});