define("adtikir3v1/pods/campaign/details/ads/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    myRouteName: Ember.computed("this.router.currentPath", function () {
      let routeName = this.router.currentPath.split(".");
      return routeName;
    })

    // setupController(controller, model) {
    //   this._super(controller, model);

    //   let adcampaign = this.modelFor('campaign.details');
    //   controller.set('adcampaign', adcampaign);
    //   adcampaign.get('adcampaigndetail').then(function(adcampaignDetail) {
    //     controller.set('adcampaignDetail',adcampaignDetail);
    //   }); 
    // }  //setup controller  
  });
});