define("adtikir3v1/models/test-location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    location: belongsTo('location'),
    adCampaignMediaRfpBody: belongsTo('AdCampaignMediaRfpBody'),
    createdAt: attr('date'),
    updatedAt: attr('date')
    // vendorMediaDetail:  belongsTo('vendorMediaDetail', { inverse:'locations'}),
  });
});