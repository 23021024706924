define("adtikir3v1/pods/components/adk-paging-select-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    pageSize: 8,
    page: 0,
    // page shouold be start from 0

    didReceiveAttrs() {
      //outside should pass in total, pageSize, page, also an action to reload the data
      //total
      //pageSize
      //page
    },
    totalPage: Ember.computed('total', 'pageSize', function () {
      let total = this.get('total');
      let pageSize = 1;
      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }
      total = Math.ceil(total / pageSize);
      return total;
    }),
    pageArray: Ember.computed("totalPage", function () {
      let totalPage = this.get('totalPage');
      if (totalPage == 0) return [];
      //we need to generate an array from 1 to x as the 
      return Array.from(Array(totalPage).keys(), x => x + 1);
    }),
    actions: {
      setPage(number) {
        number = number - 1;
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }
        this.set('page', number);
        if (this.reloadAction) {
          this.reloadAction(number, this.get('pageSize'));
        }
      },
      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 2);

        //this.actions.setPage.call(this, this.get('page') + 1);
      },
      prevPage() {
        this.actions.setPage.call(this, this.get('page'));
        //this.actions.setPage.call(this, this.get('page')-1);
      }
    }
  });
});