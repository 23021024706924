define("adtikir3v1/pods/components/adk-media-calendar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('sortProperties', ['mediatype:asc', 'workFlowStatus:asc']);
    },
    //init

    sortedAds: Ember.computed.sort('ads.[]', 'sortProperties'),
    selectedAds: Ember.computed('sortedAds.[]', function () {
      let selectedAds = [];
      let selectedAdList = [];
      let ads = this.sortedAds;
      for (let i = 0; i < ads.length; i++) {
        if (ads[i].adPlayStartDate && ads[i].adPlayEndDate) {
          selectedAds[i] = ads[i];
          selectedAds[i].set('checkVal', true);
          selectedAdList.addObject(selectedAds[i]);
        }
      }

      //try cherate chartAds here?!;;;;   
      return selectedAdList;
    }),
    chartAds: Ember.computed('selectedAds.@each.checkVal', function () {
      let chartAds = [];
      //let ads = this.get('sortedAds');
      let selectedAds = this.get('selectedAds');
      if (selectedAds) {
        let ads = selectedAds.toArray();
        for (let i = 0; i < ads.length; i++) {
          if (ads[i].checkVal) {
            chartAds.addObject(ads[i]);
          }
        } //for
      }
      return chartAds;
    }),
    mediaTypeName: Ember.computed('mediaType.[]', function () {
      let mediaType = this.get("mediaType").toArray();
      let returnObj = {};
      for (let i = 0; i < mediaType.length; i++) {
        returnObj[mediaType[i].id] = mediaType[i].mediaTypeName;
      }
      return returnObj;
    }),
    workFlowStatus: Ember.computed('acWorkFlows.[]', function () {
      let acWorkFlows = this.get("acWorkFlows").toArray();
      let returnObj = {};
      for (let i = 0; i < acWorkFlows.length; i++) {
        returnObj[acWorkFlows[i].id] = acWorkFlows[i].workFlowStatus;
      }
      return returnObj;
    }),
    data: Ember.computed("chartAds", function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let ads = this.get('chartAds');
      let adCampaign = this.get('adCampaign');
      let dataArray = [];
      let dataTable = new window.google.visualization.DataTable();
      //let newProductLaunchDate = new Date(adCampaign.campaignProductLaunchDate)
      let campaignProductLaunchDate = new Date(adkSessionAccount.getDate(adCampaign.get('campaignProductLaunchDate'), true));
      let newProductLaunchDate = new Date(adCampaign.get('campaignProductLaunchDate').getTime() + 3600 * 1000 * 24);
      newProductLaunchDate = new Date(adkSessionAccount.getDate(newProductLaunchDate, true));
      dataTable.addColumn({
        type: 'string',
        label: 'Ad Name'
      });
      dataTable.addColumn({
        type: 'string',
        label: 'Label'
      });
      dataTable.addColumn({
        type: 'string',
        role: 'style'
      });
      dataTable.addColumn({
        type: 'date',
        label: 'Start Date'
      });
      dataTable.addColumn({
        type: 'date',
        label: 'End Date'
      });
      dataTable.addRows([["Product launch date", "", "", campaignProductLaunchDate, newProductLaunchDate]]);
      ads.forEach(ad => {
        let d = [];
        d.push(ad.adName);
        //d.pushObject(ad.adPlayStartDate)
        //d.pushObject(ad.adPlayEndDate)
        d.push("");
        d.push(ad.adColor);
        d.push(new Date(adkSessionAccount.getDate(ad.get('adPlayStartDate'), true)));
        d.push(new Date(adkSessionAccount.getDate(ad.get('adPlayEndDate'), true)));
        dataArray.push(d);
      });
      dataTable.addRows(dataArray);
      return dataTable;
    }),
    option: Ember.computed("data", function () {
      let data = this.get('data');
      let rowHeight = data.getNumberOfRows() * 40 + 80;
      let options = {
        height: rowHeight,
        width: "auto"
      };
      return options;
    }),
    actions: {
      isLabel: function (mediaType) {
        let curMediaType = this.get('curMediaType');
        if (mediaType != curMediaType) {
          this.set('curMediaType', mediaType);
          return true;
        }
        return false;
      }
    }
  });
});