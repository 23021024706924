define("adtikir3v1/pods/campaign/details/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    afterModel(model, transition) {
      this._super(model, transition);
      if (transition.targetName == "campaign.details.index") {
        this.get('router').transitionTo('campaign.details.dashboard');
      }
    }
  });
});