define("adtikir3v1/pods/campaign/details/financials/budget/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdsIndexRoute = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      // let mediaFormat = yield this.store.findAll('mediaFormat');
      let mediaTypes = yield this.store.findAll('mediaType');
      let budgetWorkFlow = yield this.store.findAll('budgetWorkFlow');
      // let adCampaignBudget = yield this.store.findAll('adCampaignBudget');
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
      let ads = yield this.store.query('ad', {
        filter: {
          query: adCampaign.id
        }
      });
      let adTotalMediatype = yield this.store.query('adTotalMediatype', {
        AdCampaignId: adCampaign.id
      });
      // let adCampaignBudget = yield this.store.query('adCampaignBudget', {
      //   filter: {
      //     query: adCampaign.id
      //   }
      // });

      let campaignId = adCampaign.id;
      let adCampaignBudget = yield this.store.findRecord('adCampaignBudget', campaignId);
      return {
        ads: ads,
        // mediaFormat: mediaFormat,
        adTotalMediatype: adTotalMediatype,
        budgetWorkFlow: budgetWorkFlow,
        adCampaignBudget: adCampaignBudget,
        mediaTypes: mediaTypes,
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail
      };
    } catch (e) {
      this.intermediateTransitionTo('error', e);
      throw e;
    }
  }).restartable(), (_class = class AdsIndexRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);
      _initializerDefineProperty(this, "loadModelTask", _descriptor3, this);
      _defineProperty(this, "actions", {
        refreshCurrentRoute() {
          this.refresh();
        }
      });
    }
    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    }
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('ads', model.ads);
      controller.set('mediaTypes', model.mediaTypes);
      controller.set('budgetWorkFlow', model.budgetWorkFlow);
      controller.set('adCampaignBudget', model.adCampaignBudget);
      // controller.set('mediaFormat', model.mediaFormat);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adTotalMediatype', model.adTotalMediatype);
    } //setupController
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadModelTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});