define("adtikir3v1/pods/components/adk-paging-select-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/j9cVL/1",
    "block": "{\"symbols\":[\"pageNo\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"totalPage\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layout-row flex-end adkCampaignNav\"],[8],[0,\"\\n    \"],[5,\"paper-button\",[[12,\"class\",\"adkCampaignArrows\"]],[[\"@raised\",\"@iconButton\",\"@accent\",\"@onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"prevPage\"],null]]],{\"statements\":[[0,\"\\n      <<<\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"paper-select\",[],[[\"@options\",\"@label\",\"@selected\",\"@onChange\"],[[22,\"pageArray\"],\"Page\",[28,\"inc\",[1,[24,[\"page\"]]],null],[28,\"action\",[[23,0,[]],\"setPage\"],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n    \"],[5,\"paper-button\",[[12,\"class\",\"adkCampaignArrows\"]],[[\"@raised\",\"@iconButton\",\"@accent\",\"@onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"nextPage\"],null]]],{\"statements\":[[0,\"\\n      >>>\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-paging-select-box/template.hbs"
    }
  });
});