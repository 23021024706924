define("adtikir3v1/pods/components/adkcard-campaign-new/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    campaignName: '',
    campaignNameLocal: '',
    clientRefId: '',
    campaignPic: '',
    campaignStartDate: '',
    campaignEndDate: '',
    productLaunchDate: '',
    currency: '',
    selectedGraphic: '',
    // classNames: this.get('fieldname'),

    resetForm: function () {
      this.set('campaignName', '');
      this.set('campaignNameLocal', '');
      this.set('clientRefId', '');
      this.set('campaignPic', '');
      let todayDate = (0, _moment.default)(this.get('adkSessionAccount').get('myToday'));
      this.set('campaignStartDate', todayDate); //initial start date to today...
      this.set('campaignStartDateLocal', todayDate); //initial centerStart to today also
      this.set('centerStartLocal', todayDate); //initial centerStart to today also
      this.set('campaignEndDate', '');
      this.set('campaignEndDateLocal', '');
      this.set('productLaunchDate', '');
      this.set('productLaunchDateLocal', '');
      this.set('currency', '');
      this.set('selectedGraphic', '');
    },
    didReceiveAttrs() {
      this.resetForm();
    },
    actions: {
      // Soon - This function is called from component - adk-imageselector to set the selected graphicImage, index

      selectCampaignImage: function (campaignImage) {
        this.set('selectedGraphic', campaignImage.campaignImageURL);
      },
      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let newDate = selected.moment;
        let startDate = this.get('campaignStartDateLocal'); //assume is a moment obj
        let endDate = this.get('campaignEndDateLocal'); //assume is a moment obj
        let productLaunchDate = this.get('productLaunchDateLocal');
        if (theField != 'campaignEndDateLocal') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareDate(newDate, endDate) >= 0) {
              newDate = '';
              adkToast.display("Date should not be later than the end date, it will not be set", "warning", 2000);
            }
          } //endDate != ''
        }

        //cannot put else.. cause productLaunchDate need check both

        if (theField != 'campaignStartDateLocal') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareDate(newDate, startDate) <= 0) {
              newDate = '';
              adkToast.display("Date should not be earlier than the start date, it will not be set", "warning", 2000);
            }
          } //startdate != ''
        }
        if (newDate != '') {
          // this check if user modified the start date or end date.. 
          // but product launch date not in range
          //need check productLunchDate <= startDate
          //if so set to '';
          if (theField == 'campaignStartDateLocal' && productLaunchDate != '') {
            if (_adkDateTime.default.compareDate(productLaunchDate, newDate) <= 0) {
              this.set('productLaunchDateLocal', '');
              this.set('productLaunchDate', '');
              this.set('centerProductLaunchLocal', newDate);
              adkToast.display("Product launch date is not in range, will reset it", "warning", 2000);
            }
          } else
            //need check productLunchDate >= endDate
            if (theField == 'campaignEndDateLocal' && productLaunchDate != '') {
              if (_adkDateTime.default.compareDate(productLaunchDate, newDate) >= 0) {
                this.set('productLaunchDateLocal', '');
                this.set('productLaunchDate', '');
                this.set('centerProductLaunchLocal', newDate);
                adkToast.display("Product launch date is not in range, will reset it", "warning", 2000);
              }
            }
          this.set(theField, newDate);
          //need to update the actual date..convert the date to company timezoe from local computer timezone
          let theActualField = theField.substring(0, theField.length - 5);
          // console.log('kel: ', theField)
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theActualField, (0, _moment.default)(new Date(newDateStr)));
        } // newDate != ''
        // console.log(arguments);
      },
      // updateDate()

      checkCenter(theField, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let startDate = this.get('campaignStartDateLocal'); //assume is moment
        let endDate = this.get('campaignEndDateLocal'); //assume is moment
        // let productLaunchDate = this.get('productLaunchDate');

        if (theField != 'campaignEndDateLocal') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
              newCenterDate = endDate;
            }
          }
        }
        if (theField != 'campaignStartDateLocal') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
              newCenterDate = startDate;
            }
          }
        }
        this.set(centerField, newCenterDate);
      },
      //checkCenter()

      saveCampaign() {
        let adkToast = this.get('adkToast');
        if (this.get('campaignStartDate') == '') {
          adkToast.display("Please select a " + this.intl.t("campaign") + " start date", "error");
          return;
        }
        if (this.get('productLaunchDate') == '') {
          adkToast.display("Please select the product launch date", "error");
          return;
        }
        if (this.get('campaignEndDate') == '') {
          adkToast.display("Please select a " + this.intl.t("campaign") + " end date", "error");
          return;
        }

        //Check whether a graphic is selected
        if (this.get('selectedGraphic') == '') {
          adkToast.display("Please select a " + this.intl.t("campaign") + " photo", "error");
          return;
        }
        this.cntrlSaveCampaign({
          campaignName: this.get('campaignName'),
          campaignNameLocal: this.get('campaignNameLocal'),
          clientRefId: this.get('clientRefId'),
          campaignPic: this.get('selectedGraphic'),
          campaignStartDate: this.get('campaignStartDate').toDate(),
          campaignEndDate: this.get('campaignEndDate').toDate(),
          campaignProductLaunchDate: this.get('productLaunchDate').toDate()
        });
      },
      cancelSaveCampaign: function () {
        this.cntrlCancelSaveCampaign();
      }
    }
  });
});