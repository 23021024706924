define("adtikir3v1/pods/campaign/details/ads/ad/vendors/adclientbrief/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let model = yield this.modelFor('campaign.details.ads.ad');
      // let mediaTypes = yield this.store.findAll('mediaType');

      let targeting = {};
      let locations = [];
      let mediaType = null;
      let mediaFormat = null;
      if (model.adCampaign) {
        targeting = yield this.store.query('targeting', {
          adCampaign: model.adCampaign.id
        });
        targeting = targeting.firstObject;
        locations = yield this.store.query('location', {
          adCampaignTargeting: model.adCampaign.id
        });
        if (locations.length > 0) {
          locations = locations.toArray();
        }
      } //if

      if (model.ad) {
        mediaType = yield this.store.findRecord('media-type', model.ad.mediatype);
        if (model.ad.mediaformat) {
          mediaFormat = yield this.store.findRecord('media-format', model.ad.mediaformat);
        }
      }
      return {
        ad: model.ad,
        adCampaign: model.adCampaign,
        adCampaignDetail: model.adCampaignDetail,
        targeting: targeting,
        locations: locations,
        mediaType: mediaType,
        mediaFormat: mediaFormat
      };
    }).restartable(),
    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('ad', model.ad);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('targeting', model.targeting);
      controller.set('locations', model.locations);
      controller.set('mediaType', model.mediaType);
      controller.set('mediaFormat', model.mediaFormat);
      controller.cleanUp();
    } //setupController
  });
});