define("adtikir3v1/pods/profile/company/agency-media/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { A } from '@ember/array';
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
    },
    //init

    loadMyVendorClientPortfolioTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let myVendorClientPortfolios = yield this.store.query('my-vendor-client-portfolio', {
        page: page,
        pageSize: pagingObj.pageSize
      });
      // if (myVendorClientPortfolios.meta && myVendorClientPortfolios.meta.page && myVendorClientPortfolios.meta.page.total) {
      //   set(pagingObj, "total", myVendorClientPortfolios.meta.page.total);
      // }
      Ember.set(pagingObj, "result", myVendorClientPortfolios);
    }).restartable(),
    updateProfileTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let vendor = this.get('myVendor');
      try {
        yield vendor.save();
        adkToast.display("Data saved successfully");
        this.set('editMode', false);
      } catch (e) {
        adkToast.display("Failed to update");
      }
    }),
    formClear: function () {
      this.set('title', '');
      this.set('description', '');
      this.set('name', '');
      this.set('position', '');
      this.set('link', '');
    },
    actions: {
      selectedMediaType(mediatype) {
        this.set('selectedMediaType', mediatype);
      },
      actionToEditMode() {
        this.set('editMode', true);
        let myVendor = this.get('myVendor');
        myVendor.startTrack();
      },
      actionCancelEditMode() {
        let myVendor = this.get('myVendor');
        if (myVendor.get('hasDirtyAttributes')) {
          myVendor.rollbackAttributes();
        }
        myVendor.rollback();
        this.set("selectedLanguages", []);
        this.set("removedLanguages", []);
        this.set('editMode', false);
      },
      deleteClientPortfolio(clientPortfolio) {
        let adkToast = this.get('adkToast');
        return clientPortfolio.destroyRecord().then(function () {
          adkToast.display("Client portfolio deleted successfully", "warning", 3000);
          return true;
        }, function () {
          if (clientPortfolio.get('hasDirtyAttributes')) {
            clientPortfolio.rollbackAttributes();
          }
          adkToast.display("Fail to delete", "warning", 3000);
          return false;
        });
      },
      updateClientPortfolio(clientPortfolio) {
        let adkToast = this.get('adkToast');
        clientPortfolio.save().then(() => {
          adkToast.display("Client portfolio updated successfully");
        }, function () {
          if (clientPortfolio.get('hasDirtyAttributes')) {
            clientPortfolio.rollbackAttributes();
          }
          adkToast.display("Failed to update client portfolio", "warning", 3000);
          return false;
        });
      },
      addNewClientPortfolio() {
        this.set("showAddClientPorfolioBox", true);
      },
      cancelAddClientPortfolio() {
        this.set("showAddClientPorfolioBox", false);
        this.formClear();
      },
      saveNewClientPortfolio(componentValues) {
        let adkToast = this.get('adkToast');
        let record = this.store.createRecord('myVendorClientPortfolio', {
          description: componentValues.description,
          name: componentValues.name,
          position: componentValues.position,
          title: componentValues.title,
          link: componentValues.link,
          pic: componentValues.pic
        });
        return record.save().then(function () {
          adkToast.display("Client portfolio added successfully");
          return true;
        }, function () {
          if (record.get('hasDirtyAttributes')) {
            record.rollbackAttributes();
          }
          adkToast.display("Failed to add client portfolio", "error");
          return false;
        });
      },
      addNewMediaDetail() {
        this.set('showAddMediaDetail', true);
      },
      cancelEdit() {
        this.set('showAddMediaDetail', false);
      }
    } //actions
  });
});