define("adtikir3v1/pods/campaign/details/targeting/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    removeList: Ember.A(),
    init() {
      this._super(...arguments);
      this.set("editDemographicsMode", false);
      this.set("editAdvancedMode", false);
    },
    adkToast: Ember.inject.service(),
    saveDemographicsTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let adCampaign = this.get('adCampaign');
      let adCampaignDetail = this.get('adCampaignDetail');
      let targeting = this.get('targeting');
      let locationList = this.get('locationList');
      let removeList = this.get('removeList');
      let parentId = adCampaign.id;
      let parentField = "adCampaignTargeting";
      try {
        for (let i = 0; i < locationList.length; i++) {
          if (locationList[i].adkNewFlag) {
            let location = this.store.createRecord('location', {
              parentId: parentId,
              parentField: parentField,
              locationableType: locationList[i].constructor.modelName,
              LocationableId: locationList[i].id
            });
            yield location.save();
            locationList[i].set('adkNewFlag', false);
          }
        }
        for (let i = removeList.length - 1; i >= 0; i--) {
          let id = parentField + '!!!' + parentId + '!!!' + removeList[i].constructor.modelName + '!!!' + removeList[i].id;
          let record = yield this.store.findRecord('location', id);
          yield record.destroyRecord();
          removeList.removeObject(removeList[i]);
        }
        yield adCampaignDetail.save();
        yield targeting.save();
        adCampaignDetail.reload();
        adCampaign.reload();
        adkToast.display("Data saved successfully");
        this.set('editDemographicsMode', false);
      } catch (e) {
        adkToast.display("Failed to save", "error");
      }
    }),
    saveAdvancedTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let targeting = this.get('targeting');
      let adCampaign = this.get('adCampaign');
      let adCampaignDetail = this.get('adCampaignDetail');
      if (targeting.targetIndustry == false) {
        adCampaignDetail.targetIndustrySelection.clear();
      }
      try {
        yield adCampaignDetail.save();
        yield targeting.save();
        adCampaignDetail.reload();
        adCampaign.reload();
        adkToast.display("Data saved successfully");
        this.set('editAdvancedMode', false);
      } catch (e) {
        adkToast.display("Failed to save", "error");
      }
    }),
    actions: {
      cancelDemographicsAction: function () {
        let locationList = this.get('locationList');
        this.adCampaignDetail.rollback();
        if (this.targeting.get('hasDirtyAttributes')) {
          this.targeting.rollbackAttributes();
        }
        for (let i = 0; i < locationList.length; i++) {
          if (locationList[i].adkNewFlag) {
            locationList.removeObject(locationList[i]);
            locationList[i].set('adkNewFlag', false);
          }
        }
        this.set('editDemographicsMode', false);
      },
      //cancelDemographicsAction

      cancelAdvancedAction: function () {
        this.adCampaignDetail.rollback();
        if (this.targeting.get('hasDirtyAttributes')) {
          this.targeting.rollbackAttributes();
        }
        this.set('editAdvancedMode', false);
      },
      //cancelAdvancedAction

      incomeLevelUpdate(value) {
        value = !value;
        this.get('targeting').set('incomeLevel', value);
        if (value == false) {
          this.get('targeting').set('lowIncome', false);
          this.get('targeting').set('middleIncome', false);
          this.get('targeting').set('upperMiddleIncome', false);
          this.get('targeting').set('highIncome', false);
        }
      },
      //incomeLevelUpdate

      targetIndustrySelectionUpdate(value) {
        value = !value;
        let targeting = this.get('targeting');
        targeting.set('targetIndustry', value);
      },
      editDemographicsAction() {
        this.actions.cancelAdvancedAction.apply(this);
        let adCampaignDetail = this.get('adCampaignDetail');
        this.set('editDemographicsMode', true);

        // this.targeting.rollback will makes targeting.dirtyType = deleted
        if (!this.targeting || this.get('targeting').dirtyType == "deleted") {
          let targetingRecord = this.store.createRecord('targeting', {
            adCampaignDetail: adCampaignDetail
          });
          this.set("targeting", targetingRecord);
        }
      },
      editAdvancedAction() {
        this.actions.cancelDemographicsAction.apply(this);
        let adCampaignDetail = this.get('adCampaignDetail');
        this.set('editAdvancedMode', true);
        if (!this.targeting || this.get('targeting').dirtyType == "deleted") {
          let targetingRecord = this.store.createRecord('targeting', {
            adCampaignDetail: adCampaignDetail
          });
          this.set("targeting", targetingRecord);
        }
      }
    } //action
  });
});