define("adtikir3v1/pods/components/adk-ad-media-proposal/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    isOpen: false,
    //Variables
    adName: '',
    advertiser: '',
    editMode: Ember.computed('vAdMediaProposal.status', 'adMediaProposalInvitedExipred', function () {
      let vAdMediaProposal = this.get('vAdMediaProposal');
      if (this.adMediaProposalInvitedExipred) {
        return false;
      }
      if (vAdMediaProposal.status === "new") {
        return true;
      }
      return false;
    }),
    didReceiveAttrs() {
      //this is hack for aProposalResponse

      if (this.get('aAdMediaProposal')) {
        this.set('vAdMediaProposal', this.get('aAdMediaProposal'));
        this.set('aAdMediaProposalType', true);
      } else {
        this.set('aAdMediaProposalType', false);
      }
      let vAdMediaProposal = this.get('vAdMediaProposal');
      this.set('adRfp', vAdMediaProposal.get('adRfp'));
      this.set('adMediaRfpBody', vAdMediaProposal.get('adMediaRfpBody'));
      this.set('refresh', vAdMediaProposal.id);
      this.set('openedTarget', null);
      if (vAdMediaProposal.status === "new") {
        let currencyAccept = this.get('adRfp').get('currencyAccept');
        let currencyOption = currencyAccept.split(',');
        this.set('currencyOption', currencyOption);
      }
      this.set('adMediaProposalItemList', {
        page: 0,
        pageSize: 20,
        //set it very big
        action: this.get("loadAdMediaProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('showMediaVendorDetailId', false);
      if (!('adMediaProposalInvitedExipred' in this)) {
        this.set('adMediaProposalInvitedExipred', false);
      }
    },
    //didReceiveAttrs

    loadAdMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };
      if (this.aAdMediaProposalType) {
        let aAdMediaProposalId = this.get('aAdMediaProposal').id;
        findObj.aAdMediaProposal = aAdMediaProposalId;
      } else {
        let vAdMediaProposalId = this.get('vAdMediaProposal').id;
        findObj.vAdMediaProposal = vAdMediaProposalId;
      }
      let attachments = yield this.store.query('ad-media-proposal-attachment', findObj);

      // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }
      Ember.set(pagingObj, "result", attachments);
      // if (pagingObj.refresh) { 
      //     this.set('refresh', false); 
      // }
    }).restartable(),
    loadAdMediaProposalItemTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };
      if (this.aAdMediaProposalType) {
        let aAdMediaProposalId = this.get('aAdMediaProposal').id;
        findObj.aAdMediaProposal = aAdMediaProposalId;
      } else {
        let vAdMediaProposalId = this.get('vAdMediaProposal').id;
        findObj.vAdMediaProposal = vAdMediaProposalId;
      }
      let adMediaProposalItems = yield this.store.query('ad-media-proposal-item', findObj);
      // if (adMediaProposalItems.meta && adMediaProposalItems.meta.page && adMediaProposalItems.meta.page.total) {
      //   set(pagingObj, "total", adMediaProposalItems.meta.page.total);
      // }

      Ember.set(pagingObj, "result", adMediaProposalItems);
    }),
    submitAdkAdMediaProposalTask: (0, _emberConcurrency.task)(function* (event) {
      // event.preventDefault();
      let target = event.target;
      if (this.isOpen) {
        alert("A field is opened, please close it first");
        this.set('openedTarget', target.parentNode.parentNode.parentNode);
        return;
      }

      // let component = this;
      let adkToast = this.get('adkToast');
      let vAdMediaProposal = this.get('vAdMediaProposal');
      vAdMediaProposal.set('status', 'vSubmitted');
      yield this.saveProposal('submitted');
      this.set('showDialogWithParent', false);
      this.set('editMode', false);
      adkToast.display("Proposal submitted successfully.", "warning", 3000);
      //need to save invitee to submitted....
    }),
    proposalExpiredDateComputed: Ember.computed('vAdMediaProposal.responseExpiredDayCount', function () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adMediaRfpBody = this.get('adMediaRfpBody');
      let vAdMediaProposal = this.get('vAdMediaProposal');
      let responseExpiredDayCount = vAdMediaProposal.get('responseExpiredDayCount');
      let validDate = (0, _moment.default)(adMediaRfpBody.get('proposalDeadline')).add(responseExpiredDayCount, 'days');
      //let dateObj = new Date(adkSessionAccount.getTime());
      let rtnDate = new Date(validDate);
      return rtnDate;
    }),
    actions: {
      toggleEdit(field, even) {
        let fieldAllow = ['projectObjectives', 'ourUnderstanding', 'aboutUs', 'description',
        // 'ourMethodology',
        // 'discovery',
        // 'visualize',
        'implementation', 'currency', 'ourTeam', 'ourResults', 'cost', 'timelines', 'terms', 'responseExpiredDate', 'taxIP', 'finalNote', 'proposalNote'];
        //check if allow to edit
        if (!this.editMode) {
          return true;
        }
        let idx = fieldAllow.indexOf(field);
        if (idx != -1) {
          if (this.isOpen) {
            alert("Field is opened, please close it first");
            this.openedTarget.scrollIntoView();
            return;
          }
          //this.set('isOpen', true);
          this.toggleProperty('isEdit' + fieldAllow[idx]);
          if (this.get('isEdit' + fieldAllow[idx])) {
            this.set('isOpen', field);
            let target = even.currentTarget;
            this.set('openedTarget', target.parentNode.parentNode.parentNode);
            if (field === 'cost') {
              this.set('editorIsOpen', false);
            }
          } else {
            this.set('isOpen', false);
            this.set('openedTarget', null);
          }
          return;
        }
        this.toggleProperty('isEditing');
      },
      //toggleEdit

      openDialogWithParent(event) {
        if (this.isOpen) {
          alert("A field is opened, please close it first");
          return;
        }
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },
      closeDialogWithParent() {
        // this.set('result', result);
        this.set('showDialogWithParent', false);
        //this.actions.cancelAction();
      },
      toggleProjObjectives() {
        this.toggleProperty('isEditProjectObjectives');
      },
      cancelAction(field) {
        let adkToast = this.get('adkToast');
        let vAdMediaProposal = this.get('vAdMediaProposal');
        if (field === 'cost') {
          if (this.get('editorIsOpen')) {
            alert('The editor form is opened, please close it first');
            return false;
          }
          vAdMediaProposal.reload();
          adkToast.display("Editor closed", "warning");
        } else {
          if (vAdMediaProposal.get('hasDirtyAttributes')) {
            vAdMediaProposal.rollbackAttributes();
          }
          adkToast.display("Action cancel", "warning");
        } //else
        this.set('isOpen', false);
        this.actions.toggleEdit.call(this, field);
      },
      saveAdkAdMediaProposal(field) {
        // event.preventDefault();
        let component = this;
        let adkToast = this.get('adkToast');
        let vAdMediaProposal = this.get('vAdMediaProposal');
        if (field === "responseExpiredDate") {
          vAdMediaProposal.set("responseExpiredDate", this.get('proposalExpiredDateComputed'));
        }
        this.saveProposal().then(function () {
          component.set('isOpen', false);
          component.actions.toggleEdit.call(component, field);
          adkToast.display("Proposal successfully saved.", "warning");
        });
      },
      showVendorAdMediaDetailAction: function (vendorAdMediaDetailId, event) {
        event.preventDefault();
        if (this.showAdMediaVendorDetailId) {
          alert('Other detail already opened... please close it first');
          return false;
        }
        Ember.set(this, 'showAdMediaVendorDetailId', vendorAdMediaDetailId);
        //set(this,'showVendorMediaDetailFlag',true);
      },
      closeVendorAdMediaDetailAction: function (event) {
        event.preventDefault();
        Ember.set(this, 'showAdMediaVendorDetailId', false);
        //set(this,'showVendorMediaDetailFlag',false);
      },
      nothing() {
        // Do nothing. Used just to highlight sections.
      }
    }
  });
});