define("adtikir3v1/pods/components/adk-select-industry-type/component", ["exports", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin"], function (_exports, _childMixin, _validationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { or } from '@ember/object/computed';
  var _default = _exports.default = Ember.Component.extend(_childMixin.default, _validationMixin.default, {
    init() {
      this._super();
      this.set("isPop", false);
      this.set('filterStr', '');
      this.set('firstTime', true);
      if (!this.get('selectedIndustry')) {
        this.set('selectedIndustry', []);
      }
    },
    tagName: "md-input-container",
    validationProperty: 'selectedIndustry',
    isInvalid: Ember.computed("selectedIndustry.length", function () {
      let component = this;
      let selectedIndustry = this.get('selectedIndustry');
      if (Array.isArray(selectedIndustry) && selectedIndustry.length > 0) {
        Ember.run.once(component, "set", 'firstTime', false);
        return false;
      }
      if (this.firstTime) {
        return false;
      }
      return true;
    }),
    classNameBindings: ['isInvalid:md-input-invalid'],
    willInsertElement: function () {
      this.set("emberId", this.get('elementId'));
    },
    remainArray: Ember.computed("industryType.@each.{description,subIndustry}", "selectedIndustry.[]", function () {
      let industryType = this.get('industryType');
      let selectedIndustry = this.get('selectedIndustry');
      let result = industryType;
      if (selectedIndustry && selectedIndustry.length > 0) {
        result = industryType.filter(function (item) {
          for (let i = 0; i < selectedIndustry.length; i++) {
            if (selectedIndustry[i].id == item.id) {
              return false;
            }
          } //for
          return true;
        });
      } //if
      return result;
    }),
    filterArray: Ember.computed("filterStr", "remainArray.@each.{description,subIndustry}", function () {
      let filterStr = this.get('filterStr');
      let remainArray = this.get('remainArray');
      if (filterStr) {
        let result = remainArray.filter(function (item) {
          if (item.get('subIndustry').toLowerCase().search(filterStr.toLowerCase()) >= 0 || item.get('description').toLowerCase().search(filterStr.toLowerCase()) >= 0) {
            return item;
          }
        });
        return result;
      }
      return remainArray;
    }),
    willClearRender() {
      this.destroy();
    },
    actions: {
      openDialog(ev) {
        if (this.get('isPop')) {
          return;
          //clear string b4 pop up
        }
        this.set('filterStr', '');
        this.set('dialogOrigin', ev.currentTarget);
        this.set("isPop", true);
      },
      resetFilterStr() {
        this.set("filterStr", '');
      },
      closeDialogWithParent() {
        this.set("isPop", false);
      }
    }
  });
});