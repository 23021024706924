define("adtikir3v1/pods/campaign/details/ads/ad/vendors/media/proposal/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { capitalize } from '@ember/string';
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    intl: Ember.inject.service(),
    showAwardButtonFlag: Ember.computed('ad', 'ad.workFlowStatusNumber', 'adMediaProposal', 'adMediaProposal.status', 'showAwardEditFlag', function () {
      let fg = false;
      //let adCampaign = this.get('adCampaign');
      let adMediaProposal = this.get('adMediaProposal');
      let showAwardEditFlag = this.get('showAwardEditFlag');
      let ad = this.get('ad');

      // work flow number not in 2 to 6 ....
      // 2 is approved plan
      // 7 and 8 is  completed or canceled
      if (ad.workFlowStatusNumber < 2 || ad.workFlowStatusNumber >= 7) {
        return false;
      }
      if (adMediaProposal && adMediaProposal.get('status') === "vSubmitted") {
        //need check date as well for expiry
        let responseExpiredDate = (0, _moment.default)(adMediaProposal.get('responseExpiredDate'));
        if (responseExpiredDate.diff(this.adkSessionAccount.get('myNow')) > 0) {
          fg = true;
        } //if
      } //if 
      if (showAwardEditFlag) {
        fg = false;
      }
      return fg;
    }),
    showAwardedInfoFg: Ember.computed('adMediaProposal.status', function () {
      let adMediaProposal = this.get('adMediaProposal');
      let flaseArray = ['new', 'vSubmitted', 'expired'];
      if (flaseArray.indexOf(adMediaProposal.status) >= 0) {
        return false;
      }
      return true;
    }),
    showAcceptInfoFg: Ember.computed('adMediaProposal.status', function () {
      let adMediaProposal = this.get('adMediaProposal');
      let flaseArray = ['vAccept', 'vReject', 'vAcceptExpired', 'project', 'projectReject'];
      if (flaseArray.indexOf(adMediaProposal.status) >= 0) {
        return true;
      }
      return false;
    }),
    showCreateRatingButtonFlag: Ember.computed("rateDisplay", "userAdMediaProposalRating", 'adMediaProposal', 'adMediaProposal.status', function () {
      let fg = false;
      let userAdMediaProposalRating = this.get('userAdMediaProposalRating');
      let adMediaProposal = this.get('adMediaProposal');
      let rateDisplay = this.get('rateDisplay');
      if (!userAdMediaProposalRating) {
        if (adMediaProposal.status === "vSubmitted") {
          if (rateDisplay) {
            fg = true;
          }
        }
      }
      return fg;
    }),
    //expirarion date for the award... vendor needed to reponse before this date, 
    //otherwise, statuse will turn to awareExpired
    awardExpiredDateComputed: Ember.computed('awardExpiredDayCount', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let awardExpiredDayCount = this.get('awardExpiredDayCount');
      let dateObj = new Date(adkSessionAccount.get('myNow'));
      dateObj.setDate(dateObj.getDate() + awardExpiredDayCount + 1);
      dateObj = (0, _moment.default)(dateObj);
      let newDateStr = dateObj.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
      let rtnDate = new Date(newDateStr);
      return rtnDate;
    }),
    loadAdMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfpId = this.get('adRfp').id;
      let adMediaRfpBodies = yield this.store.query('ad-media-rfp-body', {
        adRfp: adRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adMediaRfpBodies);
      //return attachments;
    }),
    //loadAdMediaRfpBodyListTask

    loadAdMediaProposalListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adMediaRfpBody = this.get('adMediaRfpBody');
      let result = yield this.store.query('a-ad-media-proposal', {
        adMediaRfpBody: adMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadAdMediaProposalRatingListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adMediaProposal = this.get('adMediaProposal');
      let result = yield this.store.query('ad-media-proposal-rating', {
        aAdMediaProposal: adMediaProposal.id,
        // byUser: false,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadUserAdMediaProposalRatingTask: (0, _emberConcurrency.task)(function* () {
      let adMediaProposal = this.get('adMediaProposal');
      let result = yield this.store.query('ad-media-proposal-rating', {
        aAdMediaProposal: adMediaProposal.id,
        byUser: true,
        page: 0,
        pageSize: 1
      });
      Ember.set(this, 'userAdMediaProposalRating', result.get('firstObject'));
      if (this.userAdMediaProposalRating) {
        this.set('rateDisplay', true);
      }
    }),
    cleanAdMediaProposal(controller = null) {
      if (!controller) {
        controller = this;
      }
      controller.set('rateDisplay', true);
      controller.set('adMediaProposalRatingList', {
        page: 0,
        pageSize: 20,
        action: this.get("loadAdMediaProposalRatingListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      controller.set('adMediaProposal', null);
      controller.set('userAdMediaProposalRating', null);
      controller.set('showAwardFlag', false);
      controller.set('showCancelAwardAction', false);
      controller.set('awardCancelReason', '');
      controller.set('finalNote', '');
      controller.set('showConfirmProjectDialogFlag', false);
      controller.cleanAwardEdit(controller);
    },
    cleanAwardEdit(controller = null) {
      //clean the award form seciton
      //will be called by cleanAProposalUp..
      if (!controller) {
        controller = this;
      }
      controller.set('showAwardEditFlag', false);
      controller.set('awardExpiredDayCount', 3);
      controller.set('awardedNote', '');
    },
    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('adMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('starWidth', 12);
      this.set('starHeight', 12);
      this.cleanAdMediaProposal(this);
    },
    init() {
      this._super(...arguments);
    },
    //init

    saveAwardEditActionTask: (0, _emberConcurrency.task)(function* () {
      let adMediaProposal = this.get('adMediaProposal');
      let adkToast = this.get('adkToast');
      adMediaProposal.set('action', "awardAction");
      let ad = this.get('ad');

      // work flow number not in 2 to 6 ....
      // 2 is approved plan
      // 7 and 8 is  completed or canceled
      if (ad.workFlowStatusNumber < 2 || ad.workFlowStatusNumber >= 7) {
        adkToast.display("The " + this.intl.t("ad") + " is not in the right stage to run this action", "error", 3000);
        return false;
      }
      if (adMediaProposal.isForeignCurrency) {
        adMediaProposal.set('finalCurrencyRate', adMediaProposal.exchangeRate);
        adMediaProposal.set('finalCurrencyRateDate', adMediaProposal.get('exchangeRateDate'));
      }
      adMediaProposal.set('awardedNote', this.get('awardedNote'));
      adMediaProposal.set('awardedDate', this.get('adkSessionAccount').get('myNow').toDate());
      adMediaProposal.set('awardExpiredDate', this.get('awardExpiredDateComputed'));

      //need popup for confirmation....

      yield adMediaProposal.save();
      this.cleanAwardEdit();
      //reload adCampaign for 
      //yield this.get('adCampaign').reload();
      adkToast.display('Proposal awarded', 'warning', 3000);
    }),
    saveCancelAwardActionTask: (0, _emberConcurrency.task)(function* () {
      let adMediaProposal = this.get('adMediaProposal');
      adMediaProposal.set('action', "awardCancelAction");
      adMediaProposal.set('awardResponseMessage', this.get('awardCancelMessage'));

      //need popup for confirmation....
      yield adMediaProposal.save();
      this.set('showAwardEditFlag', true);
      this.set('awardCancelReason', '');
      //reload adCampaign for 
      yield this.get('ad').reload();
      this.get('adkToast').display('Proposal canceled', 'warning', 1500);
    }),
    saveResponseActionTask: (0, _emberConcurrency.task)(function* (action) {
      //action should be projectAcceptAction or projectRejectAction

      let adkToast = this.get('adkToast');
      let adMediaProposal = this.get('adMediaProposal');
      if (this.get('finalNote').length === 0) {
        adkToast.display("Pleae enter some values", "error");
        return;
      }
      adMediaProposal.set('action', action);
      adMediaProposal.set('finalNote', this.get('finalNote'));
      yield adMediaProposal.save();
      //reload adCampaign for 
      yield this.get('ad').reload();
      this.set('showConfirmProjectDialogFlag', false);
      this.set('finalNote', '');
      this.get('adkToast').display('Proposal Updated', 'warning', 1500);
    }),
    //saveResponseActionTask

    setAdMediaProposalTask: (0, _emberConcurrency.task)(function* (adMediaProposal) {
      // let router = this.get('router');
      this.cleanAdMediaProposal(this);
      adMediaProposal.reload();

      // if (adMediaProposal.status === "project") {
      //   router.transitionTo('campaign.details.ads.ad.vendor.media.accepted',
      //     this.adCampaign.id, {
      //       queryParams: {
      //         adMediaProposal: adMediaProposal.id
      //       }
      //     }
      //   );
      // }

      let adRfp = this.get('adRfp');
      if (adMediaProposal.quotedCurrency === adRfp.foreignCurrency) {
        adMediaProposal.set('isForeignCurrency', true);
        adMediaProposal.set('localCurrency', adRfp.localCurrency);

        //ok.. is using foreignCurrency...
        if (adMediaProposal.status === 'vSubmitted' || adMediaProposal.status === 'expired') {
          let currencyId = adRfp.foreignCurrency + 'to' + adRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          adMediaProposal.set('exchangeRate', currencyRate.exchangeRate);
          adMediaProposal.set('exchangeRateDate', currencyRate.exchangeDate);
        } else {
          adMediaProposal.set('exchangeRate', adMediaProposal.finalCurrencyRate);
          adMediaProposal.set('exchangeRateDate', adMediaProposal.finalCurrencyRateDate);
        }
        adMediaProposal;
      }
      this.set('adMediaProposal', adMediaProposal);

      //let pagingObj = this.get('adMediaProposalRatingList');
      if (!this.rateDisplay) {
        this.actions.cancelRating.call(this);
      }
      //this.set('AdMediaProposalRatingListRefresh', true);
      //set(pagingObj, "refresh", true);

      //set(this, "refresh", adMediaProposal.id);
      //set(pagingObj, "actionInstance", pagingObj.action.perform(pagingObj.page, pagingObj));

      this.set('loadUserAdMediaProposalRatingTaskInstant', this.loadUserAdMediaProposalRatingTask.perform());
    }),
    //setAdMediaProposalTask

    saveRatingTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let saveMode;
      let userAdMediaProposalRating = this.get('userAdMediaProposalRating');
      let changeAttributes = userAdMediaProposalRating.changedAttributes();
      if (Object.keys(changeAttributes).length === 0) {
        adkToast.display("Please enter some values", "error");
        return;
      }
      if (userAdMediaProposalRating.isNew) {
        saveMode = 'new';
        userAdMediaProposalRating.set('aAdMediaProposal', this.get('adMediaProposal'));
      }
      yield userAdMediaProposalRating.save();
      if (saveMode === 'new') {
        adkToast.display("Your new rating has been created", "warning");
      } else {
        adkToast.display("Your rating get updated", "warning");
      }
      //controller.set('createRatingOn', false);
      this.set('rateDisplay', true);
      Ember.set(this.get('adMediaProposalRatingList'), 'refresh', true);
      Ember.set(this, "refresh", userAdMediaProposalRating.id);
    }),
    actions: {
      setAdMediaRfpBody(adMediaRfpBody) {
        this.set('adMediaRfpBody', adMediaRfpBody);
        this.set('adMediaProposalList', {
          page: 0,
          pageSize: 6,
          action: this.get("loadAdMediaProposalListTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.cleanAdMediaProposal(this);
      },
      cancelCancelAwardAction() {
        this.set('showAwardEditFlag', false);
        this.set('awardCancelReason', '');
      },
      showConfirmProjectDialog(action) {
        this.set('showConfirmProjectDialogFlag', true);
        this.set('projectAction', action);
      },
      cancelConfirmProjectAction() {
        this.set('showConfirmProjectDialogFlag', false);
        this.set('projectAction', '');
        this.set('finalNote', '');
      },
      showEditAwardProposal() {
        this.set('showAwardEditFlag', true);
      },
      showCancelAwardAction() {
        this.set('showCancelAwardAction', true);
        //need set this to cancel
      },
      cancelAwardEditAction() {
        this.cleanAwardEdit();
      },
      setCreateRatingOn() {
        //this.set('createRatingOn', true);
        this.set('userAdMediaProposalRating', this.store.createRecord('ad-media-proposal-rating'));
        this.set('rateDisplay', false);
      },
      setModifyRatingOn() {
        this.set('rateDisplay', false);
      },
      cancelRating() {
        let userAdMediaProposalRating = this.get('userAdMediaProposalRating');
        if (userAdMediaProposalRating.isNew) {
          this.set('userAdMediaProposalRating', null);
        } else {
          if (userAdMediaProposalRating.get('hasDirtyAttributes')) {
            userAdMediaProposalRating.rollbackAttributes();
          }
        }
        //this.set('createRatingOn', false);
        this.set('rateDisplay', true);
      }
    } //actions
  });
});