define("adtikir3v1/pods/components/adkcard-dashboard-textfield/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    // classNames: this.get('fieldname'),

    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        emberId: this.elementId,
        options: {}
      });
    },
    resetForm: function () {
      this.set("adcampaignDetail", "");
      // this.set('model', '');
    },
    actions: {
      openDialogWithParent(event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set("showDialogWithParent", true);
      },
      closeDialogWithParent() {
        // this.set('result', result);
        this.set("showDialogWithParent", false);
        this.cancelAction();
      },
      editSubmit() {
        // var ctrl = this;
        // let adcampaigndetail = this.get('adCampaignDetail');
        this.set("showDialogWithParent", false);
        this.saveDetailAction();
        // this.updateAdCampaignDetail();
        // adcampaigndetail.save().then(onEditOK,onEditFailed);

        // function onEditOK() {
        //   let adkToast = ctrl.get('adkToast');

        //   // ctrl.resetForm();
        //   adkToast.display("Template Description successfully updated", "warning");
        //   ctrl.set('showDialogWithParent', false);
        // };

        // function onEditFailed(){
        //   //originally was ctrl-dashboard-description and its shows error
        //   let adkToast = ctrl.get('adkToast');
        //   adkToast.display("Template Description not saved", "error");
        //   ctrl.set('showDialogWithParent', false);
        // }
      },
      openNewAI(event) {
        let screenWidth = screen.availWidth;
        let screenHeight = screen.availHeight;
        //let openwin;

        let windowSize = {
          width: Math.ceil(screenWidth * 0.7),
          height: Math.ceil(screenHeight * 0.7)
        };
        let windowLocation = {
          left: window.screen.availLeft + window.screen.availWidth / 2 - windowSize.width / 2,
          top: window.screen.availTop + window.screen.availHeight / 2 - windowSize.height / 2
        };
        window.open("https://chat.openai.com", "aiwin", "height=" + windowSize.height + ",width=" + windowSize.width + ", \
       top=" + windowLocation.top + ", left=" + windowLocation.left + ", \
       resizable=yes, \
       toolbar=yes,menubar=yes, \
       location=no,directories=yes, \
       alwaysRaised=yes, status=yes");
      }
    } //actions
  });
});