define("adtikir3v1/pods/components/adk-companyinfo-dateformat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super();
      this.dateSeparatorOptions = ["-", "/"];
      this.currentTime = new Date();
    },
    dateSeperator: Ember.computed("dateFormat", {
      get(key) {
        let dateFormat = this.get('dateFormat');
        if (dateFormat.charAt(0) == "Y") {
          return dateFormat.charAt(4);
        }
        return dateFormat.charAt(2);
      },
      set(key, value) {
        let dateFormat = this.get("dateFormat");
        let oldSeperator = 2;
        if (dateFormat.charAt(0) == "Y") {
          oldSeperator = 4;
        }
        oldSeperator = dateFormat.charAt(oldSeperator);
        this.set('dateFormat', dateFormat.split(oldSeperator).join(value));
        return value;
      }
    }),
    dateFormatOptions: Ember.computed("dateSeperator", function () {
      let dateSeperator = this.get('dateSeperator');
      let dateFormatOptions = [];
      dateFormatOptions[0] = "YYYY" + dateSeperator + "MM" + dateSeperator + "DD";
      dateFormatOptions[1] = "DD" + dateSeperator + "MM" + dateSeperator + "YYYY";
      dateFormatOptions[2] = "MM" + dateSeperator + "DD" + dateSeperator + "YYYY";
      return dateFormatOptions;
    }),
    actions: {
      openEditDateDialog(ev) {
        this.set('showEditDateDialog', true);
        this.set('dialogOrigin', ev.currentTarget);
      },
      closeEditDateDialog() {
        this.set('showEditDateDialog', false);
        this.cancelAction();
      },
      updateDate() {
        this.set('showEditDateDialog', false);
        this.saveAction();
      }
    }
  });
});