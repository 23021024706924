define("adtikir3v1/pods/components/adk-display-vendor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { once } from '@ember/runloop';
  var _default = _exports.default = Ember.Component.extend({

    // didRender() {
    //   once(this, function() {
    //     this.element.scrollIntoView();
    //   });
    // }
  });
});