define("adtikir3v1/models/ad-total-mediatype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    // belongsTo,
    // hasMany,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    //  mediatype:      attr('string'),
    //  totalAmount:    attr('number'),
    estTotal: attr('number'),
    plannedTotal: attr('number'),
    committedTotal: attr('number'),
    actualTotal: attr('number')
  });
});