define("adtikir3v1/models/target-language", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    language: attr('string'),
    languegeId: attr('string'),
    adCampaign: belongsTo('adCampaign'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});