define("adtikir3v1/pods/campaign/details/ads/ad/vendors/agency/proposal/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adRfpIsCreated = false;
      let model = yield this.modelFor('campaign.details.ads.ad');
      // let mediaTypes = yield this.store.findAll('mediaType');

      //let mediaTypes = yield this.store.findAll('mediaType');
      let adRfp = yield this.store.query('ad-rfp', {
        ad: model.ad.id
      });
      //adRfp shoould be 1 only 
      if (adRfp.length == 1) {
        adRfpIsCreated = true;
        adRfp = adRfp.firstObject;
      } else {
        adRfp = null;
      }
      return {
        ad: model.ad,
        adRfp: adRfp,
        adCampaign: model.adCampaign,
        adCampaignDetail: model.adCampaignDetail,
        adRfpIsCreated: adRfpIsCreated

        //mediaTypes: mediaTypes,
      };
    }).restartable(),
    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('ad', model.ad);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adRfpIsCreated', model.adRfpIsCreated);
      controller.set('adRfp', model.adRfp);
      //controller.set('mediaTypes', model.mediaTypes);

      controller.cleanUp();
    } //setupController
  });
});