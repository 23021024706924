define("adtikir3v1/utils/adk-date-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let adkDateTime = {
    convertDateObjToDateString(dateObj, utcOffset, dateFormat) {
      if (!(dateObj instanceof _moment.default)) {
        dateObj = (0, _moment.default)(dateObj);
      }
      return dateObj.utcOffset(utcOffset).format(dateFormat);
    },
    convertDateObjToString(dateObj) {
      let dateObjFlag = false;
      if (dateObj instanceof _moment.default) {
        return dateObj.format('YYYY-MM-DD');
      }
      if (dateObj) {
        dateObjFlag = typeof dateObj.getMonth === 'function';
      }
      if (dateObjFlag) {
        //convert to date string;
        return (0, _moment.default)(dateObj).format('YYYY-MM-DD');
      }
      return dateObj;
    },
    validDate: function (dateString) {
      //check valid date format first
      //  dateString = dateString.convertDateObjToString(dateString);
      dateString = (0, _moment.default)(dateString).format('YYYY-MM-DD');
      let regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString) {
        return '';
      }
      if (!dateString.match(regEx)) return false; // Invalid format
      //check valid date
      let d = new Date(dateString);
      if (!d.getTime() && d.getTime() !== 0) return false; // Invalid date
      //check iso string first 10 is equild datestring
      return d.toISOString().slice(0, 10) === dateString;
    },
    compareYearMonth: function (date1, date2) {
      date1 = (0, _moment.default)(date1).format('YYYY-MM-DD');
      date2 = (0, _moment.default)(date2).format('YYYY-MM-DD');
      let date1Split = {
        year: +date1.substr(0, 4),
        month: +date1.substr(5, 2),
        day: +date1.substr(8, 2)
      };
      let date2Split = {
        year: +date2.substr(0, 4),
        month: +date2.substr(5, 2),
        day: +date2.substr(8, 2)
      };
      if (date1Split.year > date2Split.year) {
        return 1;
      } //year bigger
      if (date1Split.year < date2Split.year) {
        return -1;
      } //year smaller
      if (date1Split.month > date2Split.month) {
        return 2;
      } //month bigger
      if (date1Split.month < date2Split.month) {
        return -2;
      } //month smaller
      return 0;
    },
    convertDate: function (dateStr) {
      return (0, _moment.default)(dateStr).format('YYYY-MM-DD');
    },
    // Takes 2 dates in moment.js format
    // for eg today = new moment();
    // It takes the difference in number of days and return the number       
    dayDiff: function (date1, date2) {
      return date2.diff(date1, 'days');
      // return moment.duration(date2.diff(date1)).asDays();
    },
    compareDate: function (date1, date2) {
      //assume date1 and date2 are valid date format YYYY-DD-MM
      //return 1 if date1 > date2
      //return 0 if date1 == date22
      //return -1 if date1 < date2
      // date1 = this.convertDateObjToString(date1);
      // date2 = this.convertDateObjToString(date2);
      date1 = (0, _moment.default)(date1).format('YYYY-MM-DD'); //campaign end date
      date2 = (0, _moment.default)(date2).format('YYYY-MM-DD'); //campaign start date

      let result = this.compareYearMonth(date1, date2);
      if (result != 0) {
        return result;
      }
      let date1Split = {
        year: +date1.substr(0, 4),
        month: +date1.substr(5, 2),
        day: +date1.substr(8, 2)
      };
      let date2Split = {
        year: +date2.substr(0, 4),
        month: +date2.substr(5, 2),
        day: +date2.substr(8, 2)
      };
      if (date1Split.day > date2Split.day) {
        return 11;
      } //day bigger
      if (date1Split.day < date2Split.day) {
        return -11;
      } //day smaller
      return 0; //same
    },
    //compareDate

    getDayFromYear: function (date) {
      date = this.convertDateObjToString(date);
      const oneDay = 86400000; //1000*60*60*24;
      let dateObj = new Date(date);
      let start = new Date.apply(dateObj.getFullYear(), 0, 0);
      let diff = dateObj - start + (dateObj.getTimezoneOffset() - start.getTimezoneOffset()) * 60 * 1000;
      let day = Math.floor(diff / oneDay);
      return day;
    }
  }; //let
  var _default = _exports.default = adkDateTime;
});