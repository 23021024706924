define("adtikir3v1/pods/agency/request/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { computed } from '@ember/object';
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    intl: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    proposalInvited: null,
    vAgencyProposal: null,
    vAdAgencyProposal: null,
    refreshRfpAttachment: false,
    selectedAdvertiserRfp: null,
    // resetProposalInvited: function () {
    //   this.setProperties('proposalInvited', null);
    // },

    loadVProposalInvitedTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-proposal-invited', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);

      //alert(pagingObj.refresh + ' ' +  pagingObj.firstFg + ' ' + pagingObj.initFg);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }
      return true;
    }),
    loadVAdAgencyInvitedTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-ad-agency-proposal-invited', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);

      //alert(pagingObj.refresh + ' ' +  pagingObj.firstFg + ' ' + pagingObj.initFg);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }
      return true;
    }),
    resetForm() {
      //this will be call during setupController in route

      //firstFg ... first time flag
      //initFg ... to use for expand or not 

      let runArray = [['Invited', 'new'], ['Submitted', 'submitted'], ['Expired', 'expired']];

      //this will create 
      // vProposalInvitedObjInvited
      // vProposalInvitedObjSubmitted
      // vProposalInvitedObjExpired
      // vAdAgencyProposalInvitedObjjInvited
      // vAdAgencyProposalInvitedObjSubmitted
      // vAdAgencyProposalInvitedObjExpired

      for (let i = 0; i < runArray.length; i++) {
        this.set('vProposalInvitedObj' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVProposalInvitedTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
        this.set('vAdAgencyProposalInvitedObj' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVAdAgencyInvitedTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
      } //for

      this.set('loopArray', [['adkExpansionPanelAgencyAwarded',
      //class
      'New Request List',
      //title
      'Newly request invitation',
      //titleSummary
      'vProposalInvitedObjInvited', 'vAdAgencyProposalInvitedObjInvited'], ['adkExpansionPanelAgencyPending', 'Submitted List', 'Invitation with submited proposal', 'vProposalInvitedObjSubmitted', 'vAdAgencyProposalInvitedObjSubmitted'], ['adkExpansionPanelAgencyAccept', 'Expired List', 'Expired invitation', 'vProposalInvitedObjExpired', 'vAdAgencyProposalInvitedObjExpired']]);
      this.set('proposalInvited', null);

      // if (this.vProposalAgencyId) {
      //   this.store.findRecord('v-proposal-agency', this.vProposalAgencyId)
      //     .then(function (vAgencyProposal) {
      //       this.set('vAgencyProposal', vAgencyProposal);
      //     });
      // }
      this.clearProposal();
    },
    clearProposal() {
      this.set('vAdAgencyProposal', null);
      this.set('vAgencyProposal', null);
      this.set('adRfp', null);
      this.set('adAgencyRfpBody', null);
      this.set('adCampaignRfp', null);
      this.set('loadRfpTaskInstance', null);
      this.set('loadAdRfpTaskInstance', null);
      this.set('isNotAdRequest', true);
    },
    loadRfpTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignRfp = yield this.get('proposalInvited').get('adCampaignRfp');
      if (!adCampaignRfp) {
        return;
      }
      this.set('adCampaignRfp', adCampaignRfp);
      let vAgencyProposal = yield this.store.query('vProposalAgency', {
        adCampaignRfp: adCampaignRfp.id
      });
      if (this.get('proposalInvited').get('status') === "submitted") {
        this.set('editMode', false);
      }
      if (vAgencyProposal) {
        this.set('vAgencyProposal', vAgencyProposal.firstObject);
      }
      this.set('refreshRfpAttachment', true);
    }),
    saveProposalAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adkToast = this.get('adkToast');
      let vAgencyProposal = this.get('vAgencyProposal');
      let attachment = this.store.createRecord('proposal-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        vProposalAgency: vAgencyProposal //note... server still suing vProposalAgency... 
        //quite diff to change.. 
      });
      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        console.error(e);
        if (e.errors) {
          adkToast.display("Error, " + e.errors[0].detail, "error", 1500);
        } else {
          adkToast.display("Error, " + e, "error", 1500);
        }
        throw e;
      }
    }).drop(),
    saveAdAgencyProposalAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adkToast = this.get('adkToast');
      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      let attachment = this.store.createRecord('ad-agency-proposal-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        vAdAgencyProposal: vAdAgencyProposal
      });
      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        console.error(e);
        if (e.errors) {
          adkToast.display("Error, " + e.errors[0].detail, "error", 1500);
        } else {
          adkToast.display("Error, " + e, "error", 1500);
        }
        throw e;
      }
    }).drop(),
    // setProposalInvited(proposalInvited,adCampaignRfpId) {            
    setProposalInvitedTask: (0, _emberConcurrency.task)(function* (proposalInvited) {
      //clear the proposal first
      this.clearProposal();
      this.set('proposalInvited', proposalInvited);
      // this.set('adCampaignRfpId', adCampaignRfpId);
      this.set('loadRfpTaskInstance', this.loadRfpTask.perform());
      yield this.get('loadRfpTaskInstance');
      if (proposalInvited.status === "submitted") {
        if (this.get('vAgencyProposal')) {
          let router = this.get('router');
          router.transitionTo('agency.awards.index', {
            queryParams: {
              vAgencyProposalId: this.get('vAgencyProposal').id,
              vAdAgencyProposalId: ''
            }
          });
        }
      }
    }),
    // adAgency section coding start here

    loadAdRfpTask: (0, _emberConcurrency.task)(function* () {
      let proposalInvited = this.get('proposalInvited');
      let adRfp = yield proposalInvited.get('adRfp');
      let adAgencyRfpBody = yield proposalInvited.get('adAgencyRfpBody');
      if (!adRfp) {
        return;
      }
      this.set('adRfp', adRfp);
      this.set('adAgencyRfpBody', adAgencyRfpBody);
      let vAdAgencyProposal = yield this.store.query('v-ad-agency-proposal', {
        adAgencyRfpBody: adAgencyRfpBody.id
      });
      if (this.get('proposalInvited').get('status') === "submitted") {
        this.set('editMode', false);
      }
      if (vAdAgencyProposal) {
        this.set('vAdAgencyProposal', vAdAgencyProposal.firstObject);
      }
      this.set('refreshRfpAttachment', true);
    }),
    setAdProposalInvitedTask: (0, _emberConcurrency.task)(function* (proposalInvited) {
      //clear the proposal first
      this.clearProposal();
      this.set('isNotAdRequest', false);
      this.set('proposalInvited', proposalInvited);
      // this.set('adCampaignRfpId', adCampaignRfpId);
      this.set('loadAdRfpTaskInstance', this.loadAdRfpTask.perform());
      yield this.get('loadAdRfpTaskInstance');
      if (proposalInvited.status === "submitted") {
        if (this.get('vAdAgencyProposal')) {
          let router = this.get('router');
          router.transitionTo('agency.awards.index', {
            queryParams: {
              vAgencyProposalId: '',
              vAdAgencyProposalId: this.get('vAdAgencyProposal').id
            }
          });
        }
      }
    }),
    createAgencyProposalTask: (0, _emberConcurrency.task)(function* (adCampaignRfp) {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adkToast = this.get('adkToast');
      let responseExpiredDayCount = 14;
      let validDate = (0, _moment.default)(adCampaignRfp.proposalDeadline).add(responseExpiredDayCount, 'days');
      const vAgencyProposal = this.store.createRecord('vProposalAgency', {
        adCampaignRfp: adCampaignRfp,
        responseExpiredDate: (0, _moment.default)(validDate).toDate(),
        responseExpiredDayCount: responseExpiredDayCount
      });
      yield vAgencyProposal.save();
      this.set('vAgencyProposal', vAgencyProposal);
      adkToast.display("Proposal successfully created.", "warning");
    }),
    createAdAgencyProposalTask: (0, _emberConcurrency.task)(function* (adAgencyRfpBody) {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adkToast = this.get('adkToast');
      let responseExpiredDayCount = 14;
      let validDate = (0, _moment.default)(adAgencyRfpBody.proposalDeadline).add(responseExpiredDayCount, 'days');
      const vAdAgencyProposal = this.store.createRecord('v-ad-agency-proposal', {
        adAgencyRfpBody: adAgencyRfpBody,
        responseExpiredDate: (0, _moment.default)(validDate).toDate(),
        responseExpiredDayCount: responseExpiredDayCount
      });
      yield vAdAgencyProposal.save();
      this.set('vAdAgencyProposal', vAdAgencyProposal);
      adkToast.display(Ember.String.capitalize(this.intl.t("ad")) + " proposal successfully created.", "warning");
    }),
    actions: {
      error() {
        if (this.get('vAgencyProposal')) {
          this.set('vAgencyProposal', null);
        }
      },
      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateAdAgencyProposal(type) {
        let vAdAgencyProposal = this.get('vAdAgencyProposal');
        if (type == "submitted") {
          let controller = this;
          //need to do invitee update here;
          return vAdAgencyProposal.save().then(function () {
            //controller need to refresh the list;
            Ember.set(controller.get('vAdAgencyProposalInvitedObjSubmitted'), "refresh", true);
            Ember.set(controller.get('vAdAgencyProposalInvitedObjSubmitted'), "initFg", true);
            Ember.set(controller.get('vAdAgencyProposalInvitedObjSubmitted'), "firstFg", true);
            Ember.set(controller.get('vAdAgencyProposalInvitedObjInvited'), "refresh", true);
            Ember.set(controller.get('vAdAgencyProposalInvitedObjInvited'), "initFg", true);
            Ember.set(controller.get('vAdAgencyProposalInvitedObjInvited'), "firstFg", true);
          });
        }
        //this.vAgencyProposal.setProperties(componentValues);
        return vAdAgencyProposal.save();
      },
      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateAgencyProposal(type) {
        let vAgencyProposal = this.get('vAgencyProposal');
        if (type == "submitted") {
          let controller = this;
          //need to do invitee update here;
          return vAgencyProposal.save().then(function () {
            //controller need to refresh the list;
            Ember.set(controller.get('vProposalInvitedObjSubmitted'), "refresh", true);
            Ember.set(controller.get('vProposalInvitedObjSubmitted'), "initFg", true);
            Ember.set(controller.get('vProposalInvitedObjSubmitted'), "firstFg", true);
            Ember.set(controller.get('vProposalInvitedObjInvited'), "refresh", true);
            Ember.set(controller.get('vProposalInvitedObjInvited'), "initFg", true);
            Ember.set(controller.get('vProposalInvitedObjInvited'), "firstFg", true);
          });
        }
        //this.vAgencyProposal.setProperties(componentValues);
        return vAgencyProposal.save();
      }
    } //End of actions
  });
});