define("adtikir3v1/models/my-company-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    cityId: attr('string'),
    state: attr('string'),
    stateId: attr('string'),
    postCode: attr('string'),
    description: attr('string'),
    dateFormat: attr('string'),
    timeFormat: attr('string'),
    timeZoneId: attr('string'),
    thousandSeperator: attr('string'),
    decimalSeperator: attr('string'),
    myCompany: belongsTo('my-company', {
      async: true
    }),
    financialAccounts: hasMany('financial-account', {
      async: true
    }),
    //  cWorkFlows       : hasMany('c-work-flow', {async: true}),
    updatedAt: attr('date'),
    createdAt: attr('date')
  });
});