define("adtikir3v1/pods/components/adk-companyinfo-accnum/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    accountColumns: [{
      propertyName: 'accNum',
      title: 'Account Number'
    }, {
      propertyName: 'costCode',
      title: 'Cost Code'
    }, {
      propertyName: 'desc',
      title: 'Description'
    }],
    dataAccNum: [{
      accNum: "010104",
      costCode: "C5",
      desc: "Radio Creation"
    }, {
      accNum: "010204",
      costCode: "D7",
      desc: "Radio Elements"
    }, {
      accNum: "010301",
      costCode: "E2",
      desc: "Misc Expenses"
    }],
    actions: {
      upload() {}
    }
  });
});