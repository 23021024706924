define("adtikir3v1/pods/components/adkcard-dashboard-campaign-users/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
    },
    didReceiveAttrs() {
      this.set('loadCompanyRolesTaskInstance', this.get('loadCompanyRolesTask').perform(this));
      this.set('loadAdCampaignUserRoleTaskInstance', this.get('loadAdCampaignUserRoleTask').perform(this));
    },
    campaignUserList: Ember.computed('adCampaignUserRoles.[]', function () {
      let adCampaignUserRoles = this.get('adCampaignUserRoles');
      let companyRoles = this.get('companyRoles');
      let campaignOwner = companyRoles.filterBy('role', 'OWNER');
      let users = adCampaignUserRoles.filter(campaignUser => {
        return campaignUser.get('companyRole').get('role') === 'USER' || campaignUser.get('companyRole').get('role') === 'MANAGER';
      });
      users.forEach(user => {
        user.get('companyRole').then(campaignUser => {
          campaignOwner.pushObject(campaignUser);
        });
      });
      return campaignOwner;
    }),
    companyUserList: Ember.computed('companyRoles.[]', 'adCampaignUserRoles.[]', function () {
      let companyRoles = this.get('companyRoles');
      let adCampaignUserRoles = this.get('adCampaignUserRoles');
      let availableUser = companyRoles.filter(companyRole => {
        return companyRole.role === 'USER' || companyRole.role === 'MANAGER';
      });
      availableUser.forEach(user => {
        adCampaignUserRoles.forEach(campaignUser => {
          campaignUser.get('companyRole').then(campaignUserRole => {
            if (user.get('companyUser').get('id') === campaignUserRole.get('companyUser').get('id')) {
              availableUser.removeObject(user);
            }
          });
        });
      });
      return availableUser;
    }),
    actions: {
      closeErr() {
        this.set('showErrorFg', false);
      },
      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },
      closeDialogWithParent() {
        this.set('showDialogWithParent', false);
      },
      removePerson(record) {
        let adCampaign = this.get('adCampaign');
        let adkToast = this.get('adkToast');
        let adCampaignUserRole = this.store.query('adcampaign-userrole', {
          AdCampaignId: adCampaign.id,
          UserRoleId: record.id
        }).then(function (user) {
          return user.get('firstObject');
        });
        record.companyUser.then(compUser => {
          let emailUser = this.store.queryRecord('emailGroup', {
            AdCampaignId: this.get('adCampaign').id,
            email: compUser.email
          });
          emailUser.then(user => {
            user.destroyRecord();
          });
        });
        adCampaignUserRole.then(function (user) {
          user.destroyRecord().then(() => {
            adkToast.display("User removed successfully", "warning", 3000);
          }, () => {
            adkToast.display("Failed to remove user", "error");
          });
        });
      },
      addPerson(record) {
        let adkToast = this.get('adkToast');
        let adCampaign = this.get('adCampaign');
        let addUserRole = this.store.createRecord('adcampaign-userrole', {
          adCampaign: adCampaign,
          companyRole: record
        });
        addUserRole.save().then(() => {
          this.get('loadAdCampaignUserRoleTask').perform(this);
          record.companyUser.then(user => {
            let emailUser = this.store.createRecord('emailGroup', {
              AdCampaignId: adCampaign.id,
              email: user.email
            });
            emailUser.save();
          });
          adkToast.display("User added successfully", "warning", 3000);
        }, () => {
          adkToast.display("Failed to add user", "error");
        });
      }
    }
  });
});