define("adtikir3v1/models/vendor-agency-language", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    vendor: belongsTo('vendor'),
    language: belongsTo('language'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});