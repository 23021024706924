define("adtikir3v1/pods/campaign/details/ads/ad/financials/budget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BQxudtGc",
    "block": "{\"symbols\":[],\"statements\":[[5,\"adkcard-quickbudget\",[],[[\"@ad\",\"@adCampaign\",\"@isRedirect\"],[[22,\"ad\"],[22,\"adCampaign\"],\"false\"]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gte\",[[24,[\"ad\",\"workFlowStatusNumber\"]],2],null]],null,{\"statements\":[[0,\"   \"],[5,\"adk-ad-planned-budget-approved\",[],[[\"@ad\",\"@adCampaign\"],[[22,\"ad\"],[22,\"adCampaign\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/ads/ad/financials/budget/template.hbs"
    }
  });
});