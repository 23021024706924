define("adtikir3v1/models/c-work-flow", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    updatedAt: attr('date'),
    createdAt: attr('date'),
    isDisabled: attr('boolean'),
    isSelected: attr('boolean'),
    workFlowStatus: attr('string'),
    workFlowStatusLocalLanguage: attr('string'),
    workFlowColor: attr('string'),
    myCompanyDetail: belongsTo('my-company-detail', {
      async: true
    })
  });
});