define("adtikir3v1/pods/components/adk-multiple-uploader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { addObserver, removeObserver } from '@ember/object/observers';
  // import {
  //   computed
  // } from './@ember/object';
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    maxCount: 0,
    pageSize: 5,
    showNewUpload: false,
    newDescription: '',
    showAttachment: false,
    deleteDialog: false,
    attachmentFile: null,
    //attachmentFile var to keep the new uploaded file

    refreshFunc: function () {
      if (this.refresh) {
        Ember.set(this.atachmentsList, "refresh", true);
      }
      Ember.set(this, "refresh", false);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('atachmentsList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadAttachmentTask"),
        total: 0,
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('refresh', false);
      this.addObserver('refresh', this.refreshFunc);
    },
    //didReceiveAttrs

    createNewAttachmentTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let fg = true;
      if (this.get('newDescription').length === 0) {
        adkToast.display("Please put in some descriptions", "warning", 1500);
        fg = false;
      }
      if (!this.get('attachmentFile')) {
        adkToast.display("Please Attach a file first", "warning", 1500);
        fg = false;
      }
      if (!fg) {
        return fg;
      }
      let obj = {
        attachFile: this.get('attachmentFile'),
        description: this.get('newDescription')
      };
      this.set('createNewAttachmentTaskInstance', this.get('saveAttachmentTask').perform(obj));
      yield this.get('createNewAttachmentTaskInstance');
      let attachmentList = this.get('atachmentsList');
      Ember.set(attachmentList, "actionInstance", yield attachmentList.action.perform(attachmentList.page, attachmentList)); //set
      this.clearNewForm();
      this.set('showNewUpload', false);
      this.set('refresh', true);
      Ember.set(attachmentList, 'refresh', true);
      adkToast.display("New attachment saved", "warning", 2000);
    }),
    clearNewForm: function () {
      //this.set('newTitle', '');
      this.set('attachmentFile', '');
      this.set('newDescription', '');
      this.set('newAttachment', '');
    },
    deleteMeTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let attachment = this.get('theAttachment');
      //delete the record
      yield attachment.destroyRecord();
      adkToast.display('Record deleted', "warning", 1500);
      this.set('deleteDialog', false);
      let attachmentList = this.get('atachmentsList');
      Ember.set(attachmentList, 'refresh', true);
      this.set('refresh', true);
      this.set('theAttachment', null);
    }),
    actions: {
      openNewUpload(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.clearNewForm();
        this.set('showNewUpload', true);
      },
      closeNewUpload() {
        this.clearNewForm();
        this.set('showNewUpload', false);
      },
      /* Dialog with new comment */

      saveTask(hasFileUpload) {
        if (hasFileUpload) {
          return true;
        }
        return false;
        //this.set('saveTaskInstance', this.saveTaskAction.perform());
      },
      selectCard(attachment, ev) {
        let calling = ev.currentTarget.nodeName;
        this.set('dialogOrigin', ev.currentTarget.childNodes[1]);
        this.set('theAttachment', attachment);
        if (calling === "BUTTON") {
          this.set('deleteDialog', true);
        } else {
          if (!this.deleteDialog) {
            this.set('showAttachment', true);
          }
        }
      },
      closeShowAttachment() {
        this.set('theAttachment', null);
        this.set('showAttachment', false);
      },
      closeDeleteDialog() {
        this.set('theAttachment', null);
        this.set('deleteDialog', false);
      },
      adkAttachmentActive() {}
    }
  });
});