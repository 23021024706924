define("adtikir3v1/pods/agency/projectmgmt/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {
  //   computed
  // } from '@ember/object';
  // import moment from 'moment';
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    proposalInvited: '',
    proposalAgency: false,
    awardedProj: '',
    adCampaignRfp: '',
    showAward: false,
    adCampaignInvoice: '',
    selectedAdvertiserRfp: null,
    loadRfpTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignRfp = yield this.get('proposalInvited').get('adCampaignRfp');
      this.set('adCampaignRfp', adCampaignRfp);
    }),
    // loadAttachmentTask: task(function* (page, pagingObj) {
    //   let adCampaignRfpId = pagingObj.parentObj.id

    //   let attachments = yield this.store.query('rfp-attachment', {
    //     adCampaignRfp: adCampaignRfpId,
    //     page: page,
    //     pageSize: pagingObj.pageSize
    //   });
    //   if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
    //     set(pagingObj, "total", attachments.meta.page.total);
    //   }
    //   set(pagingObj, "result", attachments);
    //   //return attachments;
    // }).restartable(),

    loadVProposalAgenciesTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let result = yield this.store.query('vProposalAgency', {
        project: 'project',
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
    }),
    setAwardTask: (0, _emberConcurrency.task)(function* (vProposalAgency) {
      this.clearVar();
      this.set('vProposalAgency', vProposalAgency);
      // this.set('adCampaignRfp', null);

      let adCampaignRfp = yield vProposalAgency.get('adCampaignRfp');
      this.set('adCampaignRfp', adCampaignRfp);
    }),
    loadNewInvoiceTask: (0, _emberConcurrency.task)(function* (component) {
      let vProposalAgency = this.get('vProposalAgency');
      let vAdCampaignInvoice = yield this.store.query('vAgencyInvoice', {
        vProposalAgency: vProposalAgency.id
      });
      component.set("vAdCampaignInvoice", vAdCampaignInvoice);
    }),
    loadVendorRolesTask: (0, _emberConcurrency.task)(function* (component) {
      let adCampaignRfp = this.get('adCampaignRfp');
      let vendorRoles = yield this.store.query('vendorRole', {
        adCampaignRfp: adCampaignRfp.id
      });
      component.set("vendorRoles", vendorRoles.sortBy('status'));
    }),
    loadCompanyRolesTask: (0, _emberConcurrency.task)(function* (component) {
      let adkSessionAccount = this.get('adkSessionAccount');
      let myCompany = yield adkSessionAccount.get('myCompany');
      yield myCompany.reload(); /// y need reload?
      let companyRoles = yield myCompany.get('companyRoles');
      component.set("companyRoles", companyRoles);
    }),
    resetForm() {
      this.set('vProposalAgenciesPagingObj', {
        page: 0,
        pageSize: 20,
        action: this.get('loadVProposalAgenciesTask'),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    clearVar() {
      this.set('adCampaignRfp', null);
      this.set('vProposalAgency', null);
      this.set('vAdCampaignInvoice', null);
      this.set('vendorRoles', null);
      this.set('companyRoles', null);
    },
    actions: {
      error() {
        if (this.get('vProposalAgency')) {
          this.set('vProposalAgency', null);
        }
      },
      setAward(vProposalAgency) {
        this.set('setAwardTaskInstance', this.get('setAwardTask').perform(vProposalAgency));
      },
      // loadVendorRoles() {

      // },

      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateAgencyProposal(componentValues) {
        this.vProposalAgency.setProperties(componentValues);
        this.vProposalAgency.save();
      },
      createNewInvoice(componentValues, vProposalAgency) {
        let vAgencyInvoice = this.store.createRecord('vAgencyInvoice', {
          invoiceNumber: componentValues.invoiceNumber,
          description: componentValues.description,
          invoiceAmount: componentValues.invoiceAmount,
          agencyCost: componentValues.agencyCost,
          invoiceDoc: componentValues.invoiceDoc,
          notes: componentValues.notes,
          submissionDate: componentValues.submissionDate,
          dueDate: componentValues.dueDate,
          createdAt: componentValues.createdAt
        });
        vAgencyInvoice.set('vProposalAgency', vProposalAgency);
        return vAgencyInvoice.save();
      }
    } //End of actions
  });
});