define("adtikir3v1/models/ad-post", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    media: attr('string', {
      defaultValue: ''
    }),
    //  stage: attr('string'),
    //  workFlowStatusNumber: attr('string'),  //should be no this anymore..
    // the number ge from adApproval
    title: attr('string', {
      defaultValue: "untitled"
    }),
    status: attr('string', {
      defaultValue: "active"
    }),
    approved: attr('boolean', {
      defaultValue: false
    }),
    legalApprovalStage: attr('string', {
      defaultValue: 'nReviewed'
    }),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    // approvedBy: belongsTo('myRole'),
    mediaFormat: attr('string'),
    mediaType: attr('string'),
    wflowNum: attr('string'),
    comment: attr('string'),
    thumbnail: attr('string'),
    //creativity approval
    approvedComment: attr('string', {
      defaultValue: ''
    }),
    approvedFirstName: attr('string', {
      defaultValue: ''
    }),
    approvedLastName: attr('string', {
      defaultValue: ''
    }),
    approvedRole: attr('string', {
      defaultValue: ''
    }),
    approvedAt: attr('date'),
    //legal approval
    legalApprovalComment: attr('string', {
      defaultValue: ''
    }),
    lastModifiedLastName: attr('string', {
      defaultValue: ''
    }),
    lastModifiedFirstName: attr('string', {
      defaultValue: ''
    }),
    lastModifiedRole: attr('string', {
      defaultValue: ''
    }),
    lastModifiedAt: attr('date'),
    latestCommentAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    adApproval: belongsTo('ad-approval'),
    latestDiscussion: belongsTo('ad-post-discussion', {
      inverse: null
    }),
    // adstages: belongsTo('adstage')

    statusString: Ember.computed('legalApprovalStage', function () {
      let legalApprovalStage = this.get('legalApprovalStage');
      let statusString = {
        nReviewed: "Not Reviewed",
        approved: "Approved",
        nApproved: "Not Approved",
        nRequired: "Legal Reviewed Not Required"
      };
      return statusString[legalApprovalStage];
    }),
    fileExt: Ember.computed('media', function () {
      let adPostMedia = this.get('media');
      let ext;
      if (adPostMedia) {
        ext = adPostMedia.split('/').pop();
        if (ext.lastIndexOf('.') < 0) {
          ext = undefined;
        } else {
          ext = ext.substring(ext.lastIndexOf('.') + 1, ext.length).toLowerCase();
        }
      }
      if (!ext) {
        ext = "jpg";
      }
      return ext;
    })
  });
});