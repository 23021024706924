define("adtikir3v1/pods/campaign/details/reports/campaignoverall/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "28rKZSL6",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flex layout-align-start-stretch\"],[8],[0,\"\\n\"],[5,\"adk-report-campaign-overall\",[],[[\"@model\",\"@fieldTitle\",\"@adCampaign\",\"@adCampaignDetail\",\"@adCampaignBudget\",\"@adTotalMediatype\",\"@targeting\",\"@locationList\"],[[22,\"model\"],[28,\"concat\",[[28,\"capitalize\",[[28,\"t\",[\"campaign\"],null]],null],\" Report\"],null],[22,\"adCampaign\"],[22,\"adCampaignDetail\"],[22,\"adCampaignBudget\"],[22,\"adTotalMediatype\"],[22,\"targeting\"],[22,\"locationList\"]]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\" \\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/reports/campaignoverall/template.hbs"
    }
  });
});