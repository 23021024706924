define("adtikir3v1/pods/components/adk-ad-card-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);
    },
    actions: {
      deleteAd(ad) {
        console.log("adkadcardlist - deleteAd - ad= ", ad);
        ad.destroyRecord();
        this.set('showDialogWithParent', false);
      },
      openDialogDeleteAd(ad) {
        console.log("adkadcardlist - opendialogdeletead - ad= ", ad);
        this.set('ad', ad);
        this.set('dialogOrigin', null);
        // this.set('dialogOrigin', event.currentTarget);
        this.set('showAdMgrDialog', true);
      },
      closeDialogDeleteAd() {
        this.set('showAdMgrDialog', false);
      },
      cancelAction() {}
    }
  });
});