define("adtikir3v1/pods/agency/admanager/ad/approval/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QjA/GY77",
    "block": "{\"symbols\":[\"adApproval\",\"card\"],\"statements\":[[4,\"if\",[[28,\"lt\",[[24,[\"ad\",\"workFlowStatusNumber\"]],3],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex layout-column md-padding\"],[8],[0,\"\\n\"],[4,\"paper-content\",null,null,{\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('adtikir3v1/pods/agency/admanager/ad/approval/template.hbs' @ L5:C19) \"],null]],null,{\"statements\":[[0,\"                The current \"],[1,[28,\"t\",[\"ad\"],null],false],[0,\" is not in stage yet,\\n                nothing can be approved.\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"adApprovals\"]]],null,{\"statements\":[[0,\"        \"],[5,\"adkpanel-approval\",[],[[\"@agency\",\"@adApproval\",\"@ad\",\"@acWorkFlows\",\"@loadAdPosts\",\"@loadAdPostComments\",\"@loadAdPostDiscussions\",\"@saveNewAdPost\",\"@saveNewDiscussion\",\"@saveNewAdPDCmt\"],[true,[23,1,[]],[22,\"ad\"],[22,\"acWorkFlows\"],[28,\"action\",[[23,0,[]],\"loadAdPosts\"],null],[28,\"action\",[[23,0,[]],\"loadAdPostComments\"],null],[28,\"action\",[[23,0,[]],\"loadAdPostDiscussions\"],null],[28,\"action\",[[23,0,[]],\"createAdPost\"],null],[28,\"action\",[[23,0,[]],\"createAdPostDiscussion\"],null],[28,\"action\",[[23,0,[]],\"createAdPDCmt\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/agency/admanager/ad/approval/template.hbs"
    }
  });
});