define("adtikir3v1/pods/campaign/details/vendor/media/clientbrief/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
      let adCampaignMediaRfpSetting = yield this.store.findRecord('ad-campaign-media-rfp-setting', adCampaign.id + "_mRfpSetting");
      let targeting = yield this.store.query('targeting', {
        adCampaign: adCampaign.id
      });

      // this is not working.. the target country and language is not written properly

      // let targetCountries = yield this.store.query('target-country', {
      //   filter: {
      //     query: adCampaign.id
      //   }
      // });
      // let targetLanguage = yield this.store.query('target-language', {
      //   filter: {
      //     query: adCampaign.id
      //   }
      // })

      return {
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail,
        adCampaignMediaRfpSetting: adCampaignMediaRfpSetting,
        targeting: targeting.firstObject
        // targetCountries: targetCountries,
        // targetLanguage: targetLanguage,
      };
    }),
    model: function () {
      return this.loadModelTask.perform();
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      //this.get('loadModelTask').perform(controller);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adCampaignMediaRfpSetting', model.adCampaignMediaRfpSetting);
      controller.set('targeting', model.targeting);
      // controller.set('targetCountries', model.targetCountries);
      // controller.set('targetLanguage', model.targetLanguage);
    } //setupConntroller
  });
});