define("adtikir3v1/pods/components/adk-prefer-vendor-list/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    showVendorDetail: false,
    showConfirmationDialog: false,
    init() {
      this._super(...arguments);
      this.set('myPreferAgencyList', {
        page: 0,
        pageSize: 10,
        action: this.get('loadMyPreferAgencyTask'),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('myPreferMediaList', {
        page: 0,
        pageSize: 10,
        action: this.get('loadMyPreferMediaTask'),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    didUpdateAttrs: function () {
      this._super(...arguments);
      let vendorDetail = document.getElementById("vendorDetail");
      let el = this.element;
      if (vendorDetail.style.display === "block") {
        el.appendChild(vendorDetail);
        vendorDetail.style.display = 'none';
      }
      this.clearShowVendorDetail();
    },
    setActiveVendorDetail(mdCard) {
      let nextMdCard = mdCard;
      mdCard.classList.add('adkListCardActive');
      this.set('activeMdClass', mdCard);
      let vendorDetail = document.getElementById("vendorDetail");
      nextMdCard.parentElement.insertBefore(vendorDetail, nextMdCard.nextSibling);
      this.set('showVendorDetail', true);
      vendorDetail.style.display = "block";
      vendorDetail.classList.add("flex-100");
      Ember.run.once(this, function () {
        mdCard.scrollIntoView();
      });
    },
    loadMyPreferAgencyTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let myPreferVendors = yield this.store.query('my-prefer-vendor', {
        page: page,
        pageSize: pagingObj.pageSize,
        vendorType: "a"
      });

      // if(myPreferVendors.meta && myPreferVendors.meta.page && myPreferVendors.meta.page.total) {
      //     set(pagingObj, "total", myPreferVendors.meta.page.total);
      // }
      Ember.set(pagingObj, "result", myPreferVendors);
    }).restartable(),
    loadMyPreferMediaTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let myPreferVendors = yield this.store.query('my-prefer-vendor', {
        page: page,
        pageSize: pagingObj.pageSize,
        vendorType: "m"
      });

      // if(myPreferVendors.meta && myPreferVendors.meta.page && myPreferVendors.meta.page.total) {
      //     set(pagingObj, "total", myPreferVendors.meta.page.total);
      // }
      Ember.set(pagingObj, "result", myPreferVendors);
    }).restartable(),
    clearShowVendorDetail() {
      this.set('vendor', null);
      this.set('activeMDCard', null);
      this.set('showVendorDetail', false);
      if (this.activeMdClass) {
        this.activeMdClass.classList.remove("adkListCardActive");
      }
      this.set('activeMdClass', null);
    },
    removePreferVendor: (0, _emberConcurrency.task)(function* (myList) {
      let adkToast = this.get('adkToast');
      try {
        yield myList.destroyRecord();
        adkToast.display("Successfully removed " + myList.vendor.get('company').get('companyName') + " from prefer list", "warning", 3000);
        this.set('showConfirmationDialog', false);
        this.refreshList();
      } catch (e) {
        adkToast.display("Fail to remove " + myList.vendor.get('company').get('companyName') + " vendor from prefer list", "error", 3000);
        console.error(e);
        return false;
      }
    }),
    refreshList() {
      this.set('myPreferAgencyList', {
        page: 0,
        pageSize: 10,
        action: this.get('loadMyPreferAgencyTask'),
        result: null,
        refresh: true,
        type: "emberdata"
      });
      this.set('myPreferMediaList', {
        page: 0,
        pageSize: 10,
        action: this.get('loadMyPreferMediaTask'),
        result: null,
        refresh: true,
        type: "emberdata"
      });
    },
    actions: {
      showDetailAction(vendor, event) {
        let currentMdCard = event.currentTarget.parentElement.parentElement;
        let closeMode = this.activeMdClass === currentMdCard ? true : false;
        this.clearShowVendorDetail();
        if (closeMode) {
          return;
        }
        Ember.run.later(this, function () {
          this.setActiveVendorDetail(currentMdCard);
          this.set('vendor', vendor);
          this.set('activeMDCard', currentMdCard);
          this.set('showVendorDetail', true);
        }, 300);
      },
      hideDetailAction() {
        this.set('showVendorDetail', false);
        this.didUpdateAttrs();
      },
      showConfirmationDialog(myList) {
        this.set('showConfirmationDialog', true);
        this.set('myList', myList);
      },
      closeConfirmationDialog() {
        this.set('showConfirmationDialog', false);
      }
    }
  });
});