define("adtikir3v1/pods/media/admanager/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkSessionAccount: Ember.inject.service(),
    loadMediaCompanyListTasks: (0, _emberConcurrency.task)(function* () {
      let result = yield this.store.query('list-ad-media-company', {
        date: new Date().getTime()
      });
      this.set('companyList', result);
    })
  });
});