define("adtikir3v1/pods/components/adk-models-table-display-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "T4MnKEAW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"adk-dt\",[],[[\"@t\",\"@theDate\"],[\"dt\",[28,\"get\",[[24,[\"record\"]],[28,\"get\",[[24,[\"column\"]],\"propertyName\"],null]],null]]],{\"statements\":[],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-display-date/template.hbs"
    }
  });
});