define("adtikir3v1/pods/components/adk-ad-planned-budget-approved/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    createFormReset() {
      this.set('amountLocal', 0.0);
      this.set('comment', "");
      this.set('title', "");
      this.set('chargeType', 'agency');
    },
    reviewFormReset() {
      this.set('status', '');
      this.set('comment', '');
    },
    reset() {
      this.set('showAddWindow', false);
      this.set('showReviewWindow', false);
      this.set('showEditWindow', false);
      this.set('showViewWindow', false);
      this.set('showErrorFg', false);
      this.set('adCampaignPlannedBudget', null);
    },
    init() {
      this._super(...arguments);
      this.pagingObj = {
        page: 0,
        pageSize: 20,
        action: this.get("loadPlannedBudgetApprovedTask"),
        result: null,
        refresh: false,
        type: "emberdata",
        adId: this.ad.id,
        initFg: true,
        firstFg: true
      };
      this.createFormReset();
      this.commentPagingObj = {
        page: 0,
        pageSize: 20,
        action: this.get("loadPlannedBudgetCommentTask"),
        result: null,
        refresh: false,
        type: "emberdata",
        adCampaignPlannedBudgetId: null,
        initFg: true,
        firstFg: true
      };
      this.reset();
    },
    loadPlannedBudgetApprovedTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query("ad-campaign-planned-budget-approved", {
        adId: pagingObj.adId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", result);
      Ember.set(pagingObj, "page", page);
      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, "initFg", false);
        Ember.set(pagingObj, "firstFg", false);
      }
      return true;
    }),
    loadPlannedBudgetCommentTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query("planned-budget-comment", {
        AdCampaignPlannedBudgetApprovedId: this.adCampaignPlannedBudget.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", result);
      Ember.set(pagingObj, "page", page);
      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, "initFg", false);
        Ember.set(pagingObj, "firstFg", false);
      }
      return true;
    }),
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return !isNaN(str) &&
      // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    },
    actions: {
      openDialogWithParent(event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set("showAddWindow", true);
        this.createFormReset();
      },
      openReviewWindow(adCampaignPlannedBudget, event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set('adCampaignPlannedBudget', adCampaignPlannedBudget);
        this.commentPagingObj.adCampaignPlannedBudgetId = this.adCampaignPlannedBudget.id;
        this.set("showReviewWindow", true);
        this.reviewFormReset();
      },
      openEditWindow(adCampaignPlannedBudget, event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set('adCampaignPlannedBudget', adCampaignPlannedBudget);
        this.commentPagingObj.adCampaignPlannedBudgetId = this.adCampaignPlannedBudget.id;
        this.set("showEditWindow", true);
      },
      openViewWindow(adCampaignPlannedBudget, event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set('adCampaignPlannedBudget', adCampaignPlannedBudget);
        this.commentPagingObj.adCampaignPlannedBudgetId = this.adCampaignPlannedBudget.id;
        this.set("showViewWindow", true);
      },
      closeErr() {
        this.set('showErrorFg', false);
      },
      closeDialogWithParent() {
        this.set("showAddWindow", false);
        this.createFormReset();
      },
      closeReviewWindow() {
        this.set("showReviewWindow", false);
        this.set('adCampaignPlannedBudget', null);
        this.reviewFormReset();
      },
      closeEditWindow() {
        this.set("showEditWindow", false);
        this.set('adCampaignPlannedBudget', null);
      },
      closeViewWindow() {
        this.set("showViewWindow", false);
        this.set('adCampaignPlannedBudget', null);
      },
      editPlannedBudgetCommentForm() {
        if (this.adCampaignPlannedBudget.status !== "review") {
          alert("Planned budget is not in the right status");
          return;
        }
        if (this.adCampaignPlannedBudget.title.trim().length === 0) {
          alert("please enter some value for title");
          return;
        }
        //check description
        if (this.adCampaignPlannedBudget.comment.trim().length === 0) {
          alert("please enter some value for description");
          return;
        }
        let amountLocal = this.adCampaignPlannedBudget.amountLocal + '';
        //check amount
        if (amountLocal.trim() === 0) {
          alert("please enter some value for amount");
          return;
        } else if (!this.isNumeric(amountLocal)) {
          alert("please enter a numeric value for amount");
          return;
        }
        if (!(this.adCampaignPlannedBudget.chargeType === "misc" || this.adCampaignPlannedBudget.chargeType === "agency" || this.adCampaignPlannedBudget.chargeType === "media")) {
          alert("please select the correct charge type");
          return;
        }

        //save to db
        let component = this;
        this.adCampaignPlannedBudget.save().then(function () {
          alert("record saved");
          component.actions.closeEditWindow.apply(component);
          Ember.set(component.pagingObj, "refresh", true);
        });
      },
      viewPlannedBudgetCommentForm() {
        this.actions.closeViewWindow.apply(this);
      },
      reviewPlannedBudgetCommentForm() {
        if (this.comment.trim().length === 0) {
          alert("please enter some value for comment");
          return;
        }
        if (!(this.status === "review" || this.status === "approve" || this.status === "reject")) {
          alert("please choose the right status");
          return;
        }

        //save to db
        let plannedBudgetComment = this.store.createRecord("planned-budget-comment", {
          comment: this.comment,
          status: this.status
        });
        plannedBudgetComment.set('adCampaignPlannedBudgetApproved', this.adCampaignPlannedBudget);
        let component = this;
        plannedBudgetComment.save().then(function () {
          alert("record saved");
          if (component.adCampaignPlannedBudget.status === "approve" || component.adCampaignPlannedBudget.status === "new") {
            component.ad.reload();
          }
          component.actions.closeReviewWindow.apply(component);
          Ember.set(component.pagingObj, "refresh", true);
        });
      },
      addPlannedBudgetForm() {
        //check title
        if (this.title.trim().length === 0) {
          alert("please enter some value for title");
          return;
        }
        //check description
        if (this.comment.trim().length === 0) {
          alert("please enter some value for description");
          return;
        }

        //check amount
        if (this.amountLocal.trim().length === 0) {
          alert("please enter some value for amount");
          return;
        } else if (!this.isNumeric(this.amountLocal)) {
          alert("please enter a numeric value for amount");
          return;
        }
        if (!(this.chargeType === "misc" || this.chargeType === "agency" || this.chargeType === "media")) {
          alert("please select the correct charge type");
          return;
        }

        //save to db
        let plannedBudgetApproved = this.store.createRecord("ad-campaign-planned-budget-approved", {
          amountLocal: this.amountLocal,
          title: this.title,
          comment: this.comment,
          createdAt: new Date(),
          updatedAt: new Date(),
          firstName: "",
          lastName: "",
          UserRoleId: "",
          role: "",
          currencyType: "local",
          currency: "",
          chargeType: this.chargeType,
          status: "new"
        });
        plannedBudgetApproved.set("ad", this.ad);
        plannedBudgetApproved.set("adCampaign", this.adCampaign);
        let component = this;
        plannedBudgetApproved.save().then(function () {
          alert("record saved");
          //component.createFormReset();
          //component.set("showAddWindow", false);
          component.actions.closeDialogWithParent.apply(component);
        });
      } //add
    } //actions
  });
});