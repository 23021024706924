define("adtikir3v1/pods/agency/awards/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import {
  //  computed
  //} from '@ember/object';
  //import moment from 'moment';
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['vAgencyProposalId', 'vAdAgencyProposalId'],
    vAgencyProposalId: null,
    vAdAgencyProposalId: null,
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    //proposalInvited: '',
    //proposalAgency: false,
    //awardedProj: '',
    adCampaignRfp: '',
    adRfp: '',
    adAgencyRfpBody: '',
    //showAward: false,

    selectedAdvertiserRfp: null,
    //type will be pending, award, accept and '' for this route
    loadVAgencyProposalByTypeTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-proposal-agency', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }
      return true;
    }),
    loadVAdAgencyProposalByTypeTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-ad-agency-proposal', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }
      return true;
    }),
    runLoadAgencyProposalTask: (0, _emberConcurrency.task)(function* (vAgencyProposalId) {
      // GET /blog-posts/1
      let vAgencyProposal = yield this.store.findRecord('v-proposal-agency', vAgencyProposalId);
      this.set('setAwardTaskInstance', this.get('setAwardTask').perform(vAgencyProposal));
    }),
    runLoadAdAgencyProposalTask: (0, _emberConcurrency.task)(function* (vAdAgencyProposalId) {
      // GET /blog-posts/1
      let vAdAgencyProposal = yield this.store.findRecord('v-ad-agency-proposal', vAdAgencyProposalId);
      this.set('setAdAgencyAwardTaskInstance', this.get('setAdAgencyAwardTask').perform(vAdAgencyProposal));
    }),
    init() {
      this._super(...arguments);
    },
    resetForm() {
      let runArray = [['PendingType', 'pending'], ['AcceptType', 'toAccept'], ['AwardType', 'awarded'], ['OthersType', '']];
      for (let i = 0; i < runArray.length; i++) {
        this.set('vAgencyProposal' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVAgencyProposalByTypeTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
        this.set('vAdAgencyProposal' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVAdAgencyProposalByTypeTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
      } //for

      this.set('loopArray', [['adkExpansionPanelAgencyAccept', 'To Accept', 'Accept', 'vAgencyProposalAcceptType', 'vAdAgencyProposalAcceptType'], ['adkExpansionPanelAgencyPending',
      //class
      'Pending',
      //title
      'Waiting for award',
      //titleSummary
      'vAgencyProposalPendingType', 'vAdAgencyProposalPendingType'], ['adkExpansionPanelAgencyAwarded', 'Awarded Projects', 'Awarded', 'vAgencyProposalAwardType', 'vAdAgencyProposalAwardType'], ['adkExpansionPanelAgencyRequest', 'Did Not Win', 'DNW', 'vAgencyProposalOthersType', 'vAdAgencyProposalOthersType']]);
      this.clearVar();
    },
    // loadRfpTask: task(function* () {
    //   //let adCampaignRfp = yield this.store.findRecord('adCampaignRfp', this.get('proposalInvited.adCampaignRfpId'));
    //   let adCampaignRfp = yield this.get('proposalInvited').get('adCampaignRfp');
    //   this.set('adCampaignRfp', adCampaignRfp);
    // }),

    // loadAwardTask: task(function* () {

    //   // let adCampaignRfp = yield this.store.findRecord('adCampaignRfp', this.get('awardedProj.adCampaignRfpId'));
    //   // this.set('adCampaignRfp', adCampaignRfp);

    //   // let adCampaignRfp = yield this.get('awardedProj').get('adCampaignRfp');

    //   // let adCampaignRfp = yield this.get('vProposalAgency').get('adCampaignRfp');
    //   let adCampaignRfp = yield this.get('vProposalAgency').get('adCampaignRfp');
    //   this.set('adCampaignRfp', adCampaignRfp);

    //   let vProposalAgency = yield this.store.query('vProposalAgency', {
    //     adCampaignRfp: adCampaignRfp.id,
    //   });
    //   let vProposalInvited = yield this.store.query('vProposalInvited', {
    //     adCampaignRfp: adCampaignRfp.id,
    //   });

    //   this.set('vProposalAgency', vProposalAgency);
    //   this.set('vProposalInvited', vProposalInvited);

    // }),

    clearVar() {
      this.set('vAgencyProposal', null);
      this.set('adCampaignRfp', null);
      this.set('vAdAgencyProposal', null);
      this.set('adRfp', null);
      this.set('adAgencyRfpBody', null);
      this.set('isAdCampaign', true);
    },
    // passDeadline: computed("daysToDeadline", function () {
    //   let daysToDeadline = this.get('daysToDeadline');
    //   if (daysToDeadline === 'Past Deadline') {
    //     return true;
    //   }
    //   return false;
    // }),

    setAwardTask: (0, _emberConcurrency.task)(function* (vAgencyProposal) {
      this.clearVar();
      this.set('vAgencyProposal', vAgencyProposal);
      let adCampaignRfp = yield vAgencyProposal.get('adCampaignRfp');
      this.set('adCampaignRfp', adCampaignRfp);
    }),
    setAdAgencyAwardTask: (0, _emberConcurrency.task)(function* (vAdAgencyProposal) {
      this.clearVar();
      this.set('vAdAgencyProposal', vAdAgencyProposal);
      let adRfp = yield vAdAgencyProposal.get('adRfp');
      this.set('adRfp', adRfp);
      let adAgencyRfpBody = yield vAdAgencyProposal.get('adAgencyRfpBody');
      this.set('adAgencyRfpBody', adAgencyRfpBody);
      this.set('isAdCampaign', false);
    }),
    actions: {
      // error(error) {

      //   if (this.get('vProposalAgency')) {
      //     this.set('vProposalAgency', null);
      //   };
      // },

      setAward(vProposalAgency) {
        this.set('setAwardTaskInstance', this.get('setAwardTask').perform(vProposalAgency));
      },
      setAdAgencyAward(vAdAgencyProposalAgency) {
        this.set('setAdAgencyAwardTaskInstance', this.get('setAdAgencyAwardTask').perform(vAdAgencyProposalAgency));
      }
    } //End of actions
  });
});