define("adtikir3v1/pods/components/adkcard-dashboard-budget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        adCampaign: this.get('adCampaign'),
        ads: this.get('ads'),
        campaignUltimateBudget: this.adCampaign.get('campaignUltimateBudget'),
        adTotalMediatype: this.get('adTotalMediatype'),
        // campaignBudget:            this.adCampaignBudget.get('campaignBudget'),
        // campaignCommitted:         this.adCampaignBudget.get('campaignCommitted'),
        campaignActualSpend: this.adCampaignBudget.get('campaignActualSpend'),
        newBudgetUltimate: '',
        localCurrency: this.get('adkSessionAccount.myCompany.currency'),
        foreignCurrency: this.get('adkSessionAccount.myCompany.foreignCurrency'),
        exchangeRate: this.adCampaign.get('exchangeRate')
      });
    },
    availableBudget: Ember.computed('campaignUltimateBudget', 'campaignPlanned', function () {
      return this.get('campaignUltimateBudget') - this.get('campaignPlanned');
    }),
    campaignPlanned: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.plannedTotal + currentTotal;
      }, 0);
      return total;
    }),
    campaignCommitted: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.committedTotal + currentTotal;
      }, 0);
      return total;
    }),
    campaignActuals: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.actualTotal + currentTotal;
      }, 0);
      return total;
    }),
    totalEstimate: 0,
    budgetWarning: Ember.computed('adcampaign.campaignBudgetAvailable', function () {
      let campaignBudgetAvailable = this.get('adcampaign.campaignBudgetAvailable');
      if (campaignBudgetAvailable < 0) {
        return true;
      }
      return false;
    })
  });
});