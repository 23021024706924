define("adtikir3v1/pods/components/adk-agency-projectmgmt/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { task } from 'ember-concurrency';
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    didReceiveAttrs() {
      this.set('loadVendorRolesTaskInstance', this.get('loadVendorRolesTask').perform(this));
      this.set('loadCompanyRolesTaskInstance', this.get('loadCompanyRolesTask').perform(this));
    },
    companyUserLists: Ember.computed('companyRoles', 'vendorRoles', function () {
      let companyRoles = this.get('companyRoles');
      let vendorRoles = this.get('vendorRoles');
      let availableUser = companyRoles.filter(companyRole => {
        return companyRole.role === 'USER' || companyRole.role === 'MANAGER' || companyRole.role === 'OWNER';
      });
      availableUser.forEach(user => {
        vendorRoles.forEach(vendorRole => {
          vendorRole.get('vendorUser').then(vendorUser => {
            if (user.get('companyUser').get('id') === vendorUser.get('userId')) {
              availableUser.removeObject(user);
            }
          });
        });
      });
      return availableUser;
    }),
    actions: {
      async save(event) {
        event.preventDefault();
      },
      nothing() {},
      addPerson(record) {
        let adCampaignProposalResponseId = this.get('vProposalAgency').id;
        let adkToast = this.get('adkToast');
        let vendor = this.store.createRecord('adcampaign-vendor', {
          AdCampaignProposalResponseId: adCampaignProposalResponseId,
          companyRole: record,
          status: "pending"
        });
        vendor.save().then(() => {
          this.get('loadVendorRolesTask').perform(this);
          adkToast.display("User proposed successfully", "warning", 3000);
        }, () => {
          adkToast.display("Failed to propose user", "error");
        });
      },
      removePerson(record) {
        let adkToast = this.get('adkToast');
        record.destroyRecord().then(() => {
          this.get('loadVendorRolesTask').perform(this);
          adkToast.display("User removed successfully", "warning", 3000);
        }, () => {
          adkToast.display("Failed to remove user", "error");
        });
      }
    }
  });
});