define("adtikir3v1/pods/components/adk-newad/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment", "ember-concurrency"], function (_exports, _adkDateTime, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { capitalize } from '@ember/string';
  // import {
  //   A
  // } from '@ember/array';
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    //inject store to be able to use it
    adkToast: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    powerCalendar: Ember.inject.service('powerCalendar'),
    estBudget: 0,
    estAgencyBudget: 0,
    estMediaBudget: 0,
    estMiscBudget: 0,
    adName: '',
    adPlayStartDate: '',
    adPlayStartCenter: '',
    adPlayEndDate: '',
    adPlayEndCenter: '',
    // Set up today's date as center for the calendar
    //currentDate: moment(new Date()).format('YYYY-MM-DD'),

    //This is for mediatype
    selectedMediaType: '',
    mediaTypeName: '',
    //End of mediatype

    //This is for mediaformat
    selectedMediaFormat: '',
    adColor: '',
    //End of mediaformat

    init() {
      this._super(...arguments);
      this.numberValidation = [{
        message: 'Please provide a valid value (99999999.99)',
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      this.campaignStartDate = '';
      this.set('palette', [["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"], ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"], ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"], ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"], ["#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"], ["#900", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"], ["#600", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]]);
    },
    actionSaveAdTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');

      //error checking here
      //need to check media type
      if (this.get('selectedMediaType') == '') {
        adkToast.display("Media type not selected, please select one", "error", 3000);
        return false;
      }
      yield this.saveNewAd({
        adCampaignId: this.get('adCampaign.id'),
        adName: this.get('adName'),
        description: this.get('description'),
        estBudget: this.get('estBudget'),
        estAgencyBudget: this.get('estAgencyBudget'),
        estMediaBudget: this.get('estMediaBudget'),
        estMiscBudget: this.get('estMiscBudget'),
        // adPic                  : 'assets/new-ads-default.png',
        adPic: this.get('selectedMediaType').iconMediaTypeLocation,
        mediatype: this.get('selectedMediaType').id,
        mediaformat: this.get('selectedMediaFormat').id,
        adPlayStartDate: new Date(this.get('adPlayStartDate')),
        adPlayEndDate: new Date(this.get('adPlayEndDate')),
        adMediaFormatDetails: this.get('adMediaFormatDetails'),
        workFlowStatusNumber: 0,
        // estimatedImpressions: newAd.estimatedImpressions,
        // mediaFees           : newAd.mediaFees,
        adColor: this.get('adColor')
        // selectedMediaComment: newAd.mediaformatcomment
      });
    }),
    mediaFormat: Ember.computed('selectedMediaType', function () {
      let returnVal = [];
      let mediaType = this.get('selectedMediaType');
      if (mediaType) {
        returnVal = mediaType.get('mediaFormats').toArray();
      }
      return returnVal;
    }),
    //mediaformat..

    didReceiveAttrs() {
      this._super(...arguments);
      this.get('formClear').call(this);
    },
    formClear: function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      this.set('adName', '');
      this.set('description', '');
      this.set('estBudget', 0);
      this.set('estAgencyBudget', 0);
      this.set('estMediaBudget', 0);
      this.set('estMiscBudget', 0);

      // let todayDate = this.get('adkSessionAccount').get('myToday');

      // this.set('mediatype', '');
      //this.set('selectedMediaTypeGraphic', '');
      //this.set('selectedMediaTypeGraphicIndex', '');
      this.set('selectedMediaType', '');
      this.set('mediaTypeName', '');
      let campaignStartDateLocal = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignStartDate'), true));
      let campaignEndDateLocal = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignEndDate'), true));
      let campaignProductLaunchDateLocal = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignProductLaunchDate'), true));
      this.set('campaignStartDateLocal', campaignStartDateLocal);
      this.set('campaignEndDateLocal', campaignEndDateLocal);
      this.set('campaignProductLaunchDateLocal', campaignProductLaunchDateLocal);
      this.set('adPlayStartDate', (0, _moment.default)(campaignStartDateLocal));
      this.set('adPlayStartDateLocal', (0, _moment.default)(campaignStartDateLocal));
      this.set('adPlayStartCenterLocal', (0, _moment.default)(campaignStartDateLocal));
      this.set('adPlayEndDate', (0, _moment.default)(campaignEndDateLocal));
      this.set('adPlayEndDateLocal', (0, _moment.default)(campaignEndDateLocal));
      this.set('adPlayEndCenterLocal', (0, _moment.default)(campaignEndDateLocal));

      //this.set('selectedMediaFormatGraphic', '');
      //this.set('selectedMediaFormatGraphicIndex', '');
      this.set('selectedMediaFormat', '');
      //this.set('mediaFormatName', '');

      this.set('workFlowStatusNumber', '');
      this.set('adColor', '#000000');
    },
    actions: {
      updateDate(thefield) {
        let adkToast = this.get('adkToast');
        let newDate = arguments[1].moment;
        let campaignStartDate = this.get('campaignStartDateLocal'); //assume is a string ('YYYY-MM-dd')
        let campaignEndDate = this.get('campaignEndDateLocal'); //assume is a string ('YYYY-MM-dd)
        let startDate = this.get('adPlayStartDateLocal'); //assume is a string ('YYYY-MM-dd')
        let endDate = this.get('adPlayEndDateLocal'); //assume is a string ('YYYY-MM-dd)

        let validStartDate = _adkDateTime.default.validDate(startDate);
        let validEndDate = _adkDateTime.default.validDate(endDate);
        if (!validStartDate) {
          startDate = '';
        }
        if (!validEndDate) {
          endDate = '';
        }
        let compareDate = _adkDateTime.default.compareDate(newDate, campaignStartDate);
        // console.log("component compare date: "+ compareDate)
        //check date > campaign start date or < campaign enddate
        if (compareDate < 0) {
          this.set(thefield, campaignStartDate);
          adkToast.display("Date not in " + this.intl.t("campaign") + " range, will not set it", "warning", 1500);
          return false;
        }
        let compareDate1 = _adkDateTime.default.compareDate(newDate, campaignEndDate);
        if (compareDate1 > 0) {
          this.set(thefield, campaignStartDate);
          adkToast.display("Date not in " + this.intl.t("campaign") + " range, will not set it", "warning", 1500);
          return false;
        }
        if (thefield != 'adPlayEndDate') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
              newDate = '';
              adkToast.display("Date not in range, will not set it", "warning", 2000);
            }
          } //endDate != ''
        }
        if (thefield != 'adPlayStartDate') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
              newDate = '';
              adkToast.display("Date not in range, check start date or end date, will not set it", "warning", 1000);
            }
          } //startdate != ''
        }
        if (newDate != '') {
          this.set(thefield + 'Local', (0, _moment.default)(newDate));

          // console.log("theField: ", thefield)
          let theActualField = thefield;
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          // console.log("newDateStr: ", newDateStr)
          this.set(theActualField, (0, _moment.default)(new Date(newDateStr)));
          // console.log("result: ", this.set(theActualField, moment(new Date(newDateStr))))
          adkToast.display("Date successfully set", "warning", 2000);
        }
      },
      //end of update

      checkCenter(thefield, centerField) {
        // let newCenterDate     = arguments[2].moment.format('YYYY-MM-DD');
        let campaignStartDate = this.get('campaignStartDateLocal'); //assume is a string ('YYYY-MM-dd')
        let campaignEndDate = this.get('campaignEndDateLocal'); //assume is a string ('YYYY-MM-dd)

        let newCenterDate = arguments[2].moment;
        // console.log("newwwwww: ", newCenterDate)
        // let campaignStartDate = this.get('adCampaign.campaignStartDate'); //assume is a string ('YYYY-MM-dd')
        // let campaignEndDate   = this.get('adCampaign.campaignEndDate'); //assume is a string ('YYYY-MM-dd)
        let adPlayStartDate = this.get('adPlayStartDateLocal');
        let adPlayEndDate = this.get('adPlayEndDateLocal');
        let validAdStartDate = _adkDateTime.default.validDate(adPlayStartDate);
        let validAdEndDate = _adkDateTime.default.validDate(adPlayEndDate);
        if (!validAdStartDate) {
          adPlayStartDate = '';
        }
        if (!validAdEndDate) {
          adPlayEndDate = '';
        }

        //adPlayStartDate  Check here

        if (thefield != 'adPlayEndDate') {
          //thefield is start date..
          let endDate = campaignEndDate;
          if (adPlayEndDate != '') {
            endDate = adPlayEndDate;
          }

          // console.log("newCenterDate: ", newCenterDate)
          // console.log("endDate: ", endDate)

          // console.log("adStartDate: ", (adkDateTime.compareYearMonth(newCenterDate, endDate) > 0), adkDateTime.compareYearMonth(newCenterDate, endDate))
          if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) > 0) {
            newCenterDate = endDate;
          }
          // console.log("adStartDate: ", (adkDateTime.compareYearMonth(newCenterDate, campaignStartDate) < 0), adkDateTime.compareYearMonth(newCenterDate, campaignStartDate))
          if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignStartDate) < 0) {
            newCenterDate = campaignStartDate;
          }
        }

        //adPlayEndDate
        if (thefield != 'adPlayStartDate') {
          let startDate = campaignStartDate;
          //  console.log("startDate: ", startDate)
          if (adPlayStartDate != '') {
            startDate = adPlayStartDate;
          }
          //compare with startDate
          // console.log("adPlayEndDate", (adkDateTime.compareYearMonth(newCenterDate, startDate) < 0), adkDateTime.compareYearMonth(newCenterDate, startDate))
          if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) < 0) {
            newCenterDate = startDate;
          }

          //  console.log("adPlayEndDate: ", (adkDateTime.compareYearMonth(newCenterDate, campaignEndDate) > 0), adkDateTime.compareYearMonth(newCenterDate, campaignEndDate))
          if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignEndDate) > 0) {
            newCenterDate = campaignEndDate;
          }
        }
        this.set(centerField + 'Local', newCenterDate);
      },
      //checkCenter()

      // Soon - This function is called from component - adk-imageselector to pass back the graphic record
      selectMediaTypeImage: function (itemModel) {
        this.set('selectedMediaType', itemModel);
        this.set('mediaTypeName', itemModel.mediaTypeName);
        this.set('selectedMediaFormat', '');
      },
      selectMediaFormatImage: function (imageName, itemModel) {
        this.set('selectedMediaFormat', itemModel);
      },
      calEstBudget(field, value) {
        let controller = this;
        this.set(field, value);
        function calTotal() {
          let a = parseFloat(controller.get("estAgencyBudget"));
          let b = parseFloat(controller.get("estMediaBudget"));
          let c = parseFloat(controller.get("estMiscBudget"));
          if (isNaN(a)) {
            a = 0;
          }
          if (isNaN(b)) {
            b = 0;
          }
          if (isNaN(c)) {
            c = 0;
          }
          controller.set("estBudget", a + b + c);
          //do something in here that will run in 2 seconds
        }
        Ember.run.scheduleOnce('actions', controller, calTotal);
      },
      actionAdCancel: function () {
        this.formClear();
        this.get('router').transitionTo('campaign.details.ads');
      }
    }
  });
});