define("adtikir3v1/pods/components/adkcard-addashboard-impression/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isEditing: false,
    adkToast: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        estimatedImpressions: this.ad.get('estimatedImpressions'),
        actualImpression: this.ad.get('actualImpression')
      });
    },
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      saveAdInfo() {
        // var ctrl = this;
        let ad = this.get('ad');
        this.toggleProperty('isEditing');
        // ad.save().then(onEditOK, onEditFailed);
        // this.dashboardImpressionSave({
        //   estimatedImpressions: this.get('estimatedImpressions'),

        //   actualImpression: this.get('actualImpression'),
        // })

        ad.set('estimatedImpressions', this.get('estimatedImpressions'));
        ad.set('actualImpression', this.get('actualImpression'));
        this.saveAction(true, ad);

        // function onEditOK() {
        //   let adkToast = ctrl.get('adkToast');

        //   // ctrl.resetForm();
        //   adkToast.display("Ad Info successfully updated", "warning");

        // };

        // function onEditFailed(){
        //   let adkToast = ctrl.get('adkToast');
        //   adkToast.display("Ad Info not saved", "error")

        // }
      }
    }
  });
});