define("adtikir3v1/pods/campaign/details/ads/ad/vendors/adclientbrief/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { computed } from '@ember/object';
  //import moment from "moment";
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    refresh: false,
    loadAAdRfpTask: (0, _emberConcurrency.task)(function* () {
      let ad = this.get('ad');
      let adRfp = yield this.store.query('a-ad-rfp', {
        ad: ad.id
      });
      if (adRfp.length > 0) {
        //should be 1 only
        this.set('adRfp', adRfp.get('firstObject'));
        return;
      }
      this.set('adRfp', this.store.createRecord('a-ad-rfp', {
        ad: ad
      }));
      this.set('isNew', true);
    }),
    //loadAdRfpTask

    saveAAdRfpTask: (0, _emberConcurrency.task)(function* () {
      let adRfp = this.get('adRfp');
      yield adRfp.save();
      if (this.isNew) {
        this.set('isNew', false);
      }
    }),
    //saveAdRfpTask;

    loadAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfp = this.get('adRfp');
      let attachments = yield this.store.query('ad-rfp-attachment', {
        aAdRfp: adRfp.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", attachments);
      //return attachments;
    }),
    saveAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adRfp = this.get('adRfp');
      let attachment = this.store.createRecord('ad-rfp-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        aAdRfp: adRfp
      });
      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        throw e;
      }
    }).drop(),
    cleanUp() {
      this.set('isNew', false);
      this.set('ageGroup', ['1-16', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']);
      this.set('campaignDescEnabled', true);
      this.set('productServicesEnabled', true);
      this.set('customerDescEnabled', true);
      this.set('marketChallengesEnabled', true);
      this.set('showAdRfpFlag', false);
      this.set('loadAAdRfpTaskInstant', this.loadAAdRfpTask.perform());
    },
    actions: {
      doNotShow: function () {
        this.toggleProperty('isShown');
      },
      showAdRfpAction: function () {
        this.set('showAdRfpFlag', true);
      },
      hideAdRfpAction: function () {
        this.set('showAdRfpFlag', false);
      },
      saveAAdRfpAction() {
        this.set('saveAAdRfpTaskInstant', this.saveAAdRfpTask.perform());
      }
    }
  });
});