define("adtikir3v1/pods/campaign/details/ads/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { Promise as EmberPromise, resolve} from 'rsvp';
  // import DS from 'ember-data'
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['gridlayout'],
    gridlayout: true,
    adGroupByType: Ember.computed('ads.@each.mediatype', function () {
      // let thisController = this;
      let ads = this.get('ads');
      let groups = Ember.Object.create();
      if (ads) {
        ads.forEach(function (ad) {
          let mediaType = ad.get('mediatype');
          console.log("group ad.get - mediaType: ", mediaType);
          if (mediaType) {
            if (!Ember.isPresent(groups.get(mediaType))) {
              groups.set(mediaType, Ember.A());
            }
            groups.get(mediaType).push(ad);
          }
        }); //forEach
      } //if ads
      return groups;
    }),
    actions: {
      setGridLayout(option) {
        this.set('gridlayout', option);
      }
    }
  });
});