define("adtikir3v1/pods/components/adk-vendor-proposal-agency-tbd/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import adkDateTime from '../../../utils/adk-date-time';
  // import moment from 'moment';
  // import {
  //   A
  // } from '@ember/array';
  // import {
  //   inject as service
  // } from '@ember/service';
  // import {
  //   alias
  // } from '@ember/object/computed';
  var _default = _exports.default = Ember.Component.extend({
    //   adkToast: service(),
    //   store: service(),
    //   sessionAccount: service(),
    //   rateDisplay: true,

    //   init() {

    //     this._super(...arguments);

    //     // this.set("validityDate", adkDateTime.convertDate(proposalResponse.get('validityDate')));
    //     // this.set("responseDate", adkDateTime.convertDate(proposalResponse.get('responseDate')));
    //     // this.set("deadlineProposal", adkDateTime.convertDate(proposal.get('deadlineProposal')));
    //     // let adcampaign = this.get('adcampaign');
    //     // let proposalResponse = this.get('proposalResponse');

    //     // let proposal = this.get("proposal");
    //     //  this.set("deadlineProposal", adkDateTime.convertDate(proposal.get('deadlineProposal')));

    //     //  let proposalInvitee = this.store.query('company', {orderBy: 'vendorPreQualified'});
    //     // console.log("adkcard-rfp-agency component: vendorPreQualified ",proposalInvitee);

    //   },

    //   didReceiveAttrs() {

    //     this.set('rateDisplay', true);
    //   },

    //   actions: {
    //     cancelRate() {
    //       let proposalResponse = this.get('proposalResponse');
    //       if (proposalResponse.hasDirtyAttributes) {
    //         proposalResponse.rollbackAttributes();
    //       }
    //       this.set('rateDisplay', true);
    //     },

    //     saveRate() {
    //       let sessionAccount = this.get('sessionAccount');
    //       let proposalResponse = this.get('proposalResponse'); 
    //       proposalResponse.set('ratingDate', new Date());
    //       proposalResponse.set('ratingRatedBy', sessionAccount.get('user').get('userName'));
    //       proposalResponse.set('ratingRatedByID', sessionAccount.get('user').get('id'));
    //       proposalResponse.save();
    //       this.set('rateDisplay', true);
    //     },

    //     setRateDisplay() {
    //       this.set('rateDisplay', false);
    //     },

    //   } //actions
  });
});