define("adtikir3v1/models/location-city", ["exports", "ember-data", "adtikir3v1/models/location"], function (_exports, _emberData, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = _location.default.extend({
    name: attr('string'),
    country: belongsTo('location-country'),
    state: belongsTo('location-state'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    type: 'City'
  });
});