define("adtikir3v1/pods/profile/company/management/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    loadCompanyRolesTask: (0, _emberConcurrency.task)(function* () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      try {
        let adkSessionAccount = this.get('adkSessionAccount');
        let myCompany = yield adkSessionAccount.get('myCompany');
        yield myCompany.reload();
        let companyRoles = yield myCompany.get('companyRoles');
        return companyRoles;
      } catch (e) {
        this.intermediateTransitionTo('error', e);
        throw e;
      }
    }).restartable(),
    loadInvitationsTask: (0, _emberConcurrency.task)(function* () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      try {
        let invitations = yield this.store.query('invitation', {});
        let oldInvitations = yield this.store.query('invitation', {
          filter: {
            type: 'old'
          }
        });
        return {
          invitations: invitations,
          oldInvitations: oldInvitations
        };
      } catch (e) {
        this.intermediateTransitionTo('error', e);
        throw e;
      }
    }).restartable(),
    init: function () {
      this._super(...arguments);
      this.setProperties({
        declineUser_col: [{
          propertyName: 'email',
          title: 'Email'
        }, {
          propertyName: 'role',
          title: 'Role'
        }, {
          propertyName: 'dateInvite',
          title: 'Date Invitation Sent'
        }, {
          propertyName: 'dateDeclined',
          title: 'Date Declined'
        }],
        showInvitationDialog: false
      });
    },
    actions: {
      saveInvitationAction: function (obj) {
        let adkToast = this.get('adkToast');
        let store = this.get('store');
        let thisController = this;
        let adkSessionAccount = this.get('adkSessionAccount');
        let myCompany = adkSessionAccount.get('myCompany');
        let invitation = store.createRecord('invitation');
        invitation.set('firstName', obj.firstName);
        invitation.set('lastName', obj.lastName);
        invitation.set('email', obj.email);
        invitation.set('role', obj.sendRole);
        invitation.set('note', obj.note);
        try {
          invitation.save().then(function () {
            thisController.loadInvitationsTask.perform();
            thisController.loadCompanyRolesTask.perform();
            if (obj.sendRole == "OWNER") {
              let ownerEmail = store.createRecord('email-group-owner');
              ownerEmail.set("CompanyId", myCompany.get('id'));
              ownerEmail.set("email", obj.email);
              ownerEmail.save();
            }
          });

          //this.model.;  //call model reload to refresh all latest status
        } catch (e) {
          if (e.code == 405) {
            adkToast.display(e.message, "error", 2000);
            return false;
          }
          throw e;
          //error happen, show on adkToast...  
        }
      }
    }
  });
});