define("adtikir3v1/pods/campaign/details/financials/invoicing/controller", ["exports", "ember-concurrency", "sanitize-html"], function (_exports, _emberConcurrency, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  //import { computed } from '@ember/object'; 
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    loadCampaignInvoiceTask: (0, _emberConcurrency.task)(function* (adCampaignId) {
      let result = yield this.store.query('adInvoice', {
        adCampaignId: adCampaignId
      });
      this.set("adCampaignInvoice", result);
    }),
    actions: {
      // loadInvoiceCampaigns(adCampaignId) {
      //   // console.log("loadInvoiceCampaigns: ", this.loadCampaignInvoiceTask.perform(adCampaignId))
      //   return this.loadCampaignInvoiceTask.perform(adCampaignId);
      // },

      createNewInvoice(componentValues, adCampaign) {
        let sanitizeDescription = (0, _sanitizeHtml.default)(componentValues.description);
        let sanitizeNotes = (0, _sanitizeHtml.default)(componentValues.notes);
        let adCampaignInvoice = this.store.createRecord('adCampaignInvoice', {
          invoiceNumber: componentValues.invoiceNumber,
          description: sanitizeDescription,
          invoiceAmount: componentValues.invoiceAmount,
          invoiceDoc: componentValues.invoiceDoc,
          notes: sanitizeNotes,
          defaultCurrency: componentValues.defaultCurrency,
          foreignCurrency: componentValues.foreignCurrency,
          submissionDate: componentValues.submissionDate,
          dueDate: componentValues.dueDate,
          createdAt: componentValues.createdAt
        });
        adCampaignInvoice.set('adCampaign', adCampaign);
        return adCampaignInvoice.save();
      }
    } //end of actions
  });
});