define("adtikir3v1/pods/home-office/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ["pageSize", "page", "query", "queryType", "homeOfficeViewIdx"],
    searchText: "",
    pageSize: 10,
    page: 0,
    homeOfficeViewIdx: 0,
    query: "",
    queryType: "",
    //homeOfficeView: ,
    currentView: "",
    currentTerritory: "",
    //aways set to full

    init() {
      this._super(...arguments);
      this.homeOfficeViewOptions = [{
        hoTask: "toDo",
        taskTitle: "Approvals"
      }, {
        hoTask: "byProduct",
        taskTitle: "By Product"
      }, {
        hoTask: "byTerritories",
        taskTitle: "Campaigns By Territories"
      }];
    },
    totalPage: Ember.computed("results.meta.page.total", "pageSize", function () {
      let total = 0;
      if (this.results && this.results.meta && this.results.meta.page && this.results.meta.page.total) {
        total = this.results.meta.page.total;
      }
      let pageSize = 1;
      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }
      total = Math.ceil(total / pageSize);
      return total;
    }),
    pageArray: Ember.computed("totalPage", function () {
      let totalPage = this.get("totalPage");
      if (totalPage == 0) return [];
      return Array.from(Array(totalPage).keys(), x => x + 1);
    }),
    steps: Ember.computed(function () {
      return [{
        element: "#campaignManager",
        intro: "Your authorized campaigns are all listed below."
      }, {
        element: "#agencyMenu",
        intro: Ember.String.capitalize(this.intl.t("agency")) + " Menu is visible for companies registered as " + this.intl.t("agency") + "."
      }, {
        element: ".companies",
        intro: "You can belong to multiple companies. Switch companies here."
      }, {
        element: "#addCompany",
        intro: "Add a new company here."
      }, {
        element: "#profile",
        intro: "Click here to change your profile and company settings."
      }, {
        element: "#page",
        intro: "Only 8 " + this.intl.t("campaign") + " are displayed at a time. Click here to see the next 8 or jump to any number."
      }, {
        element: "#addCampaign",
        intro: "Click here to create or add a new " + this.intl.t("campaign") + ".",
        position: "left"
      }];
    }),
    reset(needReset = "full") {
      this.set("searchText", "");
      this.set("page", 0);
      this.set("pageSize", 8);
      this.set("query", "");
      this.set("queryType", "");
      this.set("result", []);
      if (needReset === "full") {
        //this.set("homeOfficeViewIdx", 0);
        this.set("currentTerritory", "");
        this.set("currentView", "");
      }
    },
    loadCampaignsToDoTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 10) {
      try {
        //console.time("campaign model get");
        let results = yield this.get("store").query("home-office", {
          page: page,
          pageSize: pageSize,
          queryType: this.queryType,
          query: query
        }); //query

        //console.timeEnd("campaign model get");
        this.set("results", results);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadCampaignsSearchProductsTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 10) {
      try {
        // this.set('query', this.get('searchText'));
        let results = yield this.get("store").query("home-office", {
          page: page,
          pageSize: pageSize,
          query: query,
          queryType: this.queryType
        }); //query
        this.set("results", results);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadParentTerritoriesTask: (0, _emberConcurrency.task)(function* () {
      try {
        let parentTerritories = yield this.get("store").findAll("parent-territory");
        this.set("parentTerritories", parentTerritories);
        this.actions.changeTerritory.call(this, parentTerritories.get("firstObject"));
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadCampaignTerritoryTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 8) {
      try {
        let results = yield this.get("store").query("home-office", {
          page: page,
          pageSize: pageSize,
          queryType: this.queryType,
          query: query
        }); //query

        this.set("results", results);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    runQueryTask: (0, _emberConcurrency.task)(function* () {
      this.set('queryType', this.currentView.hoTask);
      if (this.currentView.hoTask === "toDo") {
        this.set('pageSize', 10);
        yield this.loadCampaignsToDoTask.perform("", this.page, this.pageSize);
      } else if (this.currentView.hoTask === "byProduct") {
        yield this.loadCampaignsSearchProductsTask.perform(this.query, this.page, this.pageSize);
      } else {
        // By Territories
        yield this.loadCampaignTerritoryTask.perform(this.query, this.page, this.pageSize);
      }
    }),
    actions: {
      setPage(number) {
        number = number - 1;
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }
        this.set("page", number);
        this.runQueryTask.perform();
      },
      nextPage() {
        this.actions.setPage.call(this, this.get("page") + 2);
        //this.actions.setPage.call(this, this.get('page') + 1);
      },
      prevPage() {
        this.actions.setPage.call(this, this.get("page"));
        //this.actions.setPage.call(this, this.get('page')-1);
      },
      searchMe() {
        let page = 0;
        this.set("query", this.get("searchText"));
        this.loadCampaignsSearchProductsTask.perform(this.query, page, this.pageSize);
      },
      clearSearch() {
        this.set("query", "");
        this.set("searchText", "");
        this.set("page", 0);
        this.runQueryTask.perform();
      },
      introComplete() {
        let me = this.get("adkSessionAccount").me;
        me.set("action", "updateTraining");
        me.set("training", "1");
        me.save();
      },
      // changeView(parentView) {
      changeView(needTransitionTo, newView) {
        // if (this.router.currentRouteName !== 'homeo-office') {
        //    this.router.transitionTo('home-office');
        // }
        this.reset();
        this.set("homeOfficeViewIdx", this.homeOfficeViewOptions.indexOf(newView));
        // this.set(
        //   "queryType",
        //   this.homeOfficeViewOptions[this.homeOfficeViewIdx].hoTask
        // );
        this.set("currentView", newView);

        // if (needTransitionTo) {
        //   this.router.transitionTo('home-office');
        // } else {
        if (this.currentView.hoTask === "byTerritories") {
          this.loadParentTerritoriesTask.perform();
        } else {
          this.set("query", "");
          this.set("searchText", "");
          this.set("currentTerritory", "");
          this.runQueryTask.perform();
        }
      },
      async changeTerritory(selectedTerritory) {
        this.set("currentTerritory", selectedTerritory);
        this.set("query", selectedTerritory.belongsTo("company").id());
        this.set("page", 0);

        //console.log(selectedTerritory);
        //console.log(selectedTerritory.get("company"));
        this.runQueryTask.perform();
      },
      createTemplate() {
        console.log("****######*****");
        console.log("campaign createTemplate");
        console.log("****######*****");
      },
      deleteCampaign(adcampaign) {
        adcampaign.destroyRecord();
        this.set("showCampaignMgrDialog", false);
      },
      openDialogDeleteCampaign(adcampaign, event) {
        this.set("adcampaign", adcampaign);
        this.set("dialogOrigin", event.currentTarget);
        this.set("showCampaignMgrDialog", true);
      },
      closeDialogDeleteCampaign() {
        this.set("showCampaignMgrDialog", false);
      },
      cancelAction() {}
    },
    cardlayout: false,
    selectedBudgetStatus: "approved",
    selectedAssetAction: "review"
  });
});