define("adtikir3v1/pods/campaign/details/targeting/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let targetingRoute = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    let adCampaignDetailModel = yield this.modelFor('campaign.details');
    let industryType = yield this.get('store').findAll('industry-type');
    let targeting = yield adCampaignDetailModel.adCampaignDetail.get('targeting');
    let languageOption = yield this.get('store').findAll('language');
    let countries = yield this.store.query('location-country', {
      date: new Date().getTime()
    });
    let locations = yield this.store.query('location', {
      adCampaignTargeting: adCampaignDetailModel.adCampaign.id
    });
    if (!targeting) {
      let targetingRecord = yield this.store.createRecord('targeting', {
        adCampaignDetail: adCampaignDetailModel.adCampaignDetail
      });
      this.set("targeting", targetingRecord);
      targeting = this.get("targeting");
    }
    return {
      industryType: industryType.toArray(),
      countries: countries.toArray(),
      languageOption: languageOption.toArray(),
      adCampaign: adCampaignDetailModel.adCampaign,
      adCampaignDetail: adCampaignDetailModel.adCampaignDetail,
      targeting: targeting,
      locationList: locations.toArray()
    };
  }).restartable(), (_class = class targetingRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);
      _initializerDefineProperty(this, "loadModelTask", _descriptor3, this);
      _defineProperty(this, "actions", {
        willTransition: function () {
          this.controller.set('editDemographicsMode', false);
          this.controller.set('editAdvancedMode', false);

          // When user is in edit mode, and click to other route, it will rollback attributes
          if (this.controller.get('adCampaign').hasDirtyAttributes) {
            this.controller.get('adCampaign').rollbackAttributes();
          }
          if (this.controller.get('adCampaignDetail').hasDirtyAttributes) {
            this.controller.get('adCampaignDetail').rollbackAttributes();
          }
          if (this.controller.get('targeting').hasDirtyAttributes) {
            this.controller.get('targeting').rollbackAttributes();
          }

          // rollback relationship data （languages and industry)
          this.controller.get('adCampaignDetail').rollback();
        }
      });
    }
    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        this.intermediateTransitionTo('error');
      }
    }
    setupController(controller, model) {
      this._super(controller, model);
      let store = this.store;
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('industryType', model.industryType);
      controller.set('targeting', model.targeting);
      controller.set('countries', model.countries);
      controller.set('languageOption', model.languageOption);
      controller.set('locationList', model.locationList);
      let defaultLanguages = ['en', 'ja', 'fr', 'zh', 'de'];
      controller.set('defaultLanguagesOption', defaultLanguages.map(function (lang) {
        return store.peekRecord('language', lang);
      }));

      // controller.set('targetIndustries',model.targetIndustries);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadModelTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});