define("adtikir3v1/pods/components/adk-card-campaign-portrait/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7t6hfHB3",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[2,\"\\nCopyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.\\nThis software is protected by Copyright laws and use is subject to license agreement\\navailable at adtiki.com\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-card-campaign-portrait/template.hbs"
    }
  });
});