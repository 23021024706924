define("adtikir3v1/pods/campaign/details/ads/ad/financials/invoice/controller", ["exports", "adtikir3v1/themes/bootstrap4", "ember-concurrency"], function (_exports, _bootstrap, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    bootstrap4Theme: _bootstrap.default.create(),
    loadAdInvoiceTask: (0, _emberConcurrency.task)(function* (adId) {
      let result = yield this.store.query('adInvoice', {
        adId: adId
      });
      this.set("adInvoice", result);
    }),
    init() {
      this._super(...arguments);
      this.set('newInvoiceFlag', false);
      this.invoiceTableColumn = [{
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "title": "Work Flow",
        "propertyName": "workFlowStep"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Notes",
        "propertyName": "notes"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate",
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }, {
        "title": "Status",
        "component": "invoiceApproveComponent"
      }];
      this.invoiceTable2Column = [{
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "title": "Work Flow",
        "propertyName": "workFlowStep"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Account To Bill",
        "propertyName": "accountNumber"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate",
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Approval Notes",
        "propertyName": "statusLog"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }];
      this.invoiceTable3Column = this.invoiceTable2Column.concat();
      this.invoiceTable3Column[8] = {
        "title": "Rejected Comments",
        "propertyName": "statusLog"
      };
      this.resetForm();
    },
    //init

    // didReceiveAttrs() {
    //   this._super(...arguments);
    //   this.loadAdInvoiceTask.perform(this.get('ad').id);
    // },

    newAdInvoices: Ember.computed('adInvoice.@each.status', function () {
      let adInvoices = this.get('adInvoice');
      let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
      if (!adInvoices) {
        return Ember.A();
      }
      return adInvoices.filterBy('status', 'new').filterBy('companyName', companyName);
    }),
    submittedAdInvoices: Ember.computed('adInvoice.@each.status', function () {
      let adInvoices = this.get('adInvoice');
      if (!adInvoices) {
        return Ember.A();
      }
      return adInvoices.filterBy('status', 'submitted');
    }),
    approvedAdInvoices: Ember.computed('adInvoice.@each.status', function () {
      let adInvoices = this.get('adInvoice');
      if (!adInvoices) {
        return Ember.A();
      }
      return adInvoices.filterBy('status', 'approved');
    }),
    rejectedAdInvoices: Ember.computed('adInvoice.@each.status', function () {
      let adInvoices = this.get('adInvoice');
      if (!adInvoices) {
        return Ember.A();
      }
      return adInvoices.filterBy('status', 'rejected');
    }),
    actions: {
      createNewAdInvoice(componentValues, ad) {
        let adkToast = this.get('adkToast');
        let adInvoice = this.store.createRecord('adInvoice', {
          workFlowStep: componentValues.workFlowStep,
          invoiceNumber: componentValues.invoiceNumber,
          description: componentValues.description,
          invoiceAmount: componentValues.invoiceAmount,
          agencyCost: componentValues.agencyCost,
          mediaCost: componentValues.mediaCost,
          miscCost: componentValues.miscCost,
          invoiceDoc: componentValues.invoiceDoc,
          notes: componentValues.notes,
          submissionDate: componentValues.submissionDate,
          dueDate: componentValues.dueDate,
          createdAt: componentValues.createdAt
        });
        adInvoice.set('ad', ad);
        adInvoice.save().then(() => {
          this.loadAdInvoiceTask.perform(this.get('ad').id);
          adkToast.display("New Invoice added successfully", "warning", 3000);
        });
        // console.log("3", this.loadAdInvoiceTask.perform(this.get('ad').id))
        // this.actions.loadAdInvoices.apply(this);
        // this.loadAdInvoiceTask.perform(this.get('ad').id);
        // adkToast.display("New Invoice added successfully", "warning", 3000);
      },
      newInvoice() {
        this.set('newInvoiceFlag', true);
      },
      closeDialog: function () {
        this.set('newInvoiceFlag', false);
      }
    },
    resetForm() {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      this.set('workFlowStep', '');
      this.set('invoiceNumber', '');
      this.set('description', '');
      this.set('invoiceAmount', '');
      this.set('invoiceDoc', '');
      this.set('notes', '');
      this.set('submissionDate', '');
      this.set('dueDate', todayDate);
      this.set('dueDateCenter', todayDate);
    }
  });
});