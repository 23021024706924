define("adtikir3v1/pods/components/adk-projectmgmt-invoice/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment", "ember-concurrency", "adtikir3v1/themes/bootstrap4"], function (_exports, _adkDateTime, _moment, _emberConcurrency, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    bootstrap4Theme: _bootstrap.default.create(),
    invoiceNumber: '',
    description: '',
    invoiceAmount: 0,
    notes: '',
    resetForm: function () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      this.set('invoiceNumber', '');
      this.set('description', '');
      this.set('invoiceAmount', 0);
      this.set('notes', '');
      this.set('invoiceDoc', '');
      this.set('accountNumber', '');
      this.set('dueDate', todayDate);
      this.set('dueDateCenter', todayDate);
    },
    init() {
      this._super(...arguments);
      this.set('newInvoiceFlag', false);
      this.numberValidation = [{
        message: "Please provide a valid value (99999999.99)",
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      let component = this;
      let adkSessionAccount = this.get('adkSessionAccount');
      let company = adkSessionAccount.get('myCompany');
      company.get('myCompanyDetail').then(function (myCompanyDetail) {
        myCompanyDetail.get('financialAccounts').then(function (financialAccounts) {
          component.set('financialAccounts', financialAccounts);
        });
      });
      this.set('component', component);
      this.invoiceTableColumn = [{
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Notes",
        "propertyName": "notes"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": 'dueDate',
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }];
      this.invoiceTable2Column = this.invoiceTableColumn.concat();
      this.invoiceTable2Column[8] = {
        "title": "Status",
        "component": "invoiceApproveComponent"
      };
      this.invoiceTable3Column = [{
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Notes",
        "propertyName": "notes"
      }, {
        "title": "Approval Comments",
        "propertyName": "statusLog"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate",
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }];

      //merge the previous column table and replace the specific column at column [7];
      this.invoiceTable4Column = this.invoiceTable3Column.concat();
      this.invoiceTable4Column[5] = {
        "title": "Rejected Comments",
        "propertyName": "statusLog"
      };
      this.resetForm();
    },
    didReceiveAttrs() {
      // this.loadInvoiceTask.perform();
      // this.set('loadInvoiceFunc', this.get('loadInvoiceTask').perform(this));
      // this.set('loadNewInvoiceFunc', this.get('loadNewInvoiceTask').perform(this));
      this.loadNewInvoiceTask.perform(this);
      // this.loadNewInvoiceTask.perform(this.get('vProposalAgency').id);
    },
    newInvoices: Ember.computed('vAdCampaignInvoice.@each.status', function () {
      let vAdCampaignInvoice = this.get('vAdCampaignInvoice');
      let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
      if (!vAdCampaignInvoice) {
        return Ember.A();
      }
      return vAdCampaignInvoice.filterBy('status', 'new').filterBy('companyName', companyName);
    }),
    submittedInvoices: Ember.computed('vAdCampaignInvoice.@each.status', function () {
      let vAdCampaignInvoice = this.get('vAdCampaignInvoice');
      let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
      if (!vAdCampaignInvoice) {
        return Ember.A();
      }
      return vAdCampaignInvoice.filterBy('status', 'submitted').filterBy('companyName', companyName);
    }),
    approvedInvoices: Ember.computed('vAdCampaignInvoice.@each.status', function () {
      let vAdCampaignInvoice = this.get('vAdCampaignInvoice');
      let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
      if (!vAdCampaignInvoice) {
        return Ember.A();
      }
      return vAdCampaignInvoice.filterBy('status', 'approved').filterBy('companyName', companyName);
    }),
    rejectedInvoices: Ember.computed('vAdCampaignInvoice.@each.status', function () {
      let vAdCampaignInvoice = this.get('vAdCampaignInvoice');
      let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
      if (!vAdCampaignInvoice) {
        return Ember.A();
      }
      return vAdCampaignInvoice.filterBy('status', 'rejected').filterBy('companyName', companyName);
    }),
    saveInvoice: (0, _emberConcurrency.task)(function* () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      let adkToast = this.get('adkToast');
      yield this.saveNewInvoice({
        invoiceNumber: this.get('invoiceNumber'),
        description: this.get('description'),
        invoiceAmount: this.get('invoiceAmount'),
        agencyCost: this.get('agencyCost'),
        invoiceDoc: this.get('invoices'),
        notes: this.get('notes'),
        submissionDate: new Date(this.set('submissionDate', todayDate)),
        dueDate: this.get('dueDate').toDate(),
        createdAt: new Date()
      }, this.get('vProposalAgency')).then(() => {
        this.actions.closeDialog.apply(this);
        this.loadNewInvoiceTask.perform(this);
        adkToast.display("New Invoice added successfully", "warning", 3000);
      });
      // this.actions.closeDialog.apply(this);
      // this.loadNewInvoiceTask.perform(this.get('vProposalAgency').id);
      // this.loadNewInvoiceTask.perform();
      // adkToast.display("New Invoice added successfully", "warning", 3000);
      this.resetForm();
    }),
    actions: {
      uploadInvoiceDoc: function () {
        //do nothing
        return;
      },
      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let newDate = selected.moment;
        let dueDate = this.get('dueDate');
        if (theField != 'dueDate') {
          if (dueDate != '') {
            newDate = '';
          }
        }
        if (newDate != '') {
          this.set(theField, newDate);

          // let theActualField = theField.substring(0, theField.length - 5);

          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theField, (0, _moment.default)(new Date(newDateStr)));
          adkToast.display("Date successfully set", "warning", 2000);
        }
      },
      checkCenter(theField, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let dueDate = this.get('dueDate');
        if (theField != 'dueDate') {
          if (dueDate != '') {
            if (_adkDateTime.default.compareYearMonth(newCenterDate, dueDate) <= 0) {
              newCenterDate = dueDate;
            }
          }
        }
        this.set(centerField, newCenterDate);
      },
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      newInvoiceUpload() {
        this.set('newInvoiceFlag', true);
      },
      closeDialog: function () {
        this.set('newInvoiceFlag', false);
        this.resetForm();
      },
      calCostTotal(field, value) {
        let controller = this;
        this.set(field, value);
        function calTotal() {
          let a = parseFloat(controller.get("agencyCost"));
          if (isNaN(a)) {
            a = 0;
          }
          controller.set("invoiceAmount", a);
        }
        Ember.run.scheduleOnce('actions', controller, calTotal);
      }
    }
  });
});