define("adtikir3v1/pods/components/select-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      clickOnRow(index, record) {
        this.set('record.isSelected', !record.isSelected);
      }
    }
  });
});