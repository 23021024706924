define("adtikir3v1/pods/components/adk-companyinfo-add-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
      this.setProperties({
        showGlobalFilter: false,
        useFilteringByColumns: false,
        showComponentFooter: false,
        showColumnsDropdown: false,
        editMode: false,
        showInvitationDialog: false,
        sendRole: 'OWNER'
      });
      this.setProperties({
        pendingUser_col: [{
          propertyName: 'email',
          title: 'Email'
        }, {
          propertyName: 'role',
          title: 'Role'
        }, {
          propertyName: 'firstName',
          title: 'First Name'
        }, {
          propertyName: 'lastName',
          title: 'Last Name'
        }, {
          propertyName: 'note',
          title: 'Note'
        }, {
          propertyName: 'expiredDate',
          title: 'Expired Date',
          editable: false,
          component: 'date-display'
        }, {
          propertyName: 'expiredDay',
          title: 'Expired Day'
        }, {
          propertyName: 'inviterFirstName',
          title: 'Inv. First Name'
        }, {
          propertyName: 'inviterLastName',
          title: 'Inv. Last Name'
        }, {
          propertyName: 'status',
          title: 'Status'
        }, {
          propertyName: 'createdAt',
          title: 'Date Invitation Sent',
          editable: false,
          component: 'date-display'
        }],
        emailValidation: [{
          message: 'Please provide email in a valid format',
          validate: inputValue => {
            let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailPattern.test(inputValue);
          }
        }],
        //emailValidation        
        roleOptions: ['OWNER', 'MANAGER', 'USER']
      });
    },
    resetData() {
      this.set('email', '');
      this.set('firstName', '');
      this.set('lastName', '');
      this.set('sendRole', 'OWNER');
      this.set('note', '');
    },
    actions: {
      openAddInvitationDialog(ev) {
        this.resetData();
        this.set('dialogOrigin', ev.currentTarget);
        this.set('showInvitationDialog', true);
      },
      closeAddInvitationDialog() {
        this.set('showInvitationDialog', false);
        //this.cancelAction(); 
        //this.resetCopy();
      },
      insertAddInvitation() {
        this.set('showInvitationDialog', false);
        // this.myCompany.set('currency',this.currency);
        // this.myCompany.set('foreignCurrency',this.foreignCurrency);
        // this.myCompany.save();
        this.saveAction({
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          sendRole: this.sendRole,
          note: this.note
        });
      }
    }
  });
});