define("adtikir3v1/helpers/chain", ["exports", "@abcum/ember-helpers/helpers/chain"], function (_exports, _chain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "chain", {
    enumerable: true,
    get: function () {
      return _chain.chain;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _chain.default;
    }
  });
});