define("adtikir3v1/pods/campaign/details/vendor/media/rfp/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from 'moment';
  // import adkDateTime from 'adtikir3v1/utils/adk-date-time';
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    sentPanelFlag: true,
    listRefresh: false,
    init() {
      this._super(...arguments);
      this.cleanUp();
      this.set('firstTime', true);
      this.set('mediaInviteeList', []);
    },
    //init

    loadAdCampaignMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpId = this.get('adCampaignMediaRfp').id;
      let adCampaignMediaRfpBodies = yield this.store.query('ad-campaign-media-rfp-body', {
        adCampaignMediaRfp: adCampaignMediaRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adCampaignMediaRfpBodies);
      //return attachments;
    }),
    //loadAdCampaignMediaRfpBodyListTask

    loadAdCampaignMediaProposalRequestListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let adCampaignProposalRequestListObj = this.get('adCampaignProposalRequestList');

      let result = yield this.store.query('ad-campaign-media-proposal-request-list', {
        adCampaignMediaRfpBody: this.adCampaignMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadAMediaProposalInviteeTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeObj = this.get('aProposalInvitee');
      let result = yield this.store.query('a-media-proposal-invitee', {
        adCampaignMediaProposalRequestList: this.get('mediaRespondList').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadAMediaProposalInviteeByCompanyTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeByCompanyObj = this.get('aProposalInviteeByCompany');    
      let result = yield this.store.query('a-media-proposal-invitee', {
        adCampaignMediaRfpBody: this.get('adCampaignMediaRfpBody').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    loadMediaInviteeListPageObjTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let inviteeListObj = this.get('createInviteeList');
      let mediaInviteeList = yield this.get('mediaInviteeList');
      Ember.set(pagingObj, 'result', mediaInviteeList);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  

    mediaInviteeListSearch(vendorCompanyId) {
      let mediaInviteeList = this.get('mediaInviteeList');
      return mediaInviteeList.findIndex(obj => {
        if (obj.id === vendorCompanyId) {
          return true;
        }
      });
    },
    saveMediaInviteeListTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let adkToast = this.get('adkToast');
      let mediaInviteeList = this.get('mediaInviteeList');
      let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      let mediaProposalRequestList = store.createRecord('adCampaignMediaProposalRequestList', {
        countOfList: 0,
        adCampaignMediaRfpBody: adCampaignMediaRfpBody
      });
      try {
        yield mediaProposalRequestList.save();
      } catch (e) {
        //if this fail... exit
        adkToast.display("Cannot create proposal request list", "error", 3000);
        this.set("inviteeList", []);
        this.set('showDialogCheckout', false);
        console.error(e);
        return false;
      }
      for (let i = mediaInviteeList.length - 1; i >= 0; i--) {
        let invitee = store.createRecord('aMediaProposalInvitee', {
          isPrefer: mediaInviteeList[i].isPrefer,
          company: mediaInviteeList[i].vendor,
          adCampaignMediaProposalRequestList: mediaProposalRequestList
        });
        //put try here... if error occur.. staill can move to next invitee
        try {
          yield invitee.save();
          mediaInviteeList.splice(i, 1); //by the end.. the inviteeList should be empty
        } catch (e) {
          adkToast.display("Invitee " + invitee.vendor.companyName + " cannot be saved", "error", 2000);
          console.error(e);
        }
        //adkToast display tq note..     
      } //for

      if (mediaInviteeList.length != 0) {
        adkToast.display("That is " + mediaInviteeList.length + " invitee(s) cannot be saved", "error", 3000);
      }

      //clear everything.. 
      this.set('mediaInviteeList', []);
      this.set('showDialogCheckout', false);
      //reload requestList
      Ember.set(this, "listRefresh", true);
      Ember.set(this.adCampaignMediaProposalRequestList, "refresh", true);
      Ember.set(this.aMediaProposalInviteeByCompany, "refresh", true);
      //done

      //first..
    }),
    //saveMediaInviteeListTask

    cleanUp: function () {
      this.set('adCampaignMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdCampaignMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('adCampaignMediaRfpBody', null);
      this.set('firstTime', false);
      this.set('showDialogInvitee', false);
      this.set('respondList', null);
      this.set('aMediaProposalInvitee', {});
    },
    //actions section start

    actions: {
      openDialogInvitee(respondList) {
        this.set('showDialogInvitee', true);
        this.set('mediaRespondList', respondList);
        this.set('aMediaProposalInvitees', {
          page: 0,
          pageSize: 10,
          result: null,
          action: this.get('loadAMediaProposalInviteeTask'),
          refresh: false,
          type: "emberdata"
        });
      },
      closeDialogInvitee() {
        this.set('showDialogInvitee', false);
        this.set('respondList', null);
        this.set('aMediaProposalInvitees', {});
      },
      openDialogCheckout() {
        let adkToast = this.get('adkToast');
        let mediaInviteeList = this.get('mediaInviteeList');
        if (mediaInviteeList.length == 0) {
          //alert("Please select some companies first");
          adkToast.display('Please select a vendor first', "error", 2000);
          return false;
        }
        if (!this.get('createInviteeList')) {
          this.set('createInviteeList', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadCreateInviteeListTask"),
            refresh: false,
            type: "array"
          });
        }
        this.set('showDialogCheckout', true);
      },
      closeDialogCheckout() {
        this.set('showDialogCheckout', false);
      },
      setAdCampaignMediaRfpBody: function (adCampaignMediaRfpBody) {
        let mediaInviteeList = this.get('mediaInviteeList');
        if (mediaInviteeList && mediaInviteeList.length > 0) {
          let answer = confirm("You have selection in the list, do you want to continue? it will clear the list");
          if (!answer) {
            return;
          }
        }
        this.set('mediaInviteeList', []);
        this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
        this.set('adCampaignMediaProposalRequestList', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAdCampaignMediaProposalRequestListTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('aMediaProposalInviteeByCompany', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAMediaProposalInviteeByCompanyTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('byBatch', true);
      },
      //setAdCampaignMediaRfpBody

      toggleByBatch() {
        this.set("byBatch", !this.get('byBatch'));
        if (this.firstTime) {
          this.set('firstTime', false);
        }
      },
      // loadMediaVendor: function (pageNo, pageSize) {
      //   return this.loadMediaVendorTask.perform(pageNo, pageSize);
      // },

      addList(isPrefer, company) {
        let adkToast = this.get('adkToast');
        let mediaInviteeList = this.get('mediaInviteeList');
        if (this.mediaInviteeListSearch(company.id) == -1) {
          mediaInviteeList.pushObject({
            id: company.id,
            isPrefer: isPrefer,
            vendor: company
          });
          adkToast.display("Vendor " + company.companyName + " added to the list", "warning", 1500);
        } else {
          adkToast.display("Vendor " + company.companyName + " already in the list", "error", 1500);
        }
        if (!this.get('mediaInviteeListPageObj')) {
          this.set('mediaInviteeListPageObj', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadMediaInviteeListPageObjTask"),
            refresh: false,
            type: "array"
          });
        }
      },
      //addList

      removeList(idx) {
        let mediaInviteeList = this.get('mediaInviteeList');
        //let index = this.inviteeListSearch(vendor.id);
        //if (index > -1) {
        this.set('mediaInviteeList', mediaInviteeList.slice(0, idx).concat(mediaInviteeList.slice(idx + 1, mediaInviteeList.length)));
        let mediaInviteeListPageObj = this.get("mediaInviteeListPageObj");
        Ember.set(mediaInviteeListPageObj, "refresh", true);
        if (mediaInviteeList.length == 1) {
          this.set('showDialogCheckout', false);
        }
      } //removeList    

      // clickRefresh() {
      //   //this.listRefresh=true;
      //   set(this, 'listRefresh', true);
      // }
    }
  });
});