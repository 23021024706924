define("adtikir3v1/models/portfolio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    companyName: attr('string'),
    logo: attr('string'),
    activities: attr('string'),
    agency: belongsTo('agency', {
      inverse: null
    }),
    mediaCompany: belongsTo('mediaCompany', {
      inverse: null
    })
  });
});