define("adtikir3v1/pods/components/adk-display-vendor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K8rNoTRY",
    "block": "{\"symbols\":[\"card\",\"mediaType\"],\"statements\":[[4,\"paper-card\",null,[[\"class\"],[\"md-padding\"]],{\"statements\":[[7,\"h2\",true],[8],[0,\"Vendor Detail Info\"],[9],[0,\"\\n  is preferred: \"],[1,[22,\"preferredFlag\"],false],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[24,[\"vendor\",\"id\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[24,[\"vendor\",\"company\",\"companyName\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"vendor\",\"mediaTypes\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,2,[\"mediaTypeName\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n  \"],[5,\"paper-button\",[],[[\"@class\",\"@raised\",\"@onClick\"],[\"adkEditButton\",true,[28,\"action\",[[23,0,[]],[24,[\"closeFunction\"]]],null]]],{\"statements\":[[0,\"\\n    Close\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-display-vendor/template.hbs"
    }
  });
});