define("adtikir3v1/models/my-company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    companyName: attr('string'),
    companyNameLocal: attr('string'),
    url: attr('string'),
    briefDescription: attr('string'),
    country: attr('string'),
    email: attr('string'),
    // locale               : attr('string'),
    currency: attr('string'),
    foreignCurrency: attr('string'),
    exchangeRateType: attr('string'),
    exchangeRate: attr('number'),
    exchangeRateDate: attr('date'),
    logo: attr('string'),
    // languages            : attr('string'),
    // ratings              : attr('string'),
    // portfolio            : attr('string'),
    isMedia: attr('boolean'),
    isAgency: attr('boolean'),
    memberType: attr('string'),
    // preQualified      : DS.attr('boolean'),

    // Set up relationship between advertiser and preQualified vendor
    //preQualifiedVendors   : hasMany('company', {inverse: null}),   
    //    proposalInviteds      : hasMany('proposal-invited'), 
    // marketCompany        : DS.belongsTo('company', {inverse: null}), 
    // vendorPreQualified: DS.hasMany('company', {inverse: 'advertiser'}),
    // advertiser        : DS.belongsTo('company', {inverse: 'vendorPreQualified'}),

    //Set up different type of companies
    //  agency                : DS.belongsTo('company', {inverse: 'agency'}),
    //  mediaCompany          : DS.belongsTo('company', {inverse: 'mediaCompany'}),

    //roles and user in company //only available in owner mode
    //
    industries: hasMany('industry-type'),
    invitations: hasMany('invitation'),
    myRole: belongsTo('my-role', {
      async: true
    }),
    companyRoles: hasMany('company-role', {
      async: true
    }),
    myCompanyDetail: belongsTo('my-company-detail', {
      async: true
    }),
    //company status - active, deactivated, outstandingacnt, banned
    status: attr('string'),
    updatedAt: attr('date'),
    createdAt: attr('date'),
    //to be settle later
    //adCampaigns          : hasMany('ad-campaign', {async: true})

    countryObj: belongsTo('location-country'),
    // owner right  
    function() {
      let right = {
        companyNameEditRight: true,
        companyNameLocalEditRight: true,
        urlEditRight: true,
        briefDescriptionEditRight: true,
        countryEditRight: true,
        emailEditRight: true,
        isMediaEditRight: true,
        isAgencyEditRight: true,
        industriesEditRight: true
      };
      return right;
    }
    // manager right

    //user right

    //client right

    //campaign agency right
  });
});