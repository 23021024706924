define("adtikir3v1/pods/components/adk-dashboard-govapproval/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import config from '../../../config/environment';
  //import $ from 'jquery';

  const titleStr = 'Government approval document request deadline';
  var _default = _exports.default = Ember.Component.extend({
    titleStr: titleStr,
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('approvalRequiredGov', false);
      this.set('showDeadlineForm', true);
      this.set('deadlineAdRelatedField', 'approvedGovRequestDate');
    },
    img: Ember.computed('ad.approvedGovCertificate', function () {
      let ad = this.get('ad');
      return ad.approvedGovCertificate;
    }),
    showWorkflowDeadlineForm: Ember.computed('approvalRequiredGov', function () {
      if (this.get('approvalRequiredGov') === true) return true;else return false;
    }),
    acWorkFlowOptions: Ember.computed('acWorkFlows', function () {
      let array = [];
      let acWorkFlows = this.get('acWorkFlows');
      acWorkFlows.forEach(function (item, index) {
        if (item.isSelected && item.id != acWorkFlows.length - 1) {
          array.pushObject({
            index: index,
            wflowstatus: item.workFlowStatus
          });
        }
      });
      return array;
    }),
    govApprovalDeadline: Ember.computed('ad.approvedGovRequestDate', function () {
      let ad = this.get('ad');
      return this.store.findRecord('ad-deadline', ad.approvedGovRequestDate);
    }),
    resetForm: function () {
      this.set('editFlag', false);
      this.set('theDeadline', null);
    },
    actions: {
      closeDialog() {
        this.set('openUploadFile', false);
      },
      updateAdPic: function (url) {
        let ad = this.get('ad');
        ad.set('approvedGovCertificate', url);
        ad.set('action', "updateApprovedGovCertificate");
        ad.save();
      },
      triggerCancelForm: function () {
        this.set('approvalRequiredGov', false);
      },
      uploadImage(attrs) {
        if (attrs.filesId.length != 0) {
          let ad = this.get('ad');
          ad.set('approvedGovCertificate', attrs.filesId[0]);
          ad.save();
        }
      },
      addNewDeadline(theDeadLine) {
        theDeadLine.set('ad', this.get('ad'));
        let theComponent = this;
        return this.get('saveDeadlinesAction')(theDeadLine).then(function () {
          theComponent.resetForm();
          theComponent.set('showDeadlineForm', false);
          theComponent.set('approvalRequiredGov', false);
          theComponent.get('ad').reload();
          theComponent.set('refreshGlobal', new Date().getTime());
        });
      }
    }
  });
});