define("adtikir3v1/pods/components/adkcard-addashboard-media/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    //Injected store to be able to use it
    oldFilename: Ember.computed('img', function () {
      let img = this.get('img');
      let oldFilename = img.split('/').pop();
      return oldFilename;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        filesId: []
      });
    },
    actions: {
      closeDialog() {
        this.set('openUploadFile', false);
      },
      updateAdPic: function (url) {
        let ad = this.get('ad');
        ad.set('adPic', url);
        ad.save();
      }
    }
  });
});