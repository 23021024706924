define("adtikir3v1/pods/agency/admanager/ad/financials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UaQNbp7K",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex layout-column\"],[8],[0,\"\\n\"],[4,\"paper-tabs\",null,[[\"class\",\"center\",\"stretch\",\"borderBottom\",\"selected\",\"onChange\"],[\"adkAdInvoiceTabBar\",[24,[\"center\"]],[24,[\"stretch\"]],[24,[\"borderBottom\"]],[24,[\"selectedBasicTab\"]],[28,\"action\",[[23,0,[]],\"noop\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('adtikir3v1/pods/agency/admanager/ad/financials/template.hbs' @ L10:C7) \"],null]],[[\"href\"],[[28,\"href-to\",[\"agency.admanager.ad.financials.invoice\",[24,[\"adAgency\",\"id\"]]],null]]],{\"statements\":[[0,\"      Invoice\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('adtikir3v1/pods/agency/admanager/ad/financials/template.hbs' @ L14:C7) \"],null]],[[\"href\"],[[28,\"href-to\",[\"agency.admanager.ad.financials.account\",[24,[\"adAgency\",\"id\"]]],null]]],{\"statements\":[[0,\"      Account\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex layout-column\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/agency/admanager/ad/financials/template.hbs"
    }
  });
});