define("adtikir3v1/pods/media/admanager/ad/financials/account/controller", ["exports", "adtikir3v1/themes/bootstrap4"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object';
  // import { A } from '@ember/array';
  // import { task } from 'ember-concurrency';
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    bootstrap4Theme: _bootstrap.default.create()

    // loadAdInvoiceTask: task(function* (adId){
    //   let result = yield this.store.query('vAdInvoice',{
    //     adId: adId
    //   });

    //   this.set("adInvoice", result);
    // }),

    // init() {
    //     this._super(...arguments);
    //     this.set('newInvoiceFlag', false);

    //     this.invoiceTableColumn = [{
    //         "title": "Invoice Number",
    //         "propertyName": "invoiceNumber"
    //       },
    //       {
    //         "title": "Work Flow",
    //         "propertyName": "workFlowStep"
    //       },
    //       {
    //         "className": "adkTable24Width",
    //         "propertyName": "description"
    //       },
    //       {
    //         "title": "Invoice Amount",
    //         "propertyName": "invoiceAmount"
    //       },
    //       {
    //         "title": "Notes",
    //         "propertyName": "notes"
    //       },
    //       {
    //         "title": "Invoice Doc",
    //         "component": "invoiceInfoComponent"
    //       },
    //       {
    //         "title": "Submission Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "submissionDate",
    //         "component": "invoiceSubmissionDateFormat"
    //       },
    //       {
    //         "title": "Due Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "dueDate",
    //         "component": "invoiceDueDateFormat"
    //       },
    //       {
    //         "title": "Company",
    //         "propertyName": "companyName"
    //       },
    //       {
    //         "title": "Status",
    //         "component": "invoiceApproveComponent" 
    //       },
    //     ];

    //     this.invoiceTable2Column = [{
    //         "title": "Invoice Number",
    //         "propertyName": "invoiceNumber"
    //       },
    //       {
    //         "title": "Work Flow",
    //         "propertyName": "workFlowStep"
    //       },
    //       {
    //         "className": "adkTable24Width",
    //         "propertyName": "description"
    //       },
    //       {
    //         "title": "Invoice Amount",
    //         "propertyName": "invoiceAmount"
    //       },
    //       {
    //         "title": "Notes",
    //         "propertyName": "notes"
    //       },
    //       {
    //         "title": "Invoice Doc",
    //         "component": "invoiceInfoComponent"
    //       },
    //       {
    //         "title": "Submission Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "submissionDate",
    //         "component": "invoiceSubmissionDateFormat"
    //       },
    //       {
    //         "title": "Due Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "dueDate",
    //         "component": "invoiceDueDateFormat"
    //       },
    //       {
    //         "title": "Company",
    //         "propertyName": "companyName"
    //       },
    //     ];

    //     this.invoiceTable3Column = [{
    //         "title": "Invoice Number",
    //         "propertyName": "invoiceNumber"
    //       },
    //       {
    //         "title": "Work Flow",
    //         "propertyName": "workFlowStep"
    //       },
    //       {
    //         "className": "adkTable24Width",
    //         "propertyName": "description"
    //       },
    //       {
    //         "title": "Invoice Amount",
    //         "propertyName": "invoiceAmount"
    //       },
    //       {
    //         "title": "Account To Bill",
    //         "propertyName": "accountNumber"
    //       },
    //       {
    //         "title": "Invoice Doc",
    //         "component": "invoiceInfoComponent"
    //       },
    //       {
    //         "title": "Submission Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "submissionDate",
    //         "component": "invoiceSubmissionDateFormat"
    //       },
    //       {
    //         "title": "Due Date",
    //         "className": "adkTable11Width",
    //         "propertyName": "dueDate",
    //         "component": "invoiceDueDateFormat"
    //       },
    //       {
    //         "title": "Approval Notes",
    //         "propertyName": "statusLog"
    //       },
    //       {
    //         "title": "Company",
    //         "propertyName": "companyName"
    //       }
    //     ];

    //     this.invoiceTable4Column = this.invoiceTable3Column.concat();
    //     this.invoiceTable4Column[8] = {
    //       "title": "Rejected Comments",
    //       "propertyName": "statusLog"
    //     } 

    //     this.resetForm();
    // }, //init

    // // didReceiveAttrs() {
    // //   this._super(...arguments);
    // //   this.loadAdInvoiceTask.perform(this.get('ad').id);
    // // },

    // newAdInvoices: computed('adInvoice.@each.status', function() {
    //   let adInvoices = this.get('adInvoice');
    //   let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
    //   if (!adInvoices) {
    //     return A();
    //   }
    //   return adInvoices.filterBy('status', 'new').filterBy('companyName', companyName);
    // }),

    // submittedAdInvoices: computed('adInvoice.@each.status', function() {
    //   let adInvoices = this.get('adInvoice');
    //   let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
    //   if (!adInvoices) {
    //     return A();
    //   }
    //   return adInvoices.filterBy('status', 'submitted').filterBy('companyName', companyName);
    // }),

    // approvedAdInvoices: computed('adInvoice.@each.status', function() {
    //   let adInvoices = this.get('adInvoice');
    //   let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
    //   if (!adInvoices) {
    //     return A();
    //   }
    //   return adInvoices.filterBy('status', 'approved').filterBy('companyName', companyName);
    // }),

    // rejectedAdInvoices: computed('adInvoice.@each.status', function() {
    //     let adInvoices = this.get('adInvoice');
    //     let companyName = this.get('adkSessionAccount').get('myCompany').companyName;
    //     if (!adInvoices) {
    //       return A();
    //     }
    //       return adInvoices.filterBy('status', 'rejected').filterBy('companyName', companyName);
    // }),

    // actions: {
    //   createNewAdInvoice(componentValues, ad) {
    //     let adkToast = this.get('adkToast');
    //     let adInvoice = this.store.createRecord('vAdInvoice', {
    //       workFlowStep: componentValues.workFlowStep,
    //       invoiceNumber: componentValues.invoiceNumber,
    //       description: componentValues.description,
    //       invoiceAmount: componentValues.invoiceAmount,
    //       mediaCost: componentValues.mediaCost,
    //       invoiceDoc: componentValues.invoiceDoc,
    //       notes: componentValues.notes,
    //       submissionDate: componentValues.submissionDate,
    //       dueDate: componentValues.dueDate,
    //       createdAt: componentValues.createdAt,
    //     });
    //     adInvoice.set('ad', ad);
    //     adInvoice.save().then(() => {
    //       this.loadAdInvoiceTask.perform(this.get('ad').id);
    //       adkToast.display("New Invoice added successfully", "warning", 3000);
    //     });
    //   },

    //   newInvoice() {
    //       this.set('newInvoiceFlag', true);
    //   },

    //   closeDialog: function() {
    //       this.set('newInvoiceFlag',false);
    //   },

    // },      

    // resetForm() {
    //   let todayDate = this.get('adkSessionAccount').get('myToday');
    //   this.set('workFlowStep', '');
    //   this.set('invoiceNumber', '');
    //   this.set('description', '');
    //   this.set('invoiceAmount', '');
    //   this.set('invoiceDoc', '');
    //   this.set('notes', '');
    //   this.set('submissionDate', '');
    //   this.set('dueDate', todayDate);
    //   this.set('dueDateCenter', todayDate);
    // }
  });
});