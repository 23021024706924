define("adtikir3v1/models/v-ad-agency-proposal-invited", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    //companyId:                  attr('string'),
    advertiserCompanyName: attr('string'),
    adName: attr('string'),
    adNameLocal: attr('string'),
    // adCampaignRfpId:            attr('string'),
    //adCampaignName:             attr('string'), 
    proposalDeadline: attr('date'),
    adRfp: belongsTo('adRfp'),
    adAgencyRfpBody: belongsTo('adAgencyRfpBody'),
    status: attr('string'),
    daysToDeadline: Ember.computed('proposalDeadline', function () {
      let now = this.get('adkSessionAccount').get('myNow');
      let deadline = (0, _moment.default)(this.get('proposalDeadline'));
      let diffDay = deadline.diff(now, 'days');
      return diffDay;
    }),
    passDeadline: Ember.computed("daysToDeadline", 'proposalDeadline', function () {
      let daysToDeadline = this.get('daysToDeadline');
      if (daysToDeadline < 0) {
        return true;
      }
      return false;
    })
  });
});