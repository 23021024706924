define("adtikir3v1/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vAgTxzMu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"log\",[[24,[\"error\"]]],null],false],[0,\"\\n\"],[7,\"div\",true],[8],[1,[22,\"errTitle\"],false],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[8],[7,\"strong\",true],[8],[1,[22,\"errCode\"],false],[9],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[8],[1,[28,\"nl2br\",[[24,[\"errDetail\"]]],null],false],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\nThat might be some problems... please allow us to fix.\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/error/template.hbs"
    }
  });
});