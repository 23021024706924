define("adtikir3v1/pods/components/adk-companyinfo-campaignuser/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { map } from '@ember/object/computed';
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super();
      this.set('editMode', false);
      this.campaignUser = [{
        avatar: 'a',
        username: 'Ah Hock',
        email: 'ahhock@live.com',
        role: 'owner'
      }, {
        avatar: 'e',
        username: 'Kara Kok',
        email: 'kara.kok@tikicafe.com',
        role: 'user'
      }, {
        avatar: 'i',
        username: 'Mimi Too',
        email: 'mimi.too@tikicafe.com',
        role: 'manager'
      }];
      this.campaignUser_col = [{
        propertyName: 'avatar',
        title: 'Avatar'
      }, {
        propertyName: 'username',
        title: 'Username'
      }, {
        propertyName: 'email',
        title: 'Email'
      }, {
        propertyName: 'role',
        title: 'Role'
      }];
    },
    actions: {
      openSomething() {},
      openEditCampaignUserDialog() {
        this.set('editMode', true);
      },
      closeEditCampaignUserDialog() {
        this.set('editMode', false);
      }
    }
  });
});