define("adtikir3v1/pods/components/adk-media-type-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TiQJlLtB",
    "block": "{\"symbols\":[\"mediaType\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex layout-row layout-align-start-start\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex layout-column layout-align-start-start\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n            \"],[7,\"ul\",true],[10,\"class\",\"flex adk-imgLayout layout-row layout-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"mediaTypes\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"li\",true],[11,\"class\",[29,[\"modelImage \",[28,\"active-class\",[[23,1,[]],[24,[\"selectedMediaType\"]]],null],\" adk-imgList\"]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"selectMediaType\",[23,1,[]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"iconMediaTypeLocation\"]]],null,{\"statements\":[[0,\"                            \"],[7,\"figure\",true],[8],[0,\"\\n                                \"],[5,\"adk-display-image\",[],[[\"@fileid\",\"@width\",\"@height\"],[[23,1,[\"iconMediaTypeLocation\"]],104,104]]],[0,\"\\n                                \"],[7,\"figcaption\",true],[10,\"class\",\"adk-figCaption\"],[8],[1,[23,1,[\"mediaTypeName\"]],false],[9],[0,\"\\n                            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-media-type-selection/template.hbs"
    }
  });
});