define("adtikir3v1/pods/campaign/details/timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "boDg78cr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex md-padding layout-align-start-stretch\"],[8],[0,\"\\n  \"],[5,\"adk-media-calendar\",[],[[\"@acWorkFlows\",\"@adCampaign\",\"@adCampaignDetail\",\"@ads\",\"@mediaType\",\"@fieldTitle\"],[[22,\"acWorkFlows\"],[22,\"adCampaign\"],[22,\"adCampaignDetail\"],[22,\"filterAds\"],[22,\"mediaType\"],[28,\"concat\",[[28,\"capitalize\",[[28,\"t\",[\"media\"],null]],null],\" Calendar\"],null]]],{\"statements\":[[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/timeline/template.hbs"
    }
  });
});