define("adtikir3v1/helpers/console", ["exports", "@abcum/ember-helpers/helpers/console"], function (_exports, _console) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "console", {
    enumerable: true,
    get: function () {
      return _console.console;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _console.default;
    }
  });
});