define("adtikir3v1/pods/components/adk-report-financials/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // columns: [
    //     {
    //       "useFilter": false,
    //       "mayBeHidden": false,
    //     },
    //     {
    //       propertyName:  'company.companyName',
    //       title: "Company",
    //     },
    //     {
    //       propertyName: 'invoiceNumber',
    //       title: "Invoice Number",
    //     },
    //     {
    //       propertyName: 'description',
    //       title: "Description",
    //     },
    //     {
    //       propertyName: 'invoiceAmount',
    //       title: "Invoice Amount",
    //     },

    //   ], //set columns

    init() {
      this._super(...arguments);
      this.invoiceReportColumn = [{
        "title": "Ad Name",
        "propertyName": "ad.adName"
      }, {
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Account To Bill",
        "propertyName": "accountNumber"
      }, {
        "title": "Default Currency",
        "propertyName": "defaultCurrency"
      }, {
        "title": "Foreign Currency",
        "propertyName": "foreignCurrency"
      }, {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate"
      }, {
        "title": "Approval Comments",
        "propertyName": "statusLog"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate"
      }];
    },
    approvedCampaignInvoices: Ember.computed('adCampaignInvoice.@each.status', function () {
      let adCampaignInvoice = this.get('adCampaignInvoice');
      if (!adCampaignInvoice) {
        return Ember.A();
      }
      return adCampaignInvoice.filterBy('status', 'approved');
    })
  });
});