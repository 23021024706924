define("adtikir3v1/pods/components/adk-companyinfo-clientportfolio/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    pageSize: 4,
    init() {
      this._super(...arguments);
      this.set('myVendorClientPortfolioList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadMyVendorClientPortfolioTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },
    newClientPortfolio: (0, _emberConcurrency.task)(function* () {
      yield this.saveNewClientPortfolio({
        description: this.description,
        name: this.name,
        position: this.position,
        title: this.title,
        link: this.link,
        pic: this.clientPortfolioPic
      });
      this.actions.cancelAddClientPortfolio.apply(this);
      this.set('myVendorClientPortfolioList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadMyVendorClientPortfolioTask"),
        result: null,
        total: 0,
        refresh: true,
        type: "emberdata"
      });
    }),
    delClientPortfolio: (0, _emberConcurrency.task)(function* (clientPortfolio) {
      yield this.deleteClientPortfolio(clientPortfolio);
      this.set('myVendorClientPortfolioList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadMyVendorClientPortfolioTask"),
        result: null,
        total: 0,
        refresh: true,
        type: "emberdata"
      });
      this.set("openConfirmationDialogBox", false);
    }),
    actions: {
      uploadClientPortfolio: function () {
        //do nothing
        return;
      },
      editClientPortfolio(clientPortfolio, event) {
        this.set("clientPortfolio", clientPortfolio);
        this.set('dialogOrigin', event.currentTarget);
        this.set("openEditDialogBox", true);
        clientPortfolio.startTrack();
      },
      cancelEditClientPortfolio(clientPortfolio) {
        this.set("openEditDialogBox", false);
        clientPortfolio.rollback();
      },
      cancelDeleteClientPortfolio() {
        this.set("openConfirmationDialogBox", false);
      },
      deleteClientPortfolio(clientPortfolio) {
        this.deleteClientPortfolio(clientPortfolio);
        this.set("openConfirmationDialogBox", false);
        this.set('myVendorClientPortfolioList', {
          page: 0,
          pageSize: this.get('pageSize'),
          action: this.get("loadMyVendorClientPortfolioTask"),
          result: null,
          refresh: true,
          type: "emberdata"
        });
      },
      updateClientPortfolio(clientPortfolio) {
        this.updateClientPortfolio(clientPortfolio);
        this.set("openEditDialogBox", false);
      },
      showConfirmationDialogBox(clientPortfolio, event) {
        this.set("clientPortfolio", clientPortfolio);
        this.set('dialogOrigin', event.currentTarget);
        this.set("openConfirmationDialogBox", true);
      },
      addNewClientPortfolio(event) {
        this.set("showAddClientPorfolioBox", true);
        this.set('dialogOrigin', event.currentTarget);
      },
      cancelAddClientPortfolio() {
        this.set("showAddClientPorfolioBox", false);
        this.formClear();
      },
      uploadPic: function () {
        //do nothing
        return;
      }
    }
  });
});