define("adtikir3v1/pods/components/adk-companyinfo-locationhierachy/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super();
      this.dataRegion = [{
        name: "Australasia",
        isSelected: false
      }, {
        name: "South East Asia",
        isSelected: false
      }];
      this.dataRegionGroup = [{
        name: "New Zealand"
      }, {
        name: "Australia"
      }];
      this.regionColumn = [{
        propertyName: 'name',
        title: 'Region'
      }];
      this.regionGroupColumn = [{
        propertyName: 'name',
        title: 'Country'
      }];
    },
    actions: {
      openHierachyDialog(ev) {
        this.set('showHierachyDialog', true);
        this.set('dialogOrigin', ev.currentTarget);
      },
      closeHierachyDialog(ev) {
        if (ev == 'cancel') {
          this.set('regionName', '');
          this.set('selectedCountry', '');
          this.set('showHierachyDialog', false);
        }
      },
      updateHierachy() {
        // this.onSubmitRegion({
        //     name: this.regionName,
        //     country: this.selectedCountry,
        // })

        // var region = this.store.createRecord('region', {
        //   name: this.get('regionName'),
        //   country: this.get('selectedCountry')
        // });

        // console.log(region);
      }
    }
  });
});