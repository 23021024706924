define("adtikir3v1/pods/campaign/details/timeline/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    //first.. need to find ad with 2 to 8
    //then, sort by type
    filterAds: Ember.computed('ads.[]', function () {
      let filterAds = [];
      let ads = this.get('ads');
      //console.log("ads is " , ads);
      if (ads) {
        ads = ads.toArray();
        for (let i = 0; i < ads.length; i++) {
          if (ads[i].workFlowStatusNumber >= 0 && ads[i].workFlowStatusNumber < 7) {
            filterAds.addObject(ads[i]);
          }
        }
      }
      return filterAds;
    })
  });
});