define("adtikir3v1/pods/components/adk-display-cost-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gKccjJb4",
    "block": "{\"symbols\":[\"costEst\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"md-list-item-text\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"adkAgencyTable\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"style\",\"text-align:left\"],[8],[0,\"Phase\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Cost\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"costs\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[28,\"capitalize\",[[23,1,[\"title\"]]],null],false],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"style\",\"text-align:right\"],[8],[0,\"\\n            \"],[1,[22,\"quotedCurrency\"],false],[0,\"\\n            \"],[1,[28,\"format-number\",[[23,1,[\"value\"]]],[[\"precision\"],[2]]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"Total\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"style\",\"text-align:right\"],[8],[0,\"\\n          \"],[1,[22,\"quotedCurrency\"],false],[0,\"\\n          \"],[1,[28,\"format-number\",[[24,[\"total\"]]],[[\"precision\"],[2]]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-display-cost-table/template.hbs"
    }
  });
});