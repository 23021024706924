define("adtikir3v1/helpers/get-date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function getDateFormat([dateFormat, dateSeparator]) {
    let date = dateFormat.replace(/\s+/g, dateSeparator);
    return date;
  });
});