define("adtikir3v1/models/ad-agency", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    activeStatus: attr('boolean'),
    actualAgencySpend: attr('number', {
      defaultValue: 0
    }),
    actualMediaSpend: attr('number', {
      defaultValue: 0
    }),
    actualMiscBudget: attr('number', {
      defaultValue: 0
    }),
    actualSpend: attr('number', {
      defaultValue: 0
    }),
    actualImpression: attr('number', {
      defaultValue: 0
    }),
    adBudgetStatus: attr('number', {
      defaultValue: 0
    }),
    adColor: attr('string'),
    adName: attr('string'),
    adNameLocal: attr('string'),
    adPic: attr('string'),
    description: attr('string'),
    adPlayStartDate: attr('date'),
    adPlayEndDate: attr('date'),
    adMediaFormatDetails: attr('string'),
    adtypes: attr('string'),
    mediatype: attr('string'),
    mediaformat: attr('string'),
    adBudgetWorkflowStep: attr('number', {
      defaultValue: 0
    }),
    committedAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    committedBudget: attr('number', {
      defaultValue: 0
    }),
    committedMediaBudget: attr('number', {
      defaultValue: 0
    }),
    committedMiscBudget: attr('number', {
      defaultValue: 0
    }),
    estAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    estBudget: attr('number', {
      defaultValue: 0
    }),
    estMediaBudget: attr('number', {
      defaultValue: 0
    }),
    estMiscBudget: attr('number', {
      defaultValue: 0
    }),
    estimatedImpressions: attr('number', {
      defaultValue: 0
    }),
    plannedAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    plannedMediaBudget: attr('number', {
      defaultValue: 0
    }),
    plannedMiscBudget: attr('number', {
      defaultValue: 0
    }),
    miscCosts: attr('number'),
    mediaFeesApproved: attr('string'),
    mediaPaymentSchedule: attr('string'),
    mediaAgreement: attr('string'),
    mediaNDA: attr('string'),
    mediaStartDate: attr('string'),
    mediaEndDate: attr('string'),
    language: attr('string'),
    agencyStartDate: attr('string'),
    agencyEndDate: attr('string'),
    agencyPaymentSchedule: attr('string'),
    agencyAgreement: attr('string'),
    agencyNDA: attr('string'),
    agencyName: attr('string'),
    agencyFees: attr('string'),
    timeStart: attr('string'),
    duration: attr('string'),
    customField: attr('string'),
    approvedClient: attr('string'),
    taxIncluded: attr('boolean'),
    workFlowStatusNumber: attr('number', {
      defaultValue: 0
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    adDuration: Ember.computed('adPlayStartDate', 'adPlayEndDate', function () {
      let startdate = (0, _moment.default)(this.get('adPlayStartDate'));
      let enddate = (0, _moment.default)(this.get('adPlayEndDate'));
      let duration = enddate.diff(startdate, 'days') + 1;
      return duration;
    })

    // ad                        : belongsTo('ad'),
  });
});