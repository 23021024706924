define("adtikir3v1/pods/agency/admanager/ad/approval/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    intl: Ember.inject.service(),
    loadAdPostsTask: (0, _emberConcurrency.task)(function* (adApprovalId, query = '', page = 0, pageSize = 10) {
      let result = yield this.store.query('ad-post', {
        adApproval: adApprovalId,
        page: page,
        pageSize: pageSize,
        query: query
      }); //this.store

      let total = 0;
      if (result.meta.page) {
        total = result.meta.page.total;
      }
      return {
        adPosts: result,
        total: total
      };
    }).enqueue(),
    loadAdPostCommentsTask: (0, _emberConcurrency.task)(function* (adPostId, page = 0, pageSize = 10) {
      let result = yield this.store.query('ad-post-comment', {
        adPost: adPostId,
        page: page,
        pageSize: pageSize
      }); //this.store

      let total = 0;
      if (result.meta.page) {
        total = result.meta.page.total;
      }
      return {
        adPostsComment: result,
        total: total
      };
    }).enqueue(),
    loadAdPostDiscussionTask: (0, _emberConcurrency.task)(function* (adPostId, page = 0, pageSize = 10) {
      let result = yield this.store.query('ad-post-discussion', {
        adPost: adPostId,
        page: page,
        pageSize: pageSize
      });
      let total = 0;
      if (result.meta.page) {
        total = result.meta.page.total;
      }
      return {
        adPostDiscussions: result,
        total: total
      };
    }).enqueue(),
    actions: {
      createAdPDCmt(componentValues, aPD) {
        let adkToast = this.get('adkToast');
        let adPostDiscussionComment = this.store.createRecord('adPostDiscussionComment', {
          comment: componentValues.comment
        });
        adPostDiscussionComment.set('adPostDiscussion', aPD);
        return adPostDiscussionComment.save().then(function () {
          adkToast.display("Comment added successfully", "warning", 3000);
          return true;
        }, function () {
          if (adPostDiscussionComment.get('hasDirtyAttributes')) {
            adPostDiscussionComment.rollbackAttributes();
          }
          adkToast.display("Fail to comment", "error", 3000);
          return false;
        });
      },
      createAdPostDiscussion(componentValues, adPost) {
        let adkToast = this.get('adkToast');
        let adPostDiscussion = this.store.createRecord('adPostDiscussion', {
          title: componentValues.title,
          media: componentValues.media,
          comment: componentValues.comment,
          thumbnail: componentValues.thumbnail
        });
        adPostDiscussion.set('adPost', adPost);
        return adPostDiscussion.save().then(function () {
          adkToast.display("Discussion added successfully", "warning", 3000);
          return true;
        }, function () {
          if (adPostDiscussion.get('hasDirtyAttributes')) {
            adPostDiscussion.rollbackAttributes();
          }
          adkToast.display("Fail to add discussion", "error", 3000);
          return false;
        });
      },
      createAdPost(componentValues, adApproval) {
        let adkToast = this.get('adkToast');
        let wflowNum = this.get('ad').get('workFlowStatusNumber');
        let adPost = this.store.createRecord('adPost', {
          title: componentValues.title,
          media: componentValues.media,
          comment: componentValues.comment,
          thumbnail: componentValues.thumbnail,
          wflowNum: wflowNum
        });
        adPost.set('adApproval', adApproval);
        return adPost.save().then(function () {
          adkToast.display(Ember.String.capitalize(this.intl.t("ad")) + " Post created Successfully", "warning", 3000);
          return adPost.reload();
        }, function () {
          if (adPost.get('hasDirtyAttributes')) {
            adPost.rollbackAttributes();
          }
          adkToast.display("Fail to add new " + this.intl.t("ad") + " post", "error", 3000);
          return false;
        });
      },
      loadAdPosts(adApprovalId, query, page = 0, pageSize = 10) {
        return this.loadAdPostsTask.perform(adApprovalId, query, page, pageSize);
      },
      loadAdPostComments(adPostId, page = 0, pageSize = 10) {
        return this.loadAdPostCommentsTask.perform(adPostId, page, pageSize);
      },
      loadAdPostDiscussions(adPostId, page = 0, pageSize = 10) {
        return this.loadAdPostDiscussionTask.perform(adPostId, page, pageSize);
      }

      /*
          modelReload: function() {
             this.get('currentModel').reload();
          }
      */
    }
  });
});