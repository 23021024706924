define("adtikir3v1/pods/components/adk-display-cost-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    genCostArray: function () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let estCost = this.get('estCost');
      let costs = [];
      //let costs = this.get('costs');

      let fields = estCost.split('::');
      for (let i = 0; i < fields.length; i = i + 2) {
        costs.push({
          title: fields[i],
          value: fields[i + 1]
        });
      }
      this.set('costs', costs);
    },
    total: Ember.computed('costs.@each.value', function () {
      let total = 0;
      let a = this.get('costs');
      for (var i = 0; i < a.length; i++) {
        total += a[i].value * 1;
      }
      return total;
    }),
    didReceiveAttrs() {
      this.genCostArray();
    }
  });
});