define("adtikir3v1/pods/components/adkcard-dashboard-ad-description/component", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isEditing: false,
    adkToast: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('palette', [["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"], ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"], ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"], ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"], ["#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"], ["#900", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"], ["#600", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]]);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        adName: this.ad.get('adName'),
        adNameLocal: this.ad.get('adNameLocal'),
        adColor: this.ad.get('adColor')
      });
    },
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      // activateAdInterAction() {
      //   // let l = confirm("Are you sure to assign this ad as internal work?");
      //   let l = confirm("Once assigned, it cannot be undone. Please confirm");

      //   if (l===false) {
      //     return;
      //   }

      //   let ad=this.get('ad');
      //   this.assignToInternalAction(true, ad);
      // },

      saveAdInfo() {
        var ctrl = this;
        let ad = this.get('ad');
        ctrl.toggleProperty('isEditing');

        // ctrl.adDashboardSaveInfo({
        //   adName: this.get('adName'),
        //   adNameLocal: this.get('adNameLocal'),
        //   adColor: this.get('adColor'),
        // });

        ad.set('adName', (0, _sanitizeHtml.default)(this.get('adName')));
        ad.set('adNameLocal', (0, _sanitizeHtml.default)(this.get('adNameLocal')));
        ad.set('adColor', this.get('adColor'));
        this.set('adName', (0, _sanitizeHtml.default)(this.get('adName')));
        this.set('adNameLocal', (0, _sanitizeHtml.default)(this.get('adNameLocal')));
        this.saveAction(true, ad);
      }
    }
  });
});