define("adtikir3v1/pods/components/adkcard-addashboard-textfield/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    // classNames: this.get('fieldname'),

    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        emberId: this.elementId,
        options: {}
      });
    },
    resetForm: function () {
      this.set('ad', '');
    },
    actions: {
      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },
      closeDialogWithParent() {
        // this.set('result', result);
        this.set('showDialogWithParent', false);
        this.cancelAction();
      },
      editSubmit() {
        // var ctrl = this;
        let ad = this.get('ad');
        this.set('showDialogWithParent', false);
        this.saveDashboardDetail(
        // ad: this.get('ad'),
        true, ad);
        // ad.save().then(onEditOK, onEditFailed);

        // function onEditOK() {
        //   let adkToast = ctrl.get('adkToast');

        //   // ctrl.resetForm();
        //   adkToast.display("Template Description successfully updated", "warning");
        //   ctrl.set('showDialogWithParent', false);
        // };

        // function onEditFailed(){
        //   let adkToast = ctrl-dashoard-description.get('adkToast');
        //   adkToast.display("Template Description not saved", "error");
        //   ctrl.set('showDialogWithParent', false);
        // }
      }
    }
  });
});