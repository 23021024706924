define("adtikir3v1/pods/components/adk-display-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HVekgdLk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"getUrl\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"lottie-player\",true],[10,\"src\",\"https://assets6.lottiefiles.com/packages/lf20_05nWYs.json\"],[10,\"background\",\"transparent\"],[10,\"speed\",\"1\"],[10,\"style\",\"width: 100px; height: 100px;\"],[10,\"loop\",\"\"],[10,\"autoplay\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isImage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"adkImageDisplay\"],[11,\"src\",[23,0,[\"url\"]]],[11,\"alt\",[23,0,[\"myTitle\"]]],[11,\"onload\",[28,\"action\",[[23,0,[]],\"imageLoad\"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isVideo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"video\",true],[10,\"controls\",\"\"],[10,\"preload\",\"auto\"],[11,\"width\",[23,0,[\"width\"]]],[11,\"height\",[23,0,[\"height\"]]],[8],[0,\"\\n      \"],[7,\"source\",true],[11,\"src\",[23,0,[\"url\"]]],[8],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"Your browser does not support the video tag.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"divider\"]]],null,{\"statements\":[[0,\"   \"],[1,[22,\"paper-divider\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-display-image/template.hbs"
    }
  });
});