define("adtikir3v1/models/place-city-tbd", ["exports", "ember-data", "adtikir3v1/models/place"], function (_exports, _emberData, _place) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = _place.default.extend({
    name: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    state: belongsTo('state'),
    country: belongsTo('country')
  });
});