define("adtikir3v1/pods/campaign/details/ads/ad/vendors/media/rfpmanagement/controller", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    intl: Ember.inject.service(),
    init() {
      this._super(...arguments);
    },
    //init

    calDateDiff(startDate, endDate, percentage) {
      let totalDayDiff = endDate.getDate() - startDate.getDate();
      let dayDiff = 0;
      if (totalDayDiff > 1) {
        dayDiff = Math.floor(totalDayDiff * percentage / 100);
      }
      let returnDate = new Date();
      returnDate.setDate(startDate.getDate() + dayDiff);
      return (0, _moment.default)(returnDate);
    },
    clearForm() {
      let adkSessionAccount = this.get('adkSessionAccount');
      //let adCampaign = this.get('adCampaign');

      this.set('newFormName', '');
      this.set('newFormNote', '');
      let ad = this.get('ad');
      let mediaTypes = this.get('mediaTypes');
      for (let i = 0; i < mediaTypes.length; i++) {
        if (ad.mediatype === mediaTypes.objectAt(i).id) {
          this.set('newFormMediaTypes', [mediaTypes.objectAt(i)]);
          break;
        }
      } //for

      //this.get('newFormMediaTypes').push(ad.get('mediatype'));
      this.set('newFormProposalDeadline', adkSessionAccount.get('myToday'));
      // if (adCampaign) {
      //   alert(1);
      //   this.set('newFormProposalDeadline',
      //     this.calDateDiff(adCampaign.campaignStartDate,
      //       adCampaign.campaignEndDate, 10));
      // } //if
      this.set('createNewFlag', false);
      this.set('newFormProposalDeadlineCenter', this.get('newFormProposalDeadline'));
      this.set('newFormProposalDeadlineLocal', this.get('newFormProposalDeadline'));
    },
    //clearForm

    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('adMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.clearForm();
      this.set('newAdMediaRfpBodyFlag', false);
      this.set('reviewAdMediaRfpBodyFlag', false);
      this.set('editAdMediaRfpBodyFlag', false);
      //this.cleanAProposalResponse(this);
    },
    //cleanUp

    loadAdMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfpId = this.get('adRfp').id;
      let adMediaRfpBodies = yield this.store.query('ad-media-rfp-body', {
        adRfp: adRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adMediaRfpBodies);
      //return attachments;
    }),
    //loadadMediaRfpBodyListTask

    saveAdMediaRfpBodyTask: (0, _emberConcurrency.task)(function* (mode) {
      let adkToast = this.get('adkToast');
      let errorFg = false;
      if (this.get('newFormName').trim().length == 0) {
        adkToast.display('Name must be filled', 'error', 2000);
        errorFg = true;
      }
      if (this.get('newFormMediaTypes').length == 0) {
        adkToast.display('At least one ' + this.intl.t('media') + ' type must be selected', 'error', 2000);
        errorFg = true;
      }
      if (errorFg) {
        return false;
      }
      let adMediaRfpBody;
      let adRfp = this.get('adRfp');
      let message;
      if (mode === 'edit') {
        message = 'Ad customer brief body updated';
        adMediaRfpBody = this.get('adMediaRfpBody');
      } else {
        message = 'New ad customer brief body created, you may upload attachments after this';
        adMediaRfpBody = this.store.createRecord('ad-media-rfp-body');
      }
      adMediaRfpBody.set('adRfp', adRfp);
      adMediaRfpBody.set('name', this.get('newFormName'));
      adMediaRfpBody.set('note', this.get('newFormNote'));
      adMediaRfpBody.set('mediaTypes', this.get('newFormMediaTypes'));
      adMediaRfpBody.set('proposalDeadline', this.get('newFormProposalDeadline').toDate());
      yield adMediaRfpBody.save();
      adkToast.display(message, 'warning', 3000);
      this.set('newAdMediaRfpBodyFlag', false);
      if (mode === 'edit') {
        this.set('editAdMediaRfpBodyFlag', false);
        this.set('reviewAdMediaRfpBodyFlag', true);
        this.clearForm();
      } else {
        //new...
        this.set('createNewFlag', true);
        this.set('adMediaRfpBody', adMediaRfpBody);
        this.set('editAdMediaRfpBodyFlag', true);
      }
      Ember.set(this.get('adMediaRfpBodyList'), 'refresh', true);
    }),
    //saveAdMediaRfpBodyTask

    loadAdMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adMediaRfpBody = this.get('adMediaRfpBody');
      let attachments = yield this.store.query('ad-media-rfp-body-attachment', {
        adMediaRfpBody: adMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", attachments);
      //return attachments;
    }).drop(),
    //loadMediaBodyAttachmentTask 

    saveAdMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adMediaRfpBody = this.get('adMediaRfpBody');
      let attachment = this.store.createRecord('ad-media-rfp-body-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        adMediaRfpBody: adMediaRfpBody
      });
      try {
        attachment = yield attachment.save();
        this.set('refresh', true);
        return attachment;
      } catch (e) {
        throw e;
      }
    }).drop(),
    //saveAdMediaRfpBodyAttachmentTask

    actions: {
      createNewRfpBody: function () {
        if (this.newAdMediaRfpBodyFlag || this.editAdMediaRfpBodyFlag) {
          alert('some form already opened, cancel or save it first');
          return;
        }
        if (this.reviewAdMediaRfpBodyFlag) {
          this.set('reviewAdMediaRfpBodyFlag', false);
          this.set('adMediaRfpBody', null);
        }
        this.set('newAdMediaRfpBodyFlag', true);
        this.clearForm();
        return true;
      },
      cancleAdMediaRfpBodyAction: function () {
        if (this.editAdMediaRfpBodyFlag) {
          this.set('editAdMediaRfpBodyFlag', false);
          this.set('reviewAdMediaRfpBodyFlag', true);
        } else {
          this.set('newAdMediaRfpBodyFlag', false);
        }
        this.clearForm();
        return true;
      },
      setAdMediaRfpBody(adMediaRfpBody) {
        if (this.newAdMediaRfpBodyFlag || this.editAdMediaRfpBodyFlag) {
          alert('some form already opened, cancel or save it first');
          return;
        }
        this.set('adMediaRfpBody', adMediaRfpBody);
        this.set('reviewAdMediaRfpBodyFlag', true);
      },
      setEditAdMediaRfpBodyAction() {
        let adMediaRfpBody = this.get('adMediaRfpBody');
        this.set('newFormName', adMediaRfpBody.name);
        this.set('newFormNote', adMediaRfpBody.note);
        this.set('newFormMediaTypes', Array.from(adMediaRfpBody.mediaTypes.toArray()));
        this.set('newFormProposalDeadlineLocal', (0, _moment.default)(adMediaRfpBody.proposalDeadline));
        this.set('newFormProposalDeadline', (0, _moment.default)(adMediaRfpBody.proposalDeadline));
        this.set('newFormProposalDeadlineCenter', (0, _moment.default)(adMediaRfpBody.proposalDeadline));
        this.set('editAdMediaRfpBodyFlag', true);
        this.set('reviewAdMediaRfpBodyFlag', false);
      },
      updateDate(selected) {
        let adkToast = this.get('adkToast');
        let newDate = (0, _moment.default)(selected.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj
        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj

        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
          newDate = '';
          adkToast.display("Date should not be later than the end date, it will not be set", "error", 2000);
          return false;
        }

        //cannot put else.. cause productLaunchDate need check both

        //compare with startDate
        if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
          newDate = '';
          adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 2000);
          return false;
        }
        if (newDate) {
          this.set('newFormProposalDeadlineLocal', newDate);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set('newFormProposalDeadline', (0, _moment.default)(new Date(newDateStr)));
        }
      },
      // updateDate()

      checkCenter(newCenterDate) {
        newCenterDate = (0, _moment.default)(newCenterDate.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj
        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj
        // let productLaunchDate = this.get('productLaunchDate');

        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
          newCenterDate = endDate;
        }
        //compare with startDate

        if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
          newCenterDate = startDate;
        }
        this.set("newFormProposalDeadlineCenter", newCenterDate);
      } //checkCenter()    
    } //actions
  });
});