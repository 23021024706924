define("adtikir3v1/pods/campaign/details/financials/accounts/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    router: Ember.inject.service(),
    loadAdCampaignBudgetApprovedListTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let adCampaign = this.get('adCampaign');
      let result = yield this.store.query('ad-campaign-budget-approved', {
        adCampaignId: adCampaign.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    getCurrencyRateTask: (0, _emberConcurrency.task)(function* (foreignCurrency = '') {
      let adCampaign = this.get('adCampaign');
      if (!foreignCurrency) {
        foreignCurrency = adCampaign.get('foreignCurrency');
      }
      let currencyId = foreignCurrency + 'to' + adCampaign.currency;
      let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
      this.set('exchangeRate', currencyRate);
    }),
    cleanUp() {
      this.set('adCampaignBudgetApprovedList', {
        page: 0,
        pageSize: 20,
        action: this.get("loadAdCampaignBudgetApprovedListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('exchaignRate', null);
      this.getCurrencyRateTask.perform();
    },
    //cleanUp 

    actions: {} //end of actions
  });
});