define("adtikir3v1/models/vendor-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    profilePic: attr('string'),
    aboutMe: attr('string'),
    userId: attr('string'),
    email: attr('string'),
    vendorRole: belongsTo('vendor-role', {
      async: true
    })
  });
});